////Sử dụng axios2 trong trường hợp OpenAccount
import axios from "axios";
import _ from "lodash";
import config from "./config";
import reduxStore, { dispatch } from "./redux";
import * as actions from "./store/actions";

const instance = axios.create({
    baseURL: config.api.API_USERDATA_URL,
    // withCredentials: true
});

const createError = (
    httpStatusCode,
    statusCode,
    errorMessage,
    problems,
    errorCode = ""
) => {
    const error = new Error();
    error.httpStatusCode = httpStatusCode;
    error.statusCode = statusCode;
    error.errorMessage = errorMessage;
    error.problems = problems;
    error.errorCode = errorCode + "";
    return error;
};

export const isSuccessStatusCode = (s) => {
    // May be string or number
    const statusType = typeof s;
    return (
        (statusType === "number" && s === 0) ||
        (statusType === "string" && s.toUpperCase() === "OK")
    );
};

instance.interceptors.request.use((request) => {
    const state = reduxStore.getState();
    const token =
        state.admin.adminInfo != null
            ? state.admin.adminInfo["accessToken"]
            : null;
    if (token) {
        request.headers.Authorization = token;
    }
    return request;
});

instance.interceptors.response.use(
    (response) => {
        // Thrown error for request with OK status code
        const { data } = response;
        if (
            data.hasOwnProperty("s") &&
            !isSuccessStatusCode(data["s"]) &&
            data.hasOwnProperty("errmsg")
        ) {
            return Promise.reject(
                createError(
                    response.status,
                    data["s"],
                    data["errmsg"],
                    null,
                    data["errcode"] ? data["errcode"] : ""
                )
            );
        }

        // Return direct data to callback
        if (data.hasOwnProperty("s") && data.hasOwnProperty("d")) {
            return data["d"];
        }
        // Handle special case
        if (data.hasOwnProperty("s") && _.keys(data).length === 1) {
            return null;
        }
        return response.data;
    },
    (error) => {
        const { response } = error;
        if (response == null) {
            return Promise.reject(error);
        }

        const { data } = response;

        // AccessToken không hợp lệ -> logout khỏi ứng dụng
        if (data && data.errcode === "FO20011") {
            dispatch(actions.processLogout());
        }

        if (data.hasOwnProperty("s") && data.hasOwnProperty("errmsg")) {
            return Promise.reject(
                createError(response.status, data["s"], data["errmsg"])
            );
        }

        if (data.hasOwnProperty("code") && data.hasOwnProperty("message")) {
            return Promise.reject({
                status: response.status,
                code: data["code"],
                message: data["message"],
                problems: data["problems"],
            });
        }
        //here
        return Promise.reject(createError(response.status));
    }
);

export default instance;

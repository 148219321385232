import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import "./SkipEkyc.scss";
import { withRouter } from 'react-router-dom';
import { path } from "../utils";
class SkipEkyc extends Component {

    render() {
        let { dataSkipEkycModal } = this.props
        let text_content, msg
        if (dataSkipEkycModal == 'S') {
            text_content = <>
                <FormattedMessage id="account-register-2.section-2.SkipE-text-1" />
                <a onClick={() => this.props.get_take_photo()}><FormattedMessage id="account-register-2.section-2.SkipE-text-2" /></a>
                <FormattedMessage id="account-register-2.section-2.SkipE-text-3" />
            </>

            msg = <>
                <FormattedMessage id="account-register-2.section-2.SkipE-text2-1" /><a onClick={() => this.props.submit()}><FormattedMessage id="account-register-2.section-2.SkipE-text2-2" /></a><FormattedMessage id="account-register-2.section-2.SkipE-text2-3" />
            </>
        }
        else {
            if (dataSkipEkycModal == 'W') {
                text_content = <>
                    <FormattedMessage id="account-register-2.section-2.SkipE-text3-1" /> <br />
                    <FormattedMessage id="account-register-2.section-2.SkipE-text3-2" />< a onClick={() => this.props.get_take_photo()
                    }><FormattedMessage id="account-register-2.section-2.SkipE-text3-3" /></a >
                </>

                msg = <>
                    <FormattedMessage id="account-register-2.section-2.SkipE-text4-1" /><a onClick={() => this.props.submit()}><FormattedMessage id="account-register-2.section-2.SkipE-text4-2" /></a>
                    <FormattedMessage id="account-register-2.section-2.SkipE-text4-3" />
                </>
            }
        }

        return (
            <>
                <div className={'not-right-container'}>
                    <div className={'not-right-body'}>
                        <div className={'text_content'}>
                            {text_content}
                        </div>
                        <div className={'not-right-content'}>
                            {msg}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SkipEkyc)));

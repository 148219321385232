import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import './WizardComponent.scss';

class WizardComponent extends Component {

    static defaultProps = {
        step: 1,
        legends: []
    };

    initialState = {
        canGo: false,
        isStep1: false,
        isStep2: false,
        isStep3: false,
        isStep4: false,
        isStep5: false,
        shorterLegend: [],
        activedLegends: []
    };

    state = {
        ...this.initialState
    }

    componentDidMount() {
        const { multiStep } = this.props;
        if (multiStep) {
            this.getShorterLegend(); // done
        }
    }

    // Lấy đoạn giữa của legend để gán vào mảng
    getShorterLegend = () => { // done
        const { legends } = this.props;  // legends = []
        /**                      
            wizardLegends = [
                'account-register-2.step.step-1',
                'account-register-2.step.step-2',
                'account-register-2.step.step-3',
                'account-register-2.step.step-4',
                'account-register-2.step.step-5',
                'account-register-2.step.step-6'
            ];
        */
        // console.log('Harem-legends', legends);
        if (legends.length > 3) { // legends.length  = 6. tại sao lại lớn hơn 4 ?
            this.setState({
                shorterLegend: legends.slice(1, legends.length - 1)
                /**
                 *  'account-register-2.step.step-2',
                    'account-register-2.step.step-3',
                    'account-register-2.step.step-4',
                    'account-register-2.step.step-5',
                 */
            }, () => {
                // console.log('Harem-shorterLegend', this.state.shorterLegend);
                this.updateActivedStep();
            });
        } else {
            this.setState({ shorterLegend: legends.length > 1 ? legends[1] : [] })
        }
    };

    // Chỉ được gọi khi shorterLegend.leng > 0, lúc nào cũng là 4
    updateActivedStep = () => {
        const { shorterLegend } = this.state;
        const { step } = this.props;
        // console.log('Harem-step', step);
        let newActivedLegends = [];
        let nextStepIndex = step - 2; // -1 0 1 2 3 4
        // Step________________________   1 2 3 4 5 6
        if (nextStepIndex >= 0 && shorterLegend.length > 0) {
            for (let i = 0; i <= nextStepIndex; i++) { // start with step = 2
                newActivedLegends.push(shorterLegend[i]);
                this.setState({ activedLegends: newActivedLegends });
            }
        } else {
            this.setState({ activedLegends: [] });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { step, multiStep } = this.props;
        const { step: prevStep } = prevProps;
        const { shorterLegend } = this.state;
        let newActivedLegends = [];
        // Tạo một array đã kích hoạt để so sánh và style cho các nút đã actived
        if (step !== prevStep && multiStep) {
            this.updateActivedStep();
        }
    }

    onLegendClick = (stepClicked) => {
        const { step, onClick, clickable } = this.props;
        // let { canGo, isStep1, isStep2, isStep3, isStep4, isStep5 } = this.state;
        // switch (step) {
        //     case 2:
        //         isStep1 = true;
        //         isStep2 = true;
        //         break;
        //     case 3:
        //         isStep1 = true;
        //         isStep2 = true;
        //         isStep3 = true;
        //         break;
        //     case 4:
        //         isStep1 = true;
        //         isStep2 = true;
        //         isStep3 = true;
        //         isStep4 = true;
        //         break;
        //     case 5:
        //         isStep1 = true;
        //         isStep2 = true;
        //         isStep3 = true;
        //         isStep4 = true;
        //         isStep5 = true;
        //         break;
        // }

        if (clickable === true && onClick) {
            onClick(stepClicked);
        }
    }

    render() {
        const { legends, multiStep, step, clickable } = this.props;
        const { shorterLegend, activedLegends } = this.state;
        // console.log('Harem-activedLegends', this.state.activedLegends);
        // console.log('Harem-isStep4', this.state.isStep4)
        let activeMsgId = "";
        if (multiStep && shorterLegend.length > 0 && step > 1) {
            activeMsgId = shorterLegend[step - 2];
        }

        return (
            <div
                // className={this.getWizardClassName()}
                className="wizard"
            >
                <div className="row no-gutters">
                    {/* Nếu lỗi thì render step 1 */}
                    {/* {!multiStep && // Nếu multiStep = false -- ko bao gio vao TH nay
                        (<div className="col-auto">
                            <div className="step step-1">
                                <div className="step-icon">
                                    <i className="fas fa-user"></i>
                                </div>
                                <div className="step-legend">
                                    {legends.length > 0 && (
                                        <FormattedMessage id={legends[0]} />
                                    )}
                                </div>
                            </div>
                        </div>)} */}

                    {/* /** Shorter Legends
                    *  'account-register-2.step.step-2',
                        'account-register-2.step.step-3',
                        'account-register-2.step.step-4',
                        'account-register-2.step.step-5',
                    */ }


                    {/* Render step 1 */}
                    {multiStep && shorterLegend.length > 0 && // Nếu multiStep = true và true
                        // (<div className="col-auto">
                        (<div className="col">
                            <div
                                className={"step step-first " + (step === 1 ? "active" : "") + (step > 1 ? " actived" : "") + (clickable === true ? ' clickable' : '')}
                                onClick={this.onLegendClick.bind(null, 1)}
                            >
                                <div className="step-icon">
                                    <i className="fas fa-user"></i>
                                </div>
                                <div className="step-legend">
                                    <FormattedMessage id={legends[0]} />
                                </div>
                            </div>
                        </div>)}

                    {/* Render step 2 3 4 5 */}
                    {multiStep && shorterLegend.length > 0 &&
                        shorterLegend.map((element, index) => {
                            let active = (activeMsgId !== "" && activeMsgId === element);
                            let actived = activedLegends.includes(element);
                            let activing = actived && active;
                            let stepLegend = legends.findIndex(item => item === element) + 1;
                            let iconStep = "";
                            switch (stepLegend) {
                                case 2:
                                    iconStep = <i className="fas fa-fingerprint"></i>
                                    break;
                                case 3:
                                    iconStep = <i className="fas fa-id-card-alt"></i>
                                    break;
                                case 4:
                                    iconStep = <i className="fas fa-headset" />
                                    break;
                                case 5:
                                    iconStep = <i className="fas fa-book-open"></i>
                                default:
                                    iconStep = <i className="fas fa-book-open" />
                                    break;
                            }
                            return (
                                <div className="col" key={index}>
                                    <div
                                        className={"step step-middle " + (activing ? 'active' : "") + ((actived && !active) ? 'actived' : "")}
                                        onClick={this.onLegendClick.bind(null, stepLegend)}
                                    >
                                        <div className="step-icon">
                                            {iconStep}
                                        </div>
                                        <div className="step-legend">
                                            {legends.length > 1 && (
                                                <FormattedMessage id={element} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    {/* {!multiStep && (<div className="col">
                        <div className="step step-2">
                            <div className="step-icon">
                                <i className="fas fa-lock-alt" />
                            </div>
                            <div className="step-legend">
                                {legends.length > 1 && (
                                    <FormattedMessage id={legends[1]} />
                                )}
                            </div>
                        </div>
                    </div>)} */}


                    {multiStep && legends.length > 5 && (
                        // <div className="col-auto">
                        <div className="col">
                            <div className={"step step-last " + (step === legends.length ? "active" : "")}>
                                <div className="step-icon">
                                    <i className="fas fa-check" />
                                </div>
                                <div className="step-legend">
                                    <FormattedMessage id={legends[legends.length - 1]} />
                                </div>
                            </div>
                        </div>
                    )}

                    {/* {!multiStep && (
                        <div className="col-auto">
                            <div className="step step-3">
                                <div className="step-icon">
                                    <i className="fas fa-check" />
                                </div>
                                <div className="step-legend">
                                    {legends.length > 2 && (
                                        <FormattedMessage id={legends[2]} />
                                    )}
                                </div>
                            </div>
                        </div>
                    )} */}
                </div>
            </div>
        );
    }

}

export default WizardComponent;
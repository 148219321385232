import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Banner.scss';
import bannerDesktop from "../../../assets/images/register/dang-ki-thong-tin/banner-desktop.jpg";
import bannerMobile from "../../../assets/images/register/dang-ki-thong-tin/banner-mobile.jpg";

class Banner extends Component {
    render() {

        return (
            <div className="container-banner">
                <div className="banner-page">
                    <img src={bannerDesktop} alt="" className="img-banner banner-desktop" />
                    <img src={bannerMobile} alt="" className="img-banner banner-mobile" />
                    <div className="title-banner">
                        <h4>Chỉ <span>1 phút</span> có ngay tài khoản</h4>
                        <h3>Đơn giản - Tiện lợi - Bảo mật</h3>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Banner);

import React, { Component, Fragment } from "react";
import { FormattedMessage, FormattedNumberParts } from "react-intl";
import "./CreateAccountStep5.scss";
import userService from "../../services/userService";
import _ from "lodash";
import { LanguageUtils, ERROR_CODE } from '../../../src/utils';
import config from '../../config';

const REG_PASSWORD = /^(?=.*?[A-Z])(?=.*?[0-9]).{8,}$/;
const ENABLE_CHECK_PASSWORD_REG_ONLINE = config.app.ENABLE_CHECK_PASSWORD_REG_ONLINE;

class CreateAccountStep5 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '', //tên đăng nhập
            password: '', //password
            confirmPassword: '', //confirm password
            errMessage: '', //thông báo lỗi

            isValidUsername: true,
            isValidPassword: true,
            isValidConfirmPassword: true,
            errUsername: '',
            errPassword: '',
            errConfirmPassword: '',
            isShowPassword: false,
        }
    }

    componentDidMount() {
        let { executeScroll } = this.props;
        executeScroll();
    }

    isValidInputs = (username, password, confirmPassword) => {
        let { language } = this.props;
        if (!password) {
            this.setState({
                isValidUsername: true,
                isValidPassword: false,
                isValidConfirmPassword: true,
                errUsername: '',
                errPassword: 'Mật khẩu không thể để trống!',
                errConfirmPassword: '',
            })
            return false;
        }

        if (password && confirmPassword && password === confirmPassword && ENABLE_CHECK_PASSWORD_REG_ONLINE) {
            let isValid = REG_PASSWORD.test(password);
            if (!isValid) {
                this.setState({
                    isValidUsername: true,
                    isValidPassword: true,
                    isValidConfirmPassword: true,
                    errUsername: '',
                    errPassword: '',
                    errConfirmPassword: '',
                    errMessage: LanguageUtils.getMessageByKey(`error-code.${-260266}`, language)
                })
                return false;
            }
        }

        if (!confirmPassword || (password && confirmPassword && password !== confirmPassword)) {
            this.setState({
                isValidUsername: true,
                isValidPassword: true,
                isValidConfirmPassword: false,
                errUsername: '',
                errPassword: '',
                errConfirmPassword: 'Mật khẩu nhập lại không chính xác!',
            })
            return false;
        }

        return true;
    }

    nextToStep6 = async () => {
        try {
            let { setStep, setParentStateFromChild, allData, language } = this.props;
            let {
                username, password, confirmPassword
            } = this.state;

            await this.setState({
                errUsername: '',
                errPassword: '',
                errConfirmPassword: '',
                errMessage: '',
                isValidUsername: false,
                isValidPassword: false,
                isValidConfirmPassword: false,
            })

            //validate inputs
            let isValidInputs = this.isValidInputs(username, password, confirmPassword);
            if (!isValidInputs) return;

            let { sessionid } = allData.dataStep1_1
            if (sessionid && sessionid.id) {
                await userService.REG_ONLINE({
                    refid: sessionid.id,
                    // username: username,
                    username: 'NOTUSE', // không sử dụng
                    userpass: password
                }).then(async data => {
                    if (data && data.p_err_code === ERROR_CODE.VALID) {
                        await setParentStateFromChild(5, {
                            username, password, confirmPassword
                        })
                        await setStep(6);
                    }
                    else if (data.p_err_code === ERROR_CODE.INVALID_REG_ONLINE_PASSWORD) {
                        this.setState({
                            errMessage: LanguageUtils.getMessageByKey(`error-code.${data.p_err_code}`, language)
                        })
                    }
                    else {
                        this.setState({
                            // errMessage: 'Đã có lỗi xảy ra, vui lòng thử lại sau!'
                            errMessage: '[' + data.p_err_code + '] Đã có lỗi xảy ra. Vui lòng thử lại sau!'

                        })
                        console.log('error REG_ONLINE', data)
                    }

                }).catch(e => {
                    this.setState({
                        errMessage: '[CATCH] Đã có lỗi xảy ra, vui lòng thử lại sau!'
                    })
                    console.log('error REG_ONLINE', e)
                })
            }
        } catch (e) {
            console.log(e)
            alert('nextToStep6.:Error=' + e)
        }
    }

    backToStep4 = async () => {
        let { setStep } = this.props;
        await setStep(4);
    }

    onChangeAttribute = (att, event) => {
        if (event && event.target) {
            this.state[att] = event.target.value;
            this.setState((state) => ({
                ...state,
            }));
        }
    }

    hideShowPassword = (value, type) => {
        this.setState({
            isShowPassword: value
        })
    }

    onClickHideShowPassword = (value, type) => {
        let { isShowPassword } = this.state
        let check = isShowPassword ? value : value * (-1)
        isShowPassword = (check != 1) ? true : false;
        this.setState({
            isShowPassword
        })
    }

    render() {
        let { username, password, confirmPassword, errMessage,
            isValidUsername, isValidPassword, isValidConfirmPassword, errUsername, errPassword, errConfirmPassword,
            isShowPassword
        } = this.state;
        let { allData } = this.props;
        let { phoneNumber, email } = allData.dataStep1;

        let placeHolderUsername = 'Email/Số tài khoản/Số điện thoại di động';

        return (
            <div className="page-dangkithongtin3-xac-thuc-33">
                <div className="row_container">
                    <div className="box-dang-nhap">
                        <div className="row-info">
                            <div className="title-info">Tên đăng nhập</div>
                            <div className="value-info">
                                <span className={'txt-username'}  >{placeHolderUsername}</span> <br />
                                <span className={'noti-username'}  >Tên đăng nhập của Quý khách mặc định là một trong số các trường thông tin trên.</span>
                            </div>
                        </div>
                        <div className="row-info">
                            <div className="title-info">Mật khẩu</div>
                            <div className="value-info">
                                <input type={isShowPassword ? 'text' : 'password'} name="mat-khau"
                                    value={password}
                                    onChange={(event) => this.onChangeAttribute('password', event)}
                                    className={isValidPassword ? 'input-1' : 'input-1 error'} required />

                                <i className={!isShowPassword ? 'far fa-eye-slash' : 'far fa-eye'}
                                    onClick={() => this.onClickHideShowPassword(1, 'onClick')}
                                ></i>
                                {/* </span> */}
                                {errPassword && <span className="text-err">{errPassword}</span>}

                            </div>
                        </div>
                        <div className="row-info">
                            <div className="title-info">Nhập lại mật khẩu</div>
                            <div className="value-info">
                                <input type="password" name="nhap-lai-mat-khau"
                                    value={confirmPassword}
                                    onChange={(event) => this.onChangeAttribute('confirmPassword', event)}
                                    className={isValidConfirmPassword ? 'input-1' : 'input-1 error'} required />
                                {errConfirmPassword && <span className="text-err">{errConfirmPassword}</span>}
                            </div>
                        </div>
                    </div>
                    {errMessage &&
                        <div className="row-info text-center">
                            <span className="text-err">{errMessage}</span>
                        </div>
                    }
                    <div className="box-click row-wrapper">

                        <button className="button-submit quay-lai" onClick={() => this.backToStep4()}>Quay lại</button>
                        <button className="button-submit tiep-tuc" onClick={() => this.nextToStep6()}>Tiếp tục</button>
                    </div>
                </div>
            </div >
        );
    }
}

export default CreateAccountStep5;
import * as queryString from 'query-string';
import _ from 'lodash';
import axios from '../axios';
import axios2 from '../axios2';
import config from '../config';

const KSS_URL = config.app.KSS_URL;

const userService = {

    /**
      * các endpoint mở tài khoản KSS
      */
    validatePhone(data) {
        return axios.post('/checkmobile', data);
    },

    validateEmail(data) {
        return axios.post('/checkemail', data);
    },

    openAccount(data) {
        //Sử dụng axios2 trong trường hợp OpenAccount
        return axios2.post('/open-account', data);
    },

    //API đăng ký tài khoản thụ hưởng
    REG_BENEFITACCT(data) {
        return axios.post('/reg-benefitacct', data);
    },

    //API đăng ký dịch vụ trực tuyến
    REG_ONLINE(data) {
        return axios.post('/reg-online', data);
    },

    //API gen OTP
    genOTP(data) {
        return axios.post('/gen-otp', data);
    },

    //API validate OTP
    validateOTP(data) {
        return axios.post('/validate-otp', data);
    },

    //API validate cmnd
    validateIdcode(data) {
        return axios.post('/validate-idcode', data)
    },

    getDateTimeFlex(data) {
        return axios.post('/get-time', data)
    },

    activeEmail(data) {
        return axios.post('/active-email', data)
    },

    getBankInfor(data) {
        return axios.get('/bank-infor?requestid=' + data.requestid)
    },

    getOpenPointEKYC(data) {
        return axios.get('/open-point-ekyc?requestid=' + data.requestid)
    },



    //gen pdf hợp đồng mở tài khoản
    //https://ekycuat.kss.com.vn/pfiller/fill
    generateContractPDF(data) {
        // hardcode data
        // data = {
        //     "templateid": "MTKV5",
        //     "custodycd": "047C504512",
        //     "dateDD": "28",
        //     "dateMM": "04",
        //     "dateYY": "21",
        //     "canhan_ten": "Nguyễn Văn A",
        //     "canhan_cmt": "001002003004",
        //     "canhan_ngaycap": "20/11/1997",
        //     "canhan_noicap": "CA. TP HANOI",
        //     "loaihinh_canhan": "yes",
        //     "loaihinh_tochuc": "no",
        //     "canhan_dclienhe": "233 Dinh Liet, Hoang mai ha noi, cau go, van van va van van",
        //     "canhan_dcthuongtru": "233 Dinh Liet, Hoang mai ha noi, cau go, van van va van van",
        //     "canhan_didong": "012345456678",
        //     "canhan_email": "nguyenvana@gmail.com",
        //     "muctieu_nganhan": "yes",
        //     "muctieu_trunghan": "yes",
        //     "muctieu_daihan": "no",
        //     "chapnhanrr_thap": "yes",
        //     "chapnhanrr_tb": "no",
        //     "chapnhanrr_cao": "no",
        //     "kinhnghiem_chuaco": "yes",
        //     "kinhnghiem_cophieu": "no",
        //     "kinhnghiep_traiphieu": "no",
        //     "kinhnghiem_phaisinh": "no",
        //     "tkthuhuong_sotk": "12312312343",
        //     "tkthuhuong_tentk": "Nguyen Van X",
        //     "tkthuhuong_tennganhang": "Viet Com Bank",
        //     "thoidiem_ky": "04/04/2021 12:30:00"
        // }

        return axios.post(`${KSS_URL}/pfiller/fill`, data)
    }
















    // changeTheme(theme) {
    //     const queryParams = queryString.stringify({
    //         "theme": theme
    //     });
    //     return axios.post('userdata/changeTheme', queryParams);
    // },


    // /**
    //  * Lấy Allcode
    //  */
    // getAllCode(cdname, cdtype) {
    //     var queryParams = {
    //         cdname,
    //         cdtype
    //     };
    //     return axios.get(`userdata/getAllCode?` + queryString.stringify(queryParams));
    // },

    // /**Lấy code chi nhánh */
    // getAllCodeBranch() {
    //     return axios.get(`userdata/getAllBranch`);
    // },


    // createAccount(createAccountBody) {
    //     return axios.post('userdata/createAccount', createAccountBody);
    // },

    // /**
    //  * Tạo tài khoản cơ sở
    //  */
    // createAccount2(createAccountBody) {
    //     const header = {
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //         }
    //     }
    //     var form = new FormData();
    //     for (var property in createAccountBody) {
    //         console.log('createAccount2.:createAccountBody.:property==', property); // Outputs: foo, fiz or fiz, foo
    //         if (property && property != 'signature') {
    //             form.append(property, createAccountBody[property]);
    //         }
    //     }
    //     form.append('signature', createAccountBody.signature); // ảnh chữ ký phải nằm cuối cùng
    //     return axios.post('userdata/createAccount2', form, header);
    //     // return axios.post('http://localhost:1341/createAccount2', form, header);
    //     // Old
    //     // return axios.post('userdata/createAccount2', createAccountBody);
    // },

    // /**
    //  *  Tạo tài khoản phái sinh
    //  */

    // createAccount2PS(createAccountBody) {
    //     return axios.post('userdata/createAccount2PS', createAccountBody);
    // },

    // /**
    //  * Check auto create acc
    //  *
    //  */

    // preCheckRegister(data) {
    //     return axios.post('userdata/isAutoCreAcc', data);
    // },



};

export default userService;

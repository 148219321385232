import React, { Component, Fragment } from "react";
import { FormattedMessage, FormattedNumberParts } from "react-intl";
import "./CreateAccountStep5.scss";
import userService from "../../services/userService";
import _ from "lodash";
import { LanguageUtils, ERROR_CODE } from '../../../src/utils';


class CreateAccountStep6Contract extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }



    render() {
        return (
            <>
                <ol>
                    <li><strong><u>Giải th&iacute;ch từ ngữ</u></strong></li>
                </ol>
                <p>Trong Phụ lục n&agrave;y, c&aacute;c từ ngữ dưới đ&acirc;y được hiểu như sau:</p>
                <ul>
                    <li><strong>KSS</strong>: C&ocirc;ng ty cổ phần Chứng kho&aacute;n KSS (hoặc tổ chức kế thừa).</li>
                    <li><strong>Kh&aacute;ch h&agrave;ng</strong>: Kh&aacute;ch h&agrave;ng mở TKCK tại KSS.</li>
                    <li><strong>TKCK:</strong> T&agrave;i khoản giao dịch chứng kho&aacute;n của Kh&aacute;ch h&agrave;ng mở tại KSS v&agrave; c&aacute;c t&agrave;i khoản, tiểu khoản kh&aacute;c được KSS mở dưới t&ecirc;n Kh&aacute;ch h&agrave;ng gắn với t&agrave;i khoản giao dịch chứng kho&aacute;n đ&oacute; nhằm cung cấp dịch vụ được ph&eacute;p cho Kh&aacute;ch h&agrave;ng (gồm cả t&agrave;i khoản lưu k&yacute;, t&agrave;i khoản giao dịch ph&aacute;i sinh (nếu Kh&aacute;ch h&agrave;ng đăng k&yacute; mở), t&agrave;i khoản giao dịch k&yacute; quỹ (nếu Kh&aacute;ch h&agrave;ng đăng k&yacute; mở), t&agrave;i khoản/tiểu khoản trong t&agrave;i khoản chuy&ecirc;n dụng của KSS mở để quản l&yacute; t&agrave;i sản của Kh&aacute;ch h&agrave;ng).</li>
                    <li><strong>Lệnh</strong>: Lệnh, chỉ thị, chỉ dẫn, chỉ định, th&ocirc;ng b&aacute;o, th&ocirc;ng điệp hoặc tương đương do Kh&aacute;ch h&agrave;ng lập/gửi/x&aacute;c nhận (gồm cả lệnh mua, lệnh b&aacute;n, lệnh chuyển tiền hay lệnh thanh to&aacute;n) để y&ecirc;u cầu KSS thực hiện một c&ocirc;ng việc/dịch vụ li&ecirc;n quan đến TKCK.</li>
                    <li><strong>Hợp đồng mở TKCK</strong>: Hợp đồng mở t&agrave;i khoản giao dịch chứng kho&aacute;n m&agrave; Phụ lục n&agrave;y đ&iacute;nh k&egrave;m.</li>
                    <li><strong>Email/Số điện thoại di động</strong>: Địa chỉ thư điện tử v&agrave; số điện thoại di động Kh&aacute;ch h&agrave;ng đ&atilde; đăng k&yacute; với KSS tại phần ch&iacute;nh Hợp đồng mở TKCK.</li>
                </ul>
                <ol start="2">
                    <li><strong><u>Mở t&agrave;i khoản</u></strong></li>
                </ol>
                <p>Từ thời điểm Hợp đồng mở TKCK c&oacute; hiệu lực , KSS sẽ mở t&agrave;i khoản giao dịch chứng kho&aacute;n c&ugrave;ng với c&aacute;c t&agrave;i khoản/tiểu khoản theo đăng k&yacute; của Kh&aacute;ch h&agrave;ng.</p>
                <ol start="3">
                    <li><strong><u>Dịch vụ chứng kho&aacute;n</u></strong></li>
                </ol>
                <p>KSS sẽ cung cấp c&aacute;c dịch vụ chứng kho&aacute;n sau tr&ecirc;n TKCK cho Kh&aacute;ch h&agrave;ng:</p>
                <ul>
                    <li>Thực hiện giao dịch mua, b&aacute;n v&agrave; giao dịch kh&aacute;c đối với chứng kho&aacute;n v&agrave; t&agrave;i sản t&agrave;i ch&iacute;nh được ph&eacute;p;</li>
                    <li>Tham gia v&agrave;o việc thanh to&aacute;n tiền v&agrave; b&ugrave; trừ chứng kho&aacute;n do Kh&aacute;ch h&agrave;ng giao dịch;</li>
                    <li>Nhận tiền Kh&aacute;ch h&agrave;ng nộp/chuyển để giao dịch chứng kho&aacute;n v&agrave; cho c&aacute;c mục đ&iacute;ch đầu tư v&agrave; thanh to&aacute;n được ph&eacute;p kh&aacute;c; nhận tiền của tổ chức/c&aacute; nh&acirc;n kh&aacute;c chuyển hoặc thanh to&aacute;n cho Kh&aacute;ch h&agrave;ng; quản l&yacute; nguồn tiền của Kh&aacute;ch h&agrave;ng v&agrave; thực hiện Lệnh/y&ecirc;u cầu sử dụng tiền của Kh&aacute;ch h&agrave;ng (gồm cả thanh to&aacute;n giao dịch chứng kho&aacute;n, r&uacute;t tiền, thanh to&aacute;n cho giao dịch v&agrave; mục đ&iacute;ch kh&aacute;c v&agrave; chuyển tiền đến t&agrave;i khoản kh&aacute;c).</li>
                    <li>Quản l&yacute; chứng kho&aacute;n v&agrave; t&agrave;i sản được ph&eacute;p kh&aacute;c của Kh&aacute;ch h&agrave;ng;</li>
                    <li>Hỗ trợ Kh&aacute;ch h&agrave;ng thực hiện quyền của người sở hữu chứng kho&aacute;n;</li>
                    <li>Dịch vụ ứng trước tiền b&aacute;n chứng kho&aacute;n;</li>
                    <li>Dịch vụ giao dịch chứng kho&aacute;n trực tuyến;</li>
                    <li>C&aacute;c dịch vụ giao dịch kh&aacute;c gắn với từng loại t&agrave;i khoản/tiểu khoản ph&ugrave; hợp với nghiệp vụ được ph&eacute;p của KSS; v&agrave;</li>
                    <li>C&aacute;c dịch vụ kh&aacute;c ph&ugrave; hợp với quy định của ph&aacute;p luật được KSS cung cấp trong từng thời kỳ v&agrave; được c&ocirc;ng bố tr&ecirc;n website của KSS.</li>
                </ul>
                <ol start="4">
                    <li><strong><u>Phương thức quản l&yacute; tiền</u></strong></li>
                </ol>
                <p>Kh&aacute;ch h&agrave;ng lựa chọn phương thức quản l&yacute; tiền giao dịch chứng kho&aacute;n của kh&aacute;ch h&agrave;ng trong c&aacute;c t&agrave;i khoản chuy&ecirc;n dụng mở tại c&aacute;c ng&acirc;n h&agrave;ng thương mại.&nbsp; Kh&aacute;ch h&agrave;ng l&agrave; chủ sở hữu hợp ph&aacute;p đối với to&agrave;n bộ số tiền tr&ecirc;n TKCK.&nbsp; Kh&aacute;ch h&agrave;ng được hưởng mức l&atilde;i suất theo quy định của KSS c&ocirc;ng bố trong từng thời kỳ đối với số dư tiền c&oacute; tr&ecirc;n TKCK.</p>
                <p>Kh&aacute;ch h&agrave;ng được r&uacute;t/chuyển một phần hoặc to&agrave;n bộ tiền, chứng kho&aacute;n ra khỏi TKCK khi kh&ocirc;ng c&oacute; nhu cầu giao dịch hoặc khi chấm dứt Hợp đồng mở TKCK với điều kiện tiền v&agrave; chứng kho&aacute;n đ&oacute; kh&ocirc;ng bị r&agrave;ng buộc, hạn chế bởi c&aacute;c nghĩa vụ hoặc tr&aacute;ch nhiệm n&agrave;o với KSS/hoặc với bất kỳ b&ecirc;n thứ ba n&agrave;o c&oacute; li&ecirc;n quan hoặc theo y&ecirc;u cầu của cơ quan c&oacute; thẩm quyền v&agrave; Kh&aacute;ch h&agrave;ng kh&ocirc;ng c&ograve;n bất kỳ nghĩa vụ c&ograve;n tồn đọng n&agrave;o đối với KSS hoặc với c&aacute;c b&ecirc;n thứ ba theo c&aacute;c thỏa thuận m&agrave; KSS l&agrave; một b&ecirc;n c&oacute; quyền v&agrave; nghĩa vụ.</p>
                <ol start="5">
                    <li><strong><u>C&aacute;ch thức nhận lệnh</u></strong></li>
                </ol>
                <p>Kh&aacute;ch h&agrave;ng c&oacute; thể gửi Lệnh c&oacute; h&igrave;nh thức v&agrave; nội dung đ&uacute;ng quy định tới KSS theo một trong c&aacute;c phương thức sau:</p>
                <ul>
                    <li>Trực tiếp tại quầy giao dịch;</li>
                    <li>Qua điện thoại; v&agrave;</li>
                    <li>Qua hệ thống giao dịch điện tử của KSS.</li>
                </ul>
                <ol start="6">
                    <li><strong><u>Quyền v&agrave; nghĩa vụ của Kh&aacute;ch h&agrave;ng</u></strong></li>
                </ol>
                <ul>
                    <li>Được KSS cung cấp th&ocirc;ng tin về chứng kho&aacute;n, th&ocirc;ng tin về thực hiện quyền ph&aacute;t sinh từ chứng kho&aacute;n v&agrave; c&aacute;c th&ocirc;ng b&aacute;o kh&aacute;c tr&ecirc;n website ch&iacute;nh thức của KSS v&agrave;/hoặc c&aacute;c phương thức kh&aacute;c do KSS lựa chọn ph&ugrave; hợp với quy định của ph&aacute;p luật;</li>
                    <li>Được KSS bảo mật th&ocirc;ng tin tr&ecirc;n TKCK theo quy định;</li>
                    <li>Tự bảo mật quyền truy cập Email, Số điện thoại di động đ&atilde; đăng k&yacute;;</li>
                    <li>Tự chịu rủi ro đối với mọi quyết định đầu tư, sử dụng TKCK, tiền v&agrave; t&agrave;i sản tr&ecirc;n TKCK;</li>
                    <li>Đảm bảo t&iacute;nh hợp ph&aacute;p về tiền v&agrave; t&agrave;i sản được chuyển v&agrave;o TKCK;</li>
                    <li>Tự thực hiện c&aacute;c nghĩa vụ c&ocirc;ng bố th&ocirc;ng tin theo quy định; v&agrave;</li>
                    <li>C&aacute;c quyền v&agrave; nghĩa vụ kh&aacute;c theo thỏa thuận với KSS v&agrave;/hoặc ph&aacute;p luật.</li>
                </ul>
                <p>&nbsp;</p>
                <ol start="7">
                    <li><strong><u>Quyền v&agrave; nghĩa vụ của KSS</u></strong></li>
                </ol>
                <ul>
                    <li>Được được ghi tăng/ghi giảm chứng kho&aacute;n hoặc t&agrave;i sản tr&ecirc;n TKCK để thực hiện thanh to&aacute;n theo Lệnh mua/b&aacute;n của Kh&aacute;ch h&agrave;ng đ&atilde; được thực hiện;</li>
                    <li>Được phong tỏa một phần hoặc to&agrave;n bộ tiền v&agrave; t&agrave;i sản kh&aacute;c của Kh&aacute;ch h&agrave;ng tr&ecirc;n TKCK theo y&ecirc;u cầu của cơ quan Nh&agrave; nước c&oacute; thẩm quyền, theo y&ecirc;u cầu của Kh&aacute;ch h&agrave;ng hoặc theo thỏa thuận trước giữa Kh&aacute;ch h&agrave;ng v&agrave; KSS;</li>
                    <li>Tu&acirc;n thủ quy định của ph&aacute;p luật, quy chế hay quy định c&oacute; li&ecirc;n quan trong việc cung ứng dịch vụ cho Kh&aacute;ch h&agrave;ng li&ecirc;n quan đến TKCK;</li>
                    <li>Th&ocirc;ng b&aacute;o, cung cấp th&ocirc;ng tin cho Kh&aacute;ch h&agrave;ng về TKCK;</li>
                    <li>Bồi thường v&agrave; bồi ho&agrave;n đối với thiệt hại, tổn thất thực tế của Kh&aacute;ch h&agrave;ng ph&aacute;t sinh trực tiếp từ việc KSS c&oacute; h&agrave;nh vi vi phạm Hợp đồng mở TKCK một c&aacute;ch chủ &yacute; hoặc lừa dối, v&agrave; ngược lại đối với h&agrave;nh vi Kh&aacute;ch h&agrave;ng sử dụng TKCK v&agrave; dịch vụ tại KSS kh&ocirc;ng đ&uacute;ng quy định, hoặc vi phạm Hợp đồng mở TKCK, hoặc vi phạm ph&aacute;p luật; v&agrave;</li>
                    <li>C&aacute;c quyền v&agrave; nghĩa vụ kh&aacute;c theo thỏa thuận với KSS v&agrave;/hoặc ph&aacute;p luật.</li>
                </ul>
                <ol start="8">
                    <li><strong><u>Luật &aacute;p dụng v&agrave; giải quyết tranh chấp</u></strong></li>
                </ol>
                <ul>
                    <li>Hợp đồng mở TKCK được điều chỉnh bởi ph&aacute;p luật Việt Nam</li>
                    <li>Mọi tranh chấp sẽ được giải quyết th&ocirc;ng qua thương lượng trong thời gian 30 ng&agrave;y, hết thời hạn n&agrave;y tranh chấp c&oacute; thể được giải quyết tại T&ograve;a &aacute;n c&oacute; thẩm quyền.</li>
                </ul>
                <ol start="9">
                    <li><strong><u>Hiệu lực, Chấm dứt v&agrave; Thanh l&yacute; Hợp đồng</u></strong></li>
                </ol>
                <ul>
                    <li>Hợp đồng mở TKCK c&oacute; hiệu lực kể từ ng&agrave;y k&yacute;.</li>
                    <li>Hợp đồng mở TKCK c&oacute; thể bị chấm dứt bằng thỏa thuận của KSS v&agrave; Kh&aacute;ch h&agrave;ng, hoặc bằng h&agrave;nh vi đơn phương của KSS hoặc Kh&aacute;ch h&agrave;ng trong một số trường hợp cụ thể được quy định trong Bộ Điều khoản v&agrave; Điều kiện.</li>
                    <li>Khi Hợp đồng mở TKCK chấm dứt, c&aacute;c b&ecirc;n sẽ c&ugrave;ng thực hiện c&aacute;c thủ tục cần thiết để:</li>
                    <li>KSS chuyển giao tiền v&agrave; t&agrave;i sản c&ograve;n tr&ecirc;n TKCK cho Kh&aacute;ch h&agrave;ng (sau khi đ&atilde; khấu trừ c&aacute;c loại thuế, ph&iacute; v&agrave; nghĩa vụ c&ograve;n tồn đọng với KSS hoặc b&ecirc;n thứ ba c&oacute; thỏa thuận với KSS v&agrave; Kh&aacute;ch h&agrave;ng);</li>
                    <li>Kh&aacute;ch h&agrave;ng thanh to&aacute;n đủ c&aacute;c khoản ph&iacute;, chi ph&iacute; v&agrave; thuế c&ograve;n tồn đọng (nếu c&oacute;) li&ecirc;n quan đến TKCK; v&agrave;</li>
                    <li>KSS đ&oacute;ng TKCK.</li>
                </ul>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </>
        );
    }
}

export default CreateAccountStep6Contract;
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import * as actions from "../../store/actions";
import {
    ToastUtil,
    KeyCodeUtils,
    LanguageUtils,
    path,
    ERROR_CODE,
    BANK_INFO,
} from "../../utils";
import { ekycServer, userService } from "../../services";
import _ from "lodash";
import config from "../../config";

import "./CreateAccount.scss";
import WizardComponent from "./Wizard/WizardComponent";

import { push, Link } from "connected-react-router";

import { transferService } from "../../services";

import { emitter } from "../../clients/emitter";
import { Event } from "../../utils";
import SkipEkycModal from "../../../src/components/SkipEkycModal";

import {
    CreateAccountStep1,
    CreateAccountStep1Confirm,
} from "./CreateAccountStep1";
import CreateAccountStep2 from "./CreateAccountStep2";
import { CreateAccountStep3, CreateAccountStep3_1 } from "./CreateAccountStep3";
import CreateAccountStep4 from "./CreateAccountStep4";
import CreateAccountStep5 from "./CreateAccountStep5";
import CreateAccountStep6 from "./CreateAccountStep6";

import { Modal } from "reactstrap";

const ENABLE_CLICK_TITLE_STEP = config.app.ENABLE_CLICK_TITLE_STEP;
const TIME_OUT_LINK_CREATE_ACCOUNT = config.app.TIME_OUT_LINK_CREATE_ACCOUNT;

class CreateAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
            allData: {
                dataStep1: {},
                dataStep1_1: {},
                dataStep2: {},
                dataStep3: {},
                dataStep3_2: {},
                dataStep4: {},
                dataStep5: {},
                isOpenModalTimeout: true,
            },
            isShowModalClearSession: false, //modal hoàn tất, tài khoản đã mở thành công, cần kích hoạt trong email
            // isShowModalRefresh: false, //modal hiện ra khi ko gọi đc api banks và api điểm ekyc
            // isSystemError: false, // dùng để check tại step 1
        };
        this.listenToTheEmitter();

        this.scrollToContentRef = React.createRef();
    }

    wizardLegends = [
        "account-register-2.step.step-1",
        "account-register-2.step.step-2",
        "account-register-2.step.step-3",
        "account-register-2.step.step-4",
        "account-register-2.step.step-5",
        "account-register-2.step.step-6",
    ];

    listenToTheEmitter() {
        let self = this;
        emitter.on(Event.DO_SUBMIT_ADD_FILE_CAM_SUSSESS, (data) => {
            self.setStep(2.1);
        });
        emitter.on(Event.DO_SUBMIT_COMPARE_FILE_AND_FACE_SUSSESS, (data) => {
            self.setStep(3);
        });
        emitter.on(Event.DO_SKIP_SUBMIT_COMPARE_FILE_AND_FACE, (data) => {
            // Popup cảnh báo ...
            // if (window.confirm('Bạn có muốn bỏ qua bước này?')) {
            //     self.setStep(3)
            // }
            self.setState({ isSkipEkycModal: true, dataSkipEkycModal: data });
        });

        emitter.on(Event.HIDE_MODAL_TIMEOUT, (data) => {
            this.setState({
                allData: {
                    ...this.state.allData,
                    isOpenModalTimeout: false,
                },
            });
        });
    }

    async componentDidMount() {
        let self = this;
        setTimeout(function () {
            let { allData } = self.state;
            // if (currentStep == 6 || currentStep == 5) {
            // check các trường hợp ở các màn hình mở thành công hoặc mở thất bại hoặc ...
            //thì sẽ không bật ra popup ClearSession. K
            //không dùng lại được currentStep vì các thông báo tại các bước nhiều khi dùng chung currentStep => phải tổ chức lại hoặc ( Dùng cách này => sử dụng 1 biến mới*)
            //     return null
            // }

            if (!allData.isOpenModalTimeout) {
                return;
            }
            self.setState({
                isShowModalClearSession: true,
            });
            // // Trường hợp KH không click xác nhận thì hệ thống tự động submit "Xác nhận"
            // setTimeout(function () {
            //     self.setState({
            //         isShowModalClearSession: true,
            //     })
            // }, 30 * 1000)
        }, TIME_OUT_LINK_CREATE_ACCOUNT * 60 * 1000);
    }

    redirectToPrev = () => {
        const { currentStep } = this.state;
        this.setState({
            currentStep: currentStep - 1,
        });
    };

    redirectToNext = () => {
        const { currentStep } = this.state;
        this.setState({
            currentStep: currentStep + 1,
        });
    };

    setStep = (step) => {
        this.setState({
            currentStep: step,
        });
    };

    canGoToStep2 = (step) => {
        const { allData, resultValidateStep2 } = this.state;
        if (allData.currentTransaction == "0000000") {
            resultValidateStep2.currentTransaction = false;
        } else {
            resultValidateStep2.currentTransaction = true;
        }

        this.setState({
            resultValidateStep2: resultValidateStep2,
        });

        const arrResult = [];
        for (const property in resultValidateStep2) {
            arrResult.push(resultValidateStep2[property]);
        }
        let canGoToStep3 = arrResult.every((item) => item === true);
        if (canGoToStep3) {
            this.setStep(step);
        }
    };

    onLegendClick = (stepClicked) => {
        if (ENABLE_CLICK_TITLE_STEP) {
            this.setState({ currentStep: stepClicked });
        }
    };

    goScroll = (position) => {
        // document.body.scrollTop = 0; // For Safari
        // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        // window.scrollTo({ top: position, behavior: 'smooth' });
        window.scrollTo(0, 0);
    };
    //srcoll to ref element khi click chuyển next
    executeScroll = () => {
        // this.scrollToContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        // this.scrollToContentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        this.goScroll(0);
    };

    setParentStateFromChild = async (indexStep, data) => {
        let dataOld = this.state.allData[`dataStep${indexStep}`];
        this.state.allData[`dataStep${indexStep}`] = { ...dataOld, ...data };
        await this.setState((state) => ({
            ...state,
        }));
    };

    clearAllData = () => {
        this.setState({
            currentStep: 1,
            allData: {
                dataStep1: {},
                dataStep2: {},
                dataStep3: {},
                dataStep3_2: {},
                dataStep4: {},
                dataStep5: {},
            },
        });
    };

    toggleModalClearSession = () => {
        this.setState({
            isShowModalClearSession: !this.state.isShowModalClearSession,
        });
    };
    onConfirmClearSession = () => {
        // F5 lại giao diện
        window.location.reload();
    };

    // toggleModalRefresh = () => {
    //     this.setState({
    //         isShowModalRefresh: !this.state.isShowModalRefresh
    //     })
    // }

    render() {
        // const { currentStep, allData, isShowModalClearSession, isShowModalRefresh, isSystemError } = this.state;
        const { currentStep, allData, isShowModalClearSession } = this.state;
        const { language } = this.props;
        return (
            <div className="account-register-container">
                <div className="text-center" ref={this.scrollToContentRef}>
                    <div className="commonTitle">
                        <FormattedMessage id="account-register-2.title" />
                    </div>
                </div>
                <div className="flow-Create-acc">
                    <WizardComponent
                        className="stepname"
                        step={currentStep}
                        legends={this.wizardLegends}
                        multiStep={true}
                        clickable={true}
                        onClick={this.onLegendClick}
                    />
                </div>
                <div className="content-register">
                    {currentStep === 1 && (
                        <CreateAccountStep1
                            setStep={this.setStep}
                            setParentStateFromChild={
                                this.setParentStateFromChild
                            }
                            allData={allData}
                            language={language}
                            // isSystemError={isSystemError}
                        />
                    )}
                    {currentStep === 1.1 && (
                        <CreateAccountStep1Confirm
                            setStep={this.setStep}
                            setParentStateFromChild={
                                this.setParentStateFromChild
                            }
                            executeScroll={this.executeScroll}
                            allData={allData}
                            language={language}
                        />
                    )}

                    {currentStep === 2 && (
                        <CreateAccountStep2
                            setStep={this.setStep}
                            setParentStateFromChild={
                                this.setParentStateFromChild
                            }
                            executeScroll={this.executeScroll}
                            allData={allData}
                            language={language}
                        />
                    )}

                    {currentStep === 3 && (
                        <CreateAccountStep3
                            setStep={this.setStep}
                            setParentStateFromChild={
                                this.setParentStateFromChild
                            }
                            executeScroll={this.executeScroll}
                            allData={allData}
                            language={language}
                        />
                    )}
                    {currentStep === 3.1 && (
                        <CreateAccountStep3_1
                            setStep={this.setStep}
                            setParentStateFromChild={
                                this.setParentStateFromChild
                            }
                            executeScroll={this.executeScroll}
                            allData={allData}
                            clearAllData={this.clearAllData}
                            language={language}
                        />
                    )}
                    {currentStep === 4 && (
                        <CreateAccountStep4
                            setStep={this.setStep}
                            setParentStateFromChild={
                                this.setParentStateFromChild
                            }
                            executeScroll={this.executeScroll}
                            allData={allData}
                            language={language}
                        />
                    )}

                    {currentStep === 5 && (
                        <CreateAccountStep5
                            setStep={this.setStep}
                            setParentStateFromChild={
                                this.setParentStateFromChild
                            }
                            executeScroll={this.executeScroll}
                            allData={allData}
                            language={language}
                        />
                    )}

                    {currentStep === 6 && (
                        <CreateAccountStep6
                            setStep={this.setStep}
                            setParentStateFromChild={
                                this.setParentStateFromChild
                            }
                            executeScroll={this.executeScroll}
                            allData={allData}
                            language={language}
                            clearAllData={this.clearAllData}
                        />
                    )}
                </div>
                <ModalOpenClearSession
                    isShowModalClearSession={isShowModalClearSession}
                    toggleModalClearSession={this.toggleModalClearSession}
                    onConfirmClearSession={this.onConfirmClearSession}
                />

                {/* <ModalRefresh
                    isShowModalRefresh={isShowModalRefresh}
                    toggleModalRefresh={this.toggleModalRefresh}
                    onConfirmModalRefresh={this.onConfirmClearSession}

                /> */}
            </div>
        );
    }
}

class ModalOpenClearSession extends Component {
    render() {
        let {
            isShowModalClearSession,
            toggleModalClearSession,
            onConfirmClearSession,
        } = this.props;
        return (
            // <Modal isOpen={isShowModalClearSession} toggle={toggleModalClearSession} className="modal-open-acc" centered>
            <Modal
                isOpen={isShowModalClearSession}
                className="modal-open-acc modal-clear-session"
                centered
            >
                <div className="thong-bao-popup popup-text-black">
                    <h3 className="o-h3 text-gradient">THÔNG BÁO</h3>
                    <div className="o-wysiwyg row-upper">
                        <p>
                            Quý khách đã thực hiện quá{" "}
                            {TIME_OUT_LINK_CREATE_ACCOUNT} phút!{" "}
                        </p>
                    </div>
                    <div className="o-wysiwyg row-lower">
                        <p>
                            Quý khách vui lòng thực hiện lại hoặc liên hệ tổng
                            đài 1900 1000 để được hỗ trợ.
                        </p>
                        {/* <p>Quý khách vui lòng thực hiện lại hoặc liên hệ hotline KSS để được hỗ trợ đăng ký mở tài khoản</p> */}
                    </div>
                    <button
                        type="button"
                        className="btn btn-manhtq btn-orange"
                        onClick={onConfirmClearSession}
                    >
                        Thực hiện lại
                    </button>
                </div>
            </Modal>
        );
    }
}

// class ModalRefresh extends Component {

//     render() {
//         let { isShowModalRefresh, toggleModalRefresh, onConfirmModalRefresh } = this.props;
//         return (
//             <Modal isOpen={isShowModalRefresh} className="modal-open-acc modal-clear-session" centered>
//                 <div className="thong-bao-popup popup-text-black">
//                     <h3 className="o-h3 text-gradient">THÔNG BÁO</h3>
//                     <div className="o-wysiwyg row-upper">
//                         <p>Đã có lỗi khi thực hiện kết nối dữ liệu hệ thống. </p>

//                     </div>
//                     <div className="o-wysiwyg row-lower">
//                         <p>Quý khách vui lòng thực hiện lại hoặc liên hệ tổng đài 1900 1000 để được hỗ trợ.</p>
//                         {/* <p>Quý khách vui lòng thực hiện lại hoặc liên hệ hotline KSS để được hỗ trợ đăng ký mở tài khoản</p> */}
//                     </div>
//                     <button type="button" className="btn btn-manhtq btn-orange"
//                         onClick={onConfirmModalRefresh}
//                     >Xác nhận</button>
//                 </div>
//             </Modal >
//         );
//     }
// }

const mapStateToProps = (state) => {
    return {
        language: state.app.language,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeLanguage: (lang) => dispatch(actions.changeLanguage(lang)),
        navigate: (path) => dispatch(push(path)),
    };
};

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        CreateAccount
        //  ModalOpenClearSession
    )
);

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { path } from '../../src/utils/constant';

class Home extends Component {
    render() {
        const linkToRedirect = path.ACCOUNT_REGISTER;
        return (
            <Redirect to={linkToRedirect} />
        );
    }
}
const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import "./CreateAccountStep4.scss";
import {
    STEP4_RISK,
    STEP4_TARGET,
    STEP4_EXPERIENCE,
    STEP4_PROFESSIONAL,
} from "../../utils/constant";
import _ from "lodash";
import userService from "../../services/userService";
import Select from "react-select";
import TextareaAutosize from "react-textarea-autosize";

class CreateAccountStep4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountHolder: "", //tên chủ tài khoản
            numberBank: "", //số tài khoản
            nameBank: "", // ngân hàng
            selectValueBank: "",
            branchBank: "", //chi nhánh
            permanentAddress: "", //địa chỉ thường trú
            contactAddress: "", //địa chỉ liên lạc

            risk: "", //mức độ chấp nhận rủi ro

            // risk: {
            //     '001': true,
            //     '002': false,
            //     '003': false,
            // },
            // target: '', //mục tiêu đầu tư
            target: {
                "001": true,
                "002": false,
                "003": false,
            },
            // experience: '', //kinh nghiệm đầu tư chứng khoán
            experience: {
                "001": true,
                "002": false,
                "003": false,
                "004": false,
            },
            professional: "", //Tư cách nhà đầu tư chuyên nghiệp

            isValidNumberBank: true,
            isValidNameBank: true,
            isValidAddress: true,
            isValidBranchBank: true,
            isValidTarget: true,

            errNumberBank: "",
            errNameBank: "",
            errAddress: "",
            errBranchBank: "",
            errTarget: "",
            errRisk: "",
            errExperience: "",
            errProfessional: "",
        };
    }

    componentDidMount() {
        this.soTaiKhoan.focus();
        let { allData, executeScroll } = this.props;
        executeScroll();

        if (allData.dataStep4 && !_.isEmpty(allData.dataStep4)) {
            //lấy giá trị state theo giá trị lưu ở allData
            // từ step 5 -> step 4
            let {
                accountHolder,
                numberBank,
                nameBank,
                branchBank,
                permanentAddress,
                contactAddress,
                risk,
                target,
                experience,
                professional,
            } = allData.dataStep4;
            this.setState({
                accountHolder,
                numberBank,
                nameBank,
                branchBank,
                permanentAddress,
                contactAddress,
                risk,
                target,
                experience,
                professional,
            });
        } else {
            //lấy giá trị state theo ekyc, từ step 3 -> step 4
            this.setState({
                accountHolder:
                    allData.dataStep3_1 && allData.dataStep3_1.name
                        ? allData.dataStep3_1.name
                        : "",
                risk: STEP4_RISK[0].value,
                // target: STEP4_TARGET[0].value,
                // experience: STEP4_EXPERIENCE[0].value,
                professional: STEP4_PROFESSIONAL[0].value,
                contactAddress: allData.dataStep3_1.address,
            });
        }
    }

    onChangeAttribute = (att, event) => {
        if (event && event.target) {
            this.state[att] = event.target.value;
            this.setState((state) => ({
                ...state,
            }));
        }
    };

    onChangeAttributeCheckbox = (att, event) => {
        if (event && event.target && event.target.value) {
            // this.state[att][event.target.value] = !this.state[att][event.target.value];

            if (att != "experience") {
                this.state[att][event.target.value] =
                    !this.state[att][event.target.value];
            } else {
                if (event.target.value + "" == "001") {
                    this.state[att][event.target.value] = true;
                    this.state[att]["002"] = false;
                    this.state[att]["003"] = false;
                    this.state[att]["004"] = false;
                } else {
                    this.state[att]["001"] = false;
                    this.state[att][event.target.value] =
                        !this.state[att][event.target.value];
                    if (
                        !this.state[att]["002"] &&
                        !this.state[att]["003"] &&
                        !this.state[att]["004"]
                    ) {
                        this.state[att]["001"] = true;
                    }
                }
            }

            this.setState((state) => ({
                ...state,
            }));
        }
    };

    isValidInputs = (
        nameBank,
        numberBank,
        contactAddress,
        branchBank,
        target,
        risk,
        experience,
        professional
    ) => {
        this.setState({
            isValidNumberBank: true,
            errNumberBank: "",
            isValidNameBank: true,
            errNameBank: "",
            isValidAddress: true,
            errAddress: "",
            isValidBranchBank: true,
            errBranchBank: "",

            isValidTarget: true,
            errTarget: "",
        });
        if (!numberBank) {
            this.setState({
                isValidNumberBank: false,
                errNumberBank: "Số tài khoản ngân hàng không được để trống",
            });
            this.soTaiKhoan.focus();
            return false;
        }
        if (!nameBank) {
            this.setState({
                isValidNameBank: false,
                errNameBank: "Tên ngân hàng không được để trống",
            });

            return false;
        }

        if (!branchBank) {
            this.setState({
                isValidBranchBank: false,
                errBranchBank: "Tên chi nhánh ngân hàng không được để trống",
            });
            this.chiNhanh.focus();
            return false;
        }

        if (!contactAddress || (contactAddress && contactAddress.lengh < 15)) {
            this.setState({
                isValidAddress: false,
                errAddress: "Địa chỉ liên lạc phải có tối thiểu 15 kí tự",
            });
            return false;
        }
        //
        let isCheckTarget = false;
        for (let _tar in target) {
            if (target[_tar] == true) {
                isCheckTarget = true;
            }
        }
        if (isCheckTarget == false) {
            this.setState({
                isValidTarget: false,
                errTarget: '"Mục tiêu đầu tư" chưa được tích chọn!',
            });

            return false;
        }
        //
        let isCheckNotRisk = risk != "001" && risk != "002" && risk != "003";
        if (isCheckNotRisk) {
            this.setState({
                isValidRisk: false,
                errRisk: '"Mức độ chấp nhân rủi ro" chưa được tích chọn!',
            });
            return false;
        }
        //
        let isCheckExperience = false;
        for (let _exp in experience) {
            if (experience[_exp] == true) {
                isCheckExperience = true;
            }
        }
        if (isCheckExperience == false) {
            this.setState({
                isValidExperience: false,
                errExperience:
                    '"Kinh nghiệm đầu tư chứng khoán" chưa được tích chọn!',
            });
            return false;
        }
        //
        let isCheckNotProfessional = professional != "N" && professional != "Y";
        if (isCheckNotProfessional) {
            this.setState({
                isValidProfessional: false,
                errProfessional:
                    '"Tư cách nhà đầu tư chuyên nghiệp" chưa được tích chọn!',
            });
            return false;
        }
        // risk, target, experience, professional
        return true;
    };

    nextToStep5 = async () => {
        let { setStep, setParentStateFromChild, allData } = this.props;
        let {
            accountHolder,
            numberBank,
            nameBank,
            branchBank,
            permanentAddress,
            contactAddress,
            risk,
            target,
            experience,
            professional,
        } = this.state;

        let isValidInputs = this.isValidInputs(
            nameBank,
            numberBank,
            contactAddress,
            branchBank,
            target,
            risk,
            experience,
            professional
        );
        if (!isValidInputs) return;

        await setParentStateFromChild("4", {
            accountHolder,
            numberBank,
            nameBank,
            branchBank,
            permanentAddress,
            contactAddress,
            target,
            risk,
            experience,
            professional,
        });

        await setStep(5);
    };

    onChangeBankInfor = (value) => {
        this.setState({
            nameBank: value,
        });
    };

    render() {
        let {
            accountHolder,
            numberBank,
            nameBank,
            branchBank,
            permanentAddress,
            contactAddress,
            risk,
            target,
            experience,
            professional,
            isValidNumberBank,
            isValidNameBank,
            errNumberBank,
            errNameBank,
            isValidAddress,
            errAddress,
            isValidBranchBank,
            errBranchBank,
            isValidTarget,
            errTarget,
            errRisk,
            errExperience,
            errProfessional,
        } = this.state;

        let { allData } = this.props;
        let bankInfor = allData.dataStep1_1.bankInfor
            ? allData.dataStep1_1.bankInfor
            : [];

        return (
            <div className="register-step4">
                <div className="row_container">
                    <div className="box-1">
                        <div className="tieu-de-box-1">
                            Điền thông tin tài khoản ngân hàng để chuyển tiền
                        </div>
                        <div className="wrapper-content pdt-10">
                            {/* <div className="wrapper-row wrapper-row-1"> */}
                            <div className="wrapper-row ">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">Chủ tài khoản</p>
                                    </div>
                                    <div className="col2">
                                        <p className="o-label-1">
                                            {accountHolder}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper-row ">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">Số tài khoản</p>
                                    </div>
                                    <div className="col2">
                                        <input
                                            type="text"
                                            name="so-tai-khoan"
                                            value={numberBank}
                                            className={
                                                isValidNumberBank
                                                    ? "input-1"
                                                    : "input-1 error"
                                            }
                                            onChange={(event) =>
                                                this.onChangeAttribute(
                                                    "numberBank",
                                                    event
                                                )
                                            }
                                            ref={(input) => {
                                                this.soTaiKhoan = input;
                                            }}
                                        />

                                        {errNumberBank && (
                                            <span className="text-err">
                                                {errNumberBank}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">Ngân hàng</p>
                                    </div>
                                    <div className="col2">
                                        {/* <input type="text" value={nameBank}
                                            className={isValidNameBank ? 'input-1' : 'input-1 error'}
                                            onChange={(event) => this.onChangeAttribute('nameBank', event)}
                                        /> */}

                                        <SelectDropdown
                                            options={bankInfor}
                                            nameBank={nameBank}
                                            onChangeBankInfor={
                                                this.onChangeBankInfor
                                            }
                                            allData={allData}
                                        />
                                        {errNameBank && (
                                            <span className="text-err">
                                                {errNameBank}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">
                                            Chi nhánh, Tỉnh/TP
                                        </p>
                                    </div>
                                    <div className="col2">
                                        <input
                                            type="text"
                                            value={branchBank}
                                            className={
                                                isValidBranchBank
                                                    ? "input-1"
                                                    : "input-1 error"
                                            }
                                            onChange={(event) =>
                                                this.onChangeAttribute(
                                                    "branchBank",
                                                    event
                                                )
                                            }
                                            ref={(inputEl) =>
                                                (this.chiNhanh = inputEl)
                                            }
                                        />
                                        {errBranchBank && (
                                            <span className="text-err">
                                                {errBranchBank}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">Địa chỉ thường trú</p>
                                    </div>
                                    <div className="col2">
                                        <textarea className="input-1 h-100" value={permanentAddress} rows="2"
                                            onChange={(event) => this.onChangeAttribute('permanentAddress', event)}
                                        ></textarea>
                                    </div>
                                </div>
                            </div> */}

                            {/* lấy theo EKYC */}
                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">
                                            Địa chỉ liên lạc
                                        </p>
                                    </div>
                                    <div className="col2">
                                        <TextareaAutosize
                                            className={
                                                isValidAddress
                                                    ? "input-1 h-100"
                                                    : "input-1 h-100 error"
                                            }
                                            onChange={(event) =>
                                                this.onChangeAttribute(
                                                    "contactAddress",
                                                    event
                                                )
                                            }
                                            value={contactAddress}
                                            minRows={2}
                                            ref={(inputEl) =>
                                                (this.diaChi = inputEl)
                                            }
                                        />
                                        {errAddress && (
                                            <span className="text-err">
                                                {errAddress}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-2">
                        <div className="wrapper-content">
                            <div className="tieu-de-box-1">
                                Đăng ký một số thông tin khác
                            </div>
                            <div className="wrapper-row pdt-10">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">
                                            Mục tiêu đầu tư
                                        </p>
                                        {/* <p className={isValidTarget ? 'o-label' : 'o-label error'}> Mục tiêu đầu tư</p> */}
                                    </div>
                                    <div className="col2">
                                        <div className="row">
                                            {STEP4_TARGET.map((item, index) => {
                                                return (
                                                    <div
                                                        className="col-auto p-0 input-radio"
                                                        key={index}
                                                        onClick={(event) =>
                                                            this.onChangeAttributeCheckbox(
                                                                "target",
                                                                event
                                                            )
                                                        }
                                                    >
                                                        <input
                                                            type="radio"
                                                            value={item.value}
                                                            checked={
                                                                target[
                                                                    item.value
                                                                ]
                                                            }
                                                            name={
                                                                "target" +
                                                                item.value
                                                            }
                                                            id={
                                                                item.value + "T"
                                                            }
                                                        />
                                                        <label
                                                            htmlFor={
                                                                item.value + "T"
                                                            }
                                                            className="o-input-1 o-input-2"
                                                        >
                                                            {item.name}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {errTarget && (
                                    <span className="text-err">
                                        {errTarget}
                                    </span>
                                )}
                            </div>

                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">
                                            Mức độ chấp nhân rủi ro
                                        </p>
                                    </div>
                                    <div className="col2">
                                        <div className="row">
                                            {STEP4_RISK.map((item, index) => {
                                                return (
                                                    <div
                                                        className="col-auto p-0 input-radio"
                                                        key={index}
                                                        onChange={(event) =>
                                                            this.onChangeAttribute(
                                                                "risk",
                                                                event
                                                            )
                                                        }
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="risk"
                                                            value={item.value}
                                                            checked={
                                                                item.value ===
                                                                risk
                                                                    ? true
                                                                    : false
                                                            }
                                                            id={
                                                                item.value + "R"
                                                            }
                                                        />
                                                        <label
                                                            htmlFor={
                                                                item.value + "R"
                                                            }
                                                            className="o-input-1 o-input-2"
                                                        >
                                                            {item.name}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {errRisk && (
                                    <span className="text-err">{errRisk}</span>
                                )}
                            </div>

                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">
                                            Kinh nghiệm đầu tư chứng khoán
                                        </p>
                                    </div>
                                    <div className="col2">
                                        <div className="row">
                                            {STEP4_EXPERIENCE.map(
                                                (item, index) => {
                                                    return (
                                                        <div
                                                            className="col-auto p-0 input-radio"
                                                            key={index}
                                                            onClick={(event) =>
                                                                this.onChangeAttributeCheckbox(
                                                                    "experience",
                                                                    event
                                                                )
                                                            }
                                                        >
                                                            <input
                                                                type="radio"
                                                                id={
                                                                    item.value +
                                                                    "E"
                                                                }
                                                                name={
                                                                    "experience" +
                                                                    item.value
                                                                }
                                                                value={
                                                                    item.value
                                                                }
                                                                checked={
                                                                    experience[
                                                                        item
                                                                            .value
                                                                    ]
                                                                }
                                                            />
                                                            <label
                                                                className="o-input-1 o-input-2"
                                                                htmlFor={
                                                                    item.value +
                                                                    "E"
                                                                }
                                                            >
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {errExperience && (
                                    <span className="text-err">
                                        {errExperience}
                                    </span>
                                )}
                            </div>
                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">
                                            Tư cách nhà đầu tư chuyên nghiệp
                                        </p>
                                    </div>
                                    <div className="col2">
                                        <div className="row">
                                            {STEP4_PROFESSIONAL.map(
                                                (item, index) => {
                                                    return (
                                                        <div
                                                            className="col-auto p-0 input-radio"
                                                            key={index}
                                                            onChange={(event) =>
                                                                this.onChangeAttribute(
                                                                    "professional",
                                                                    event
                                                                )
                                                            }
                                                        >
                                                            <input
                                                                type="radio"
                                                                value={
                                                                    item.value
                                                                }
                                                                id={
                                                                    item.value +
                                                                    "P"
                                                                }
                                                                name="professional"
                                                                checked={
                                                                    professional ===
                                                                    item.value
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                            <label
                                                                htmlFor={
                                                                    item.value +
                                                                    "P"
                                                                }
                                                                className="o-input-1 o-input-2"
                                                            >
                                                                {item.name}
                                                            </label>
                                                        </div>
                                                    );
                                                }
                                            )}

                                            <div className="col-auto p-0 dpn"></div>
                                        </div>
                                    </div>
                                </div>
                                {errProfessional && (
                                    <span className="text-err">
                                        {errProfessional}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="button-xac-nhan">
                        <button
                            type="button"
                            className="xac-nhan"
                            onClick={() => this.nextToStep5()}
                        >
                            Tiếp tục
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

class SelectDropdown extends Component {
    render() {
        let { options, nameBank, onChangeBankInfor, allData } = this.props;

        const customStyles = {
            menu: (base) => ({
                ...base,
                marginTop: 0,
            }),
            control: (base, state) => ({
                ...base,
                background: "#E5E5E5",
                padding: 0,
            }),
        };

        return (
            <Select
                value={nameBank}
                onChange={onChangeBankInfor}
                options={options}
                placeholder="Chọn ngân hàng..."
                styles={customStyles}
                getOptionLabel={(option) => option.FULL_NAME}
                getOptionValue={(option) => option.SHORT_NAME}
            />
        );
    }
}
export default CreateAccountStep4;

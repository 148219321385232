import { emitter } from '../clients/emitter';
import { emitEvents } from './constant';
import _ from "lodash";
import $ from 'jquery';
import { STEP4_RISK, STEP4_TARGET, STEP4_EXPERIENCE, STEP4_PROFESSIONAL } from "./constant";
import moment from 'moment';
class CommonUtils {
    static showLoaderScreen(isShow) {
        if (isShow == undefined) {
            isShow = true
        }
        // alert('showLoaderScreen')
        isShow && $('#loaderDiv').removeClass('display_none')
        var body = document.body,
            html = document.documentElement;

        var h = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight);
        $('#loaderDiv').css('height', h)
        h = parseInt(h / 2) + 25
        $('#loaderDiv ._loading_overlay_overlay').css('top', h)
        // Tránh scroll
        isShow && $('.content-container').css('position', 'fixed')
    }
    static hideLoaderScreen() {
        $('#loaderDiv').addClass('display_none')
        // cho phép scroll
        $('.content-container').css('position', 'absolute')
    }
    static isNumber1(number) {
        if (number === 1) return true;
        return false;
    }

    static onOpenFullScreenWidget(widgetID) {
        if (document.getElementById(widgetID)) {
            document.getElementById(widgetID).classList.add("fullscreen");
        }

        let monitorItemArr = document.getElementsByClassName('monitor-item');
        if (monitorItemArr && monitorItemArr.length > 0) {
            for (let i = 0; i < monitorItemArr.length; i++) {
                monitorItemArr[i].classList.remove("col-md-12");
            }
        }

        let monitorRowArr = document.getElementsByClassName('monitor-row');
        if (monitorRowArr && monitorRowArr.length > 0) {
            for (let i = 0; i < monitorRowArr.length; i++) {
                monitorRowArr[i].classList.remove("col-md-4");
            }
        }
    }

    static onCloseFullScreenWidget(widgetID) {
        if (document.getElementById(widgetID)) {
            document.getElementById(widgetID).classList.remove("fullscreen");
        }

        let monitorItemArr = document.getElementsByClassName('monitor-item');
        if (monitorItemArr && monitorItemArr.length > 0) {
            for (let i = 0; i < monitorItemArr.length; i++) {
                monitorItemArr[i].classList.add("col-md-12");
            }
        }

        let monitorRowArr = document.getElementsByClassName('monitor-row');
        if (monitorRowArr && monitorRowArr.length > 0) {
            for (let i = 0; i < monitorRowArr.length; i++) {
                monitorRowArr[i].classList.add("col-md-4");
            }
        }

        emitter.emit(emitEvents.ON_CLOSE_FULLSCREEN_WIDGET);
    }

    static getDefaultLink(currentMenu) {
        let defautLink;
        if (currentMenu && currentMenu.length > 0) {
            if (currentMenu[0].link) {
                defautLink = currentMenu[0].link;
            } else {
                //menus
                if (currentMenu[0].menus && currentMenu[0].menus.length > 0) {
                    if (currentMenu[0].menus[0].link) {
                        defautLink = currentMenu[0].menus[0].link;
                    } else {
                        //subMenus
                        if (currentMenu[0].menus[0].subMenus && currentMenu[0].menus[0].subMenus.length > 0) {
                            if (currentMenu[0].menus[0].subMenus[0].link) {
                                defautLink = currentMenu[0].menus[0].subMenus[0].link;
                            }
                        }
                    }
                }
            }
        }
        return defautLink;
    }

    static getListPrid(listprid, prid) {
        if (listprid) {
            let currentListPrid = listprid.split('$');
            currentListPrid = _.filter(currentListPrid, item => item && item !== prid);
            if (currentListPrid && currentListPrid.length > 0) {
                currentListPrid = '$' + currentListPrid.join('$') + '$';
            } else {
                currentListPrid = '';
            }
            return currentListPrid;
        }
        return '';
    }


    static convertBodyDataGetContractPdf(allData) {
        try {
            let { risk, target, experience, professional } = allData.dataStep4;
            let { sessionid } = allData.dataStep1_1
            //STEP4_RISK, STEP4_TARGET, STEP4_EXPERIENCE, STEP4_PROFESSIONAL
            let targetObj = {
                "muctieu_nganhan": target[STEP4_TARGET[0].value] == true ? 'yes' : 'no',
                "muctieu_trunghan": target[STEP4_TARGET[1].value] == true ? 'yes' : 'no',
                "muctieu_daihan": target[STEP4_TARGET[2].value] == true ? 'yes' : 'no',
            }

            let riskObj = {
                "chapnhanrr_thap": STEP4_RISK[0].value === risk ? 'yes' : 'no',
                "chapnhanrr_tb": STEP4_RISK[1].value === risk ? 'yes' : 'no',
                "chapnhanrr_cao": STEP4_RISK[2].value === risk ? 'yes' : 'no',
            }

            let experienceObj = {
                "kinhnghiem_chuaco": experience[STEP4_EXPERIENCE[0].value] == true ? 'yes' : 'no',
                "kinhnghiem_cophieu": experience[STEP4_EXPERIENCE[1].value] == true ? 'yes' : 'no',
                "kinhnghiem_phaisinh": experience[STEP4_EXPERIENCE[2].value] == true ? 'yes' : 'no',
                "kinhnghiep_traiphieu": experience[STEP4_EXPERIENCE[3].value] == true ? 'yes' : 'no',
            }

            let finalObj = {
                "templateid": "MTKV5",
                "custodycd": "",
                "dateDD": sessionid.day,
                "dateMM": sessionid.month,
                "dateYY": sessionid.year,
                "canhan_ten": allData.dataStep3_1.name,
                "canhan_cmt": allData.dataStep3_1.idcode,
                "canhan_ngaycap": allData.dataStep3_1.iddate,
                "canhan_noicap": allData.dataStep3_1.idplace,
                "loaihinh_canhan": "yes", //hardcode, mặc định là cá nhân
                "loaihinh_tochuc": "no",
                "canhan_dclienhe": allData.dataStep4.contactAddress,
                "canhan_dcthuongtru": allData.dataStep3_1.address,
                "canhan_didong": allData.dataStep1.phoneNumber,
                "canhan_email": allData.dataStep1.email,
                "tkthuhuong_sotk": allData.dataStep4.numberBank,
                "tkthuhuong_tentk": allData.dataStep4.accountHolder,
                "tkthuhuong_tennganhang": allData.dataStep4.nameBank.FULL_NAME ? allData.dataStep4.nameBank.FULL_NAME : '',
                "tkthuhuong_chinhanhnh": allData.dataStep4.branchBank,
                "thoidiem_ky": sessionid.txdate + ' ' + sessionid.txtime,
            }

            return { ...finalObj, ...targetObj, ...riskObj, ...experienceObj };
        } catch (e) {
            console.log('convertBodyDataGetContractPdf: ', e)
            return '';
        }
    }
}

export default CommonUtils;
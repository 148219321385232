import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import "./CreateAccountStep2.scss";
import config from "../../config";
import { TYPE_IMG } from "../../utils/constant";
import { CommonUtils } from "../../../src/utils";
import ekycServer from "../../services/ekycServer";
import { timer } from "../../clients/timer";
import { split } from "lodash";

const Url_Upload_File = config.app.DIR_UPLOAD_FILE;
const IS_WIN_SERVER = config.app.IS_WIN_SERVER;
const PATH_FILE = IS_WIN_SERVER == true ? "\\" : "/";
const MAX_SIZE_FILE_UPLOAD = config.app.MAX_SIZE_FILE_UPLOAD;
const listIDTYPE = ["CCCD", "OLD ID", "NEW ID", "CHIP ID"];

class CreateAccountStep2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagePreviewUrl: {
                FRONT: "",
                BACK: "",
            },
            imgfiles: {
                FRONT: undefined,
                BACK: undefined,
            },
            imgtypefiles: {
                FRONT: undefined,
                BACK: undefined,
            },
            msg_error: {
                FRONT: undefined,
                BACK: undefined,
            },
        };

        this.initState = this.state;
    }

    componentDidMount() {
        let { executeScroll } = this.props;
        executeScroll();
    }

    componentWillUnmount() {
        this.setState(this.initState);
    }

    backToStep1 = () => {
        let { setStep } = this.props;
        setStep(1);
    };

    nextToStep3 = async () => {
        let { imgfiles, imgtypefiles } = this.state;
        let { setParentStateFromChild, setStep } = this.props;
        // if (!imgfiles[TYPE_IMG.FRONT]) {
        //     alert('Vui lòng upload CMND/CCCD mặt trước!');
        //     return;
        // }
        // if (!imgfiles[TYPE_IMG.BACK]) {
        //     alert('Vui lòng upload CMND/CCCD mặt sau!');
        //     return;
        // }
        await setParentStateFromChild(2, { imgfiles, imgtypefiles });
        let check = await this.submitCheckInfoByEkyc();
        if (check == true) {
            setStep(3);
        }
    };

    onChangeFile = (e, typeImg) => {
        let files = e.target.files;
        let { imgfiles, imgtypefiles, imagePreviewUrl } = this.state;

        if (files && files[0] && files[0].size) {
            let currentFile = files[0];
            let urlImg = document.getElementById(
                "upload-image-files-" + typeImg
            ).value;
            urlImg = urlImg.split(".");
            imgtypefiles[typeImg] = urlImg[urlImg.length - 1];
            console.log("onChangeFile.:", urlImg, imgtypefiles[typeImg]);
            if (currentFile.size > MAX_SIZE_FILE_UPLOAD) {
                alert("Quý khách vui lòng upload ảnh dung lượng nhỏ hơn 5MB");
            } else {
                imgfiles[typeImg] = currentFile;
                imagePreviewUrl[typeImg] = URL.createObjectURL(currentFile);
                this.setState({
                    imgfiles,
                    imgtypefiles,
                    imagePreviewUrl,
                });
            }
        }
    };

    getInfoImgByEkyc = async (typeImg, data) => {
        // Haki.: Get Info Img Back
        let objFile = {
            requestid: data.requestid,
            foldername: data.folder_name,
            filename: data.file_name[typeImg],
            file: data.file[typeImg],
        };
        return await ekycServer.getInfoImgByEkyc(objFile).then(async (res) => {
            console.log("getInfoImgByEkyc=====================", res);
            return res;
        });
    };
    setStateMsg_error = async (msg_error) => {
        await this.setState({ msg_error });
    };
    checkValidate = async (imgfiles, imgtypefiles) => {
        this.setStateMsg_error({
            msg_error: {
                FRONT: undefined,
                BACK: undefined,
            },
        });
        let { msg_error } = this.state;
        if (!imgfiles[TYPE_IMG.FRONT]) {
            msg_error.FRONT = "Vui lòng tải ảnh CMND/CCCD mặt trước!";
            if (!imgfiles[TYPE_IMG.BACK]) {
                msg_error.BACK = "Vui lòng tải ảnh CMND/CCCD mặt sau!";
            }
            this.setStateMsg_error(msg_error);
            return false;
        } else {
            if (!imgfiles[TYPE_IMG.BACK]) {
                msg_error.BACK = "Vui lòng tải ảnh CMND/CCCD mặt sau!";
                this.setStateMsg_error(msg_error);
                return false;
            }
        }
        return true;
    };
    checkValidateInfoEkyc = async (dataFront, dataBack) => {
        let { msg_error } = this.state;
        if (dataFront && dataBack) {
            //check CCCD, NEW ID, CHIP ID , OLD ID
            // Check loại CMND/CCCD
            if (dataFront.id_type != "0") {
                msg_error.FRONT =
                    "Ảnh không đúng loại. Vui lòng tải ảnh CMND/CCCD mặt trước!";
                if (dataBack.id_type != "1") {
                    msg_error.BACK =
                        "Ảnh không đúng loại. Vui lòng tải ảnh CMND/CCCD mặt sau!";
                    this.setStateMsg_error(msg_error);
                    return false;
                }
                this.setStateMsg_error(msg_error);
                return false;
            } else {
                if (dataBack.id_type != "1") {
                    msg_error.BACK =
                        "Ảnh không đúng loại. Vui lòng tải ảnh CMND/CCCD mặt sau!";
                    this.setStateMsg_error(msg_error);
                    return false;
                }
                if (dataFront.document != dataBack.document) {
                    // Haki.: Tạm chấp nhận document == 'CCCD' === 'NEW ID' là cùng loại
                    if (
                        dataFront.document == "CCCD" &&
                        dataBack.document != "NEW ID"
                    ) {
                        // CCCD
                        msg_error.BACK =
                            "Ảnh CMND/CCCD mặt trước và sau không khớp loại!";
                        this.setStateMsg_error(msg_error);
                        return false;
                    }
                    if (
                        dataFront.document == "OLD ID" &&
                        dataBack.document != "OLD ID"
                    ) {
                        // CMND 9 số
                        msg_error.BACK =
                            "Ảnh CMND/CCCD mặt trước và sau không khớp loại!";
                        this.setStateMsg_error(msg_error);
                        return false;
                    }
                    if (
                        dataFront.document == "NEW ID" &&
                        dataBack.document != "NEW ID"
                    ) {
                        // CMND 12
                        msg_error.BACK =
                            "Ảnh CMND/CCCD mặt trước và sau không khớp loại!";
                        this.setStateMsg_error(msg_error);
                        return false;
                    }
                    if (
                        dataFront.document == "CHIP ID" &&
                        dataBack.document != "CHIP ID"
                    ) {
                        // CHIP ID
                        msg_error.BACK =
                            "Ảnh CMND/CCCD mặt trước và sau không khớp loại!";
                        this.setStateMsg_error(msg_error);
                        return false;
                    }
                    if (!listIDTYPE.includes(dataFront.document)) {
                        // error type
                        msg_error.BACK =
                            "Vui lòng tải ảnh đúng loại CMND/CCCD!";
                        this.setStateMsg_error(msg_error);
                        return false;
                    }

                    // Haki.: Check chính xác thì dataFront.document == dataBack.document mới cùng loại
                    // msg_error.BACK = 'Ảnh CMND/CCCD mặt trước và sau không khớp loại!'
                    // this.setStateMsg_error(msg_error)
                    // return false
                }
            }
            // Check thời hạn expiry CCCD
            //check expire CHIP ID
            if (
                (dataFront.document == "CCCD" &&
                    dataBack.document == "NEW ID") ||
                (dataFront.document == "NEW ID" &&
                    dataBack.document == "NEW ID") ||
                (dataFront.document == "CHIP ID" &&
                    dataBack.document == "CHIP ID")
            ) {
                if (
                    dataBack.expiry &&
                    (dataBack.issue_date === "N/A" ||
                        dataBack.issue_date.length < 10)
                ) {
                    msg_error.BACK =
                        "Ảnh CMND/CCCD không xác định được ngày cấp. Vui lòng tải ảnh CMND/CCCD!";
                    this.setStateMsg_error(msg_error);
                    return false;
                } else if (dataFront.expiry && dataFront.expiry != "N/A") {
                    // CCCD mặt trước và có lấy được expiry
                    // expiry Định dạng dd-mm-yyyy
                    let { allData } = this.props;
                    let dataStep1_1 = allData.dataStep1_1;
                    let { txdate } = dataStep1_1.sessionid;
                    let age = 0;
                    if (dataFront.birthday) {
                        let today = new Date();
                        let birthDate = new Date(dataFront.birthday);
                        age = today.getFullYear() - birthDate.getFullYear();
                        let m = today.getMonth() - birthDate.getMonth();
                        if (
                            m < 0 ||
                            (m === 0 && today.getDate() < birthDate.getDate())
                        ) {
                            age--;
                        }
                    }

                    if (dataFront.expiry == "Không thời hạn" || age >= 60) {
                        dataFront.expiry = "1-1-2121";
                    }

                    let checkExpiry = await timer.checkExpiry(
                        dataFront.expiry,
                        txdate
                    );
                    if (!checkExpiry) {
                        msg_error.FRONT =
                            "Ảnh CMND/CCCD hết hạn. Vui lòng tải ảnh CMND/CCCD còn hiệu lực!";
                        // msg_error.BACK = 'Ảnh CMND/CCCD hết hạn. Vui lòng tải ảnh CMND/CCCD còn hiệu lực!'
                        this.setStateMsg_error(msg_error);
                        return false;
                    }
                } else {
                    msg_error.BACK =
                        "Ảnh CMND/CCCD không xác định được thời hạn. Vui lòng tải ảnh CMND/CCCD!";
                    this.setStateMsg_error(msg_error);
                    return false;
                }
            }
            if (
                dataFront.document == "OLD ID" &&
                dataBack.document == "OLD ID"
            ) {
                if (dataBack.issue_date && dataBack.issue_date != "N/A") {
                    // Check thời hạn expiry CNND 9 sổ
                    // CCCD mặt trước
                    // expiry Định dạng dd-mm-yyyy
                    let { allData } = this.props;
                    let dataStep1_1 = allData.dataStep1_1;
                    let { txdate } = dataStep1_1.sessionid;
                    let newDate = await timer.getExpiry(txdate);
                    let checkExpiry2 = await timer.checkExpiry(
                        dataBack.issue_date,
                        newDate
                    );
                    if (!checkExpiry2) {
                        // msg_error.FRONT = 'Ảnh CMND/CCCD hết hạn. Vui lòng tải ảnh CMND/CCCD còn hiệu lực!'
                        msg_error.BACK =
                            "Ảnh CMND/CCCD hết hạn. Vui lòng tải ảnh CMND/CCCD còn hiệu lực!";
                        this.setStateMsg_error(msg_error);
                        return false;
                    }
                } else {
                    msg_error.BACK =
                        "Ảnh CMND/CCCD không xác định được thời hạn. Vui lòng tải ảnh CMND/CCCD!";
                    this.setStateMsg_error(msg_error);
                    return false;
                }
            }
        }
        return true;
    };

    submitCheckInfoByEkyc = async () => {
        try {
            let { allData, setParentStateFromChild, setStep } = this.props;
            // let dataStep1 = allData.dataStep1
            // let phoneNumber = dataStep1.phoneNumber
            //
            let dataStep2 = allData.dataStep2;
            let { imgfiles, imgtypefiles } = dataStep2;

            let checkValidate = await this.checkValidate(
                imgfiles,
                imgtypefiles
            );
            if (checkValidate == true) {
                //
                CommonUtils.showLoaderScreen();
                let imgfilesFace = this.state.imgfiles[0];
                let imgtypefilesFace = this.state.imgtypefiles;
                console.log(
                    "submitCheckInfoByEkyc.:imgfilesFace=",
                    imgfilesFace,
                    "imgtypefilesFace=",
                    imgtypefilesFace
                );
                // console.log('submitCheckInfoByEkyc.:dataStep1=', dataStep1)
                // console.log('submitCheckInfoByEkyc.:dataStep2=', dataStep2, imgfiles, imgtypefiles)
                if (
                    imgfiles &&
                    imgfiles[TYPE_IMG.FRONT] &&
                    imgfiles[TYPE_IMG.BACK]
                ) {
                    // let unixtime = moment().unix();
                    // let name = dataStep1.phoneNumber;
                    // let requestid = name + '_' + unixtime

                    let { sessionid } = allData.dataStep1_1;
                    // let sessionid = {
                    //     phoneNumber: '0964664466',
                    //     unixtime: '123456789',
                    //     id: '0964664466_123456789'
                    // }
                    if (sessionid) {
                        let name = sessionid.phoneNumber;
                        let unixtime = sessionid.unixtime;
                        let requestid = sessionid.id; // =phoneNumber+'_'+unixtime
                        let folder_name =
                            timer.formatDateToNameFolder() +
                            PATH_FILE +
                            requestid; // folder Linux/Ubuntu
                        let file_name_front =
                            name +
                            "_" +
                            TYPE_IMG.FRONT +
                            "_" +
                            unixtime +
                            "." +
                            imgtypefiles[TYPE_IMG.FRONT];
                        let file_name_back =
                            name +
                            "_" +
                            TYPE_IMG.BACK +
                            "_" +
                            unixtime +
                            "." +
                            imgtypefiles[TYPE_IMG.BACK];
                        // let file_name_face = name + '_' + TYPE_IMG.FACE + '_' + unixtime + '.' + imgtypefilesFace
                        let objFile = {
                            requestid: requestid,
                            folder_name: folder_name,
                            file_name: {
                                // 'FRONT': name + '_' + TYPE_IMG.FRONT + '_' + unixtime + '.' + imgtypefiles[TYPE_IMG.FRONT],
                                FRONT: file_name_front
                                    ? file_name_front
                                    : undefined,
                                BACK: file_name_back
                                    ? file_name_back
                                    : undefined,
                                // 'FACE': file_name_face ? file_name_face : undefined,
                            },
                            file: {
                                FRONT: imgfiles[TYPE_IMG.FRONT],
                                BACK: imgfiles[TYPE_IMG.BACK],
                                // 'FACE': imgfilesFace,
                            },
                        };
                        // Haki.: Lưu đường dẫn file xuống Back
                        let dirFile = {
                            FRONT:
                                Url_Upload_File +
                                folder_name +
                                PATH_FILE +
                                objFile.file_name[TYPE_IMG.FRONT],
                            BACK:
                                Url_Upload_File +
                                folder_name +
                                PATH_FILE +
                                objFile.file_name[TYPE_IMG.BACK],
                            // 'FACE': Url_Upload_File + folder_name + PATH_FILE + objFile.file_name[TYPE_IMG.FACE],
                        };

                        let dataFront = await this.getInfoImgByEkyc(
                            TYPE_IMG.FRONT,
                            objFile
                        );
                        console.log(
                            "submitCheckInfoByEkyc.:objFront=",
                            dataFront
                        );
                        //
                        let dataBack = await this.getInfoImgByEkyc(
                            TYPE_IMG.BACK,
                            objFile
                        );
                        console.log(
                            "submitCheckInfoByEkyc.:dataBack=",
                            dataBack
                        );
                        //
                        let check = await this.checkValidateInfoEkyc(
                            dataFront,
                            dataBack
                        );
                        if (check) {
                            if (dataFront) {
                                let id_type = "";
                                if (
                                    dataFront.document &&
                                    (dataFront.document == "CCCD" ||
                                        dataFront.document == "CHIP ID")
                                ) {
                                    id_type = "007";
                                }
                                if (
                                    dataFront.document &&
                                    (dataFront.document == "OLD ID" ||
                                        dataFront.document == "NEW ID")
                                ) {
                                    id_type = "001";
                                }
                                let objFront = {
                                    name: dataFront.name ? dataFront.name : "", // Ho ten
                                    birthday:
                                        dataFront.birthday &&
                                        dataFront.birthday != "N/A"
                                            ? timer.formatDateEkyc(
                                                  dataFront.birthday
                                              )
                                            : "N/A", // Ngay sinh
                                    idcode: dataFront.id ? dataFront.id : "", // So CMND
                                    address: dataFront.address
                                        ? dataFront.address
                                        : "", // Dia chi thuong tru
                                    // address: dataFront.address ? dataFront.address : '',
                                    dirFile,
                                    sex: dataFront.sex ? dataFront.sex : "", //giới tính
                                    id_type: id_type,
                                    idexpired:
                                        dataFront.expiry &&
                                        dataFront.expiry != "N/A"
                                            ? timer.formatDateEkyc(
                                                  dataFront.expiry
                                              )
                                            : "N/A", // TH CCCD, còn CMDN mặc định truyền ''
                                };
                                let fullName =
                                    allData.dataStep1 &&
                                    allData.dataStep1.fullName;
                                if (objFront.name != fullName) {
                                    //Haki.: Khác thông tin ở form "Đăng ký thông tin" => hiển thị popup "Bạn có muốn cập nhật theo thông tin mới không?"
                                }

                                console.log(
                                    "submitCheckInfoByEkyc.:objFront=",
                                    objFront
                                );
                                await setParentStateFromChild(3, objFront);
                            } else {
                                // Lỗi xác thực EKyc
                                // alert('Lỗi xác thực EKyc.:dataFront')
                                console.log("Lỗi xác thực ekyc");
                                CommonUtils.hideLoaderScreen();
                                return null;
                            }

                            console.log(
                                "submitCheckInfoByEkyc.:dataBack=",
                                dataBack
                            );
                            if (dataBack) {
                                let objBack = {
                                    iddate:
                                        dataBack.issue_date &&
                                        dataBack.issue_date != "N/A"
                                            ? timer.formatDateEkyc(
                                                  dataBack.issue_date
                                              )
                                            : "N/A", // Ngay cap
                                    idplace: dataBack.issue_by
                                        ? dataBack.issue_by
                                        : "", // Noi cap
                                };
                                await setParentStateFromChild(3, objBack);
                            } else {
                                // Lỗi xác thực EKyc
                                alert("Lỗi xác thực EKyc.:dataBack");
                                CommonUtils.hideLoaderScreen();
                                return null;
                            }
                            CommonUtils.hideLoaderScreen();
                        } else {
                            CommonUtils.hideLoaderScreen();
                            return null;
                        }
                        console.log(
                            "submitCheckInfoByEkyc.:dataFront=",
                            dataFront
                        );
                        console.log(
                            "submitCheckInfoByEkyc.:dataBack=",
                            dataBack
                        );
                        console.log("submitCheckInfoByEkyc.:dirFile=", dirFile);
                        return true;
                    } else {
                        alert("Lỗi không tìm thấy sessionid!");
                        CommonUtils.hideLoaderScreen();
                        return null;
                    }
                }
            }
        } catch (e) {
            console.log("submitCheckInfoByEkyc.errorr= ", e);
            alert("Lỗi mạng không xác định. Vui lòng thử lại");
            CommonUtils.hideLoaderScreen();
            return null;
        }
    };

    render() {
        let { imagePreviewUrl, msg_error } = this.state;
        return (
            <div className="register-step2">
                <div className="row_container">
                    <div className="title">
                        <span className="note"> Lưu ý: </span>Chỉ chấp nhận file
                        định dạng JPG, PNG và dung lượng dưới 5 MB
                    </div>
                    <div className="input-files">
                        <div className="input-child d-block upload-files d-flex flex-row justify-content-center">
                            <label className="btn-front d-block">
                                Tải ảnh CMND/CCCD mặt trước
                                <input
                                    id={"upload-image-files-" + TYPE_IMG.FRONT}
                                    className="d-none"
                                    type="file"
                                    onChange={(event) =>
                                        this.onChangeFile(event, TYPE_IMG.FRONT)
                                    }
                                    accept=".jpeg,.jpg,.png"
                                    multiple={false}
                                />
                            </label>
                        </div>

                        <div
                            className={
                                imagePreviewUrl[TYPE_IMG.FRONT]
                                    ? "preview-mobile"
                                    : "d-none"
                            }
                        >
                            {imagePreviewUrl[TYPE_IMG.FRONT] && (
                                <>
                                    <div
                                        className="img-pre"
                                        style={{
                                            backgroundImage: `url(${
                                                imagePreviewUrl[TYPE_IMG.FRONT]
                                            })`,
                                        }}
                                    ></div>
                                </>
                            )}
                        </div>

                        <div className="input-child d-block upload-files d-flex flex-row justify-content-center">
                            <label className="btn-back d-block">
                                Tải ảnh CMND/CCCD mặt sau
                                <input
                                    id={"upload-image-files-" + TYPE_IMG.BACK}
                                    className="d-none"
                                    type="file"
                                    onChange={(event) =>
                                        this.onChangeFile(event, TYPE_IMG.BACK)
                                    }
                                    accept=".jpeg,.jpg,.png"
                                    multiple={false}
                                />
                            </label>
                        </div>

                        <div
                            className={
                                imagePreviewUrl[TYPE_IMG.BACK]
                                    ? "preview-mobile"
                                    : "d-none"
                            }
                        >
                            {imagePreviewUrl[TYPE_IMG.BACK] && (
                                <>
                                    <div
                                        className="img-pre"
                                        style={{
                                            backgroundImage: `url(${
                                                imagePreviewUrl[TYPE_IMG.BACK]
                                            })`,
                                        }}
                                    ></div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="preview d-none d-sm-flex">
                        <div className="img-front w-50 mx-1">
                            <div
                                className="img-pre"
                                style={{
                                    backgroundImage: `url(${
                                        imagePreviewUrl[TYPE_IMG.FRONT]
                                    })`,
                                }}
                            ></div>
                        </div>
                        <div className="img-back w-50 mx-1">
                            <div
                                className="img-pre"
                                style={{
                                    backgroundImage: `url(${
                                        imagePreviewUrl[TYPE_IMG.BACK]
                                    })`,
                                }}
                            ></div>
                        </div>
                    </div>

                    <div className="err-message ">
                        <div className="d-block upload-files pt-1">
                            <span className="lbl_error">
                                {msg_error.FRONT && msg_error.FRONT}
                            </span>
                        </div>
                        <div className="d-block upload-files pt-1">
                            <span className="lbl_error">
                                {msg_error.BACK && msg_error.BACK}
                            </span>
                        </div>
                    </div>
                    <div className="all-buttons">
                        <button
                            className="btn-back"
                            onClick={() => this.backToStep1()}
                        >
                            Quay lại
                        </button>
                        <button
                            className="btn-continue"
                            onClick={() => this.nextToStep3()}
                        >
                            Tiếp tục
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateAccountStep2;

import moment from 'moment';
// import { _time } from '../clients/finfo.api.client';

const tzOffset = '+07:00';
const timeFormat = 'HH:mm:ss';

const startDayTime = moment('6:00:00', timeFormat);
const beforeTradingTime = moment('08:30:00', timeFormat);
const startIntermissionTime = moment('11:35:00', timeFormat);
const endIntermissionTime = moment('12:45:00', timeFormat);
const afterTradingTime = moment('15:15:00', timeFormat);
const afterDerivativeTradingTime = moment('15:01:00', timeFormat);
const endDayTime = moment('20:00:00', timeFormat);

const isWeekend = dayOfWeek => {
	return dayOfWeek === 0 || dayOfWeek === 6;
};

// export const syncTime = () => {
// 	let startTime = new Date().getTime();

// 	return _time()
// 		.then(response => {
// 			let requestTime = new Date().getTime() - startTime;
// 			let serverTime = response.data.d.currentTimeDb + requestTime / 2;
// 			sessionStorage.setItem('startTimeCounter', serverTime);
// 			return serverTime;
// 		})
// 		.catch(e => {
// 			let now = new Date().getTime();
// 			let requestTime = now - startTime;
// 			let serverTime = now + requestTime / 2;
// 			sessionStorage.setItem('startTimeCounter', serverTime);
// 			return serverTime;
// 		});
// };

export const getSyncedTime = () => {
	return sessionStorage.getItem('startTimeCounter') || new Date().getTime();
};

export const getLocalTime = () => {
	return new Promise((resolve, reject) => {
		resolve(new Date().getTime());
	});
};

export const init = cb => {
	// Haki.: Tạm Hide (Get time error)
	// syncTime().then(serverTime => {
	// 	cb(serverTime);
	// });
};

export const notInTradingHour = serverTime => {
	let time = moment(serverTime).utcOffset(tzOffset);

	let notInTradingHour = !time.isBetween(beforeTradingTime, afterTradingTime);

	return isWeekend(time.weekday()) || notInTradingHour;
};

// Haki: check time theo giờ của client
export const isInTradingHour = () => {
	let time = moment().utcOffset(tzOffset);
	return (
		time.isBetween(beforeTradingTime, afterTradingTime, 'minute') &&
		!isWeekend(time.weekday())
	);
};

export const isAfterDerivativeTradingHour = () => {
	let time = moment().utcOffset(tzOffset);
	return (
		time.isAfter(afterDerivativeTradingTime, 'minute') &&
		!isWeekend(time.weekday())
	);
};

export const isProperTimeToShowNPS = () => {
	let time = moment().utcOffset(tzOffset);
	let onWeekend = isWeekend(time.weekday());
	let onTestingPeriod = time.isBetween(
		moment('2018-12-24'),
		moment('2018-12-31'),
		'day',
		'[]'
	);
	let onPeriodToShowNPS =
		time.isBetween(startDayTime, beforeTradingTime, 'minute') ||
		time.isBetween(startIntermissionTime, endIntermissionTime, 'minute') ||
		time.isBetween(afterTradingTime, endDayTime, 'minute');

	return onTestingPeriod && (onWeekend || onPeriodToShowNPS);
};

export const isBefore9pm = () => {
	let time = moment().utcOffset(tzOffset);
	return time.isBefore(moment('21:00:00', timeFormat), 'minute');
};

export const getNearestTradingDate = () => {
	let weekday = moment().isoWeekday();
	let tradingDate =
		weekday > 5 ? moment().subtract(weekday - 5, 'days') : moment();
	return tradingDate;
};

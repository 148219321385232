//const KSS

export const STEP1_AREA = [
    { name: 'Hà Nội', value: 'HN' },
    { name: 'TP. Hồ Chí Minh', value: 'HC' }
];

export const STEP3_SEX = [
    { name: 'Nam', value: '001' },
    { name: 'Nữ', value: '002' },
]
export const STEP4_TARGET = [
    { name: 'Ngắn hạn', value: '001' },
    { name: 'Trung hạn', value: '002' },
    { name: 'Dài hạn', value: '003' }
];


export const STEP4_RISK = [
    { name: 'Thấp', value: '001' },
    { name: 'Trung bình', value: '002' },
    { name: 'Cao', value: '003' }
];

export const STEP4_EXPERIENCE = [
    { name: 'Chưa có', value: '001' },
    { name: 'Chứng khoán cơ sở', value: '002' },
    { name: 'Chứng khoán phái sinh', value: '003' },
    { name: 'Trái phiếu', value: '004' }
];
export const STEP4_PROFESSIONAL = [
    { name: 'Chưa xác định', value: 'N' },
    { name: 'Có', value: 'Y' },
];

export const OTPTYPE = 'OPENACCT';


export const TYPE_IMG = {
    FRONT: 'FRONT', // Mặt trước FRONT
    BACK: 'BACK', // Mặt sau BACK
    FACE: 'FACE', // Gương mặt FACE
}

export const ERROR_CODE = {
    VALID: 0,
    INVALID_ID_CODE: -200020,
    INVALID_TIMEOUT_OTP: -260253, //OTP hết hạn
    INVALID_PASSWORD: -260254,
    INVALID_MAX_OTP: -260255, //quá số lần tối đa nhập OTP
    INVALID_OTP: -260256, //OTP không đúng
    INVALID_SEND_OTP: -260257, // Không sinh đc OTP
    INVALID_EMAIL: -260258, // email đã tồn tại
    INVALID_PHONE: -260259, //số đt đã được sử dụng
    INVALID_FORMAT_EMAIL: -260264, // Định dạng email không đúng
    INVALID_REG_ONLINE_PASSWORD: -260266, // định dạng password ko đúng
    INVALID_EXPIRED_PASSWORD: -260268, // định dạng expired ko đúng
}

export const BANK_INFO = [
    { SHORT_NAME: 'OCEAN', FULL_NAME: 'Ngân hàng TMCP Đại Dương ' },
    { SHORT_NAME: 'PGB', FULL_NAME: 'Ngân hàng TMCP Xăng dầu Petrolimex ' },
    { SHORT_NAME: 'PVB', FULL_NAME: 'Ngân hàng Đại Chúng (PVcombank) ' },
    { SHORT_NAME: 'SB', FULL_NAME: 'Ngân hàng TMCP Phương Nam ' },
    { SHORT_NAME: 'SCB', FULL_NAME: 'Ngân hàng TMCP Sài Gòn' },
    { SHORT_NAME: 'SCSB', FULL_NAME: 'Shanghai Commercial & Savings' },
    { SHORT_NAME: 'SEA', FULL_NAME: 'Ngân hàng TMCP Đông Nam Á (SEABANK) ' },
    { SHORT_NAME: 'SGB', FULL_NAME: 'Ngân hàng TMCP Sài Gòn Công Thương(SAIGONBANK) ' },
    { SHORT_NAME: 'SHB', FULL_NAME: 'Ngân hàng TMCP Sài gòn - Hà Nội (SHB) ' },
    { SHORT_NAME: 'SHINHAN', FULL_NAME: 'Ngân hàng ShinhanVina' },
    { SHORT_NAME: 'SHINHAN_VN_LIMIT', FULL_NAME: 'Shinhan Vietnam Bank Limited' },
    { SHORT_NAME: 'SIAM_COMMERCIAL', FULL_NAME: 'NH The Siam Commercial Bank Public' },
    { SHORT_NAME: 'SINOPAC', FULL_NAME: 'Far East National Bank' },
    { SHORT_NAME: 'SMBC', FULL_NAME: 'Sumitomo Mitsui Bank' },
    { SHORT_NAME: 'STANDARD', FULL_NAME: 'Ngân hàng Standard Chartered Việt Nam ' },
    { SHORT_NAME: 'STB', FULL_NAME: 'Ngân hàng TMCP Sài Gòn Thương Tín (Sacombank) ' },
    { SHORT_NAME: 'TAIPEI_FUBON', FULL_NAME: 'Taipei Fubon Bank' },
    { SHORT_NAME: 'TCB', FULL_NAME: 'Ngân hàng TMCP Kỹ thương Việt Nam(Techcombank) ' },
    { SHORT_NAME: 'THE_SIAM', FULL_NAME: 'NH The Siam Commercial Bank Public' },
    { SHORT_NAME: 'TPB', FULL_NAME: 'Ngân hàng TMCP Tiên Phong ' },
    { SHORT_NAME: 'UOB', FULL_NAME: 'United Overseas Bank' },
    { SHORT_NAME: 'VAB', FULL_NAME: 'Ngân hàng TMCP Việt Á ' },
    { SHORT_NAME: 'VBSP', FULL_NAME: 'Ngân hàng Chính sách Xã hội Việt Nam ' },
    { SHORT_NAME: 'VCB', FULL_NAME: 'Ngân hàng TMCP Ngoại Thương Việt Nam(Vietcombank) ' },
    { SHORT_NAME: 'VDB', FULL_NAME: 'Ngân hàng Phát triển Việt Nam ' },
    { SHORT_NAME: 'VIB', FULL_NAME: 'Ngân hàng TMCP Quốc tế (VIB) ' },
    { SHORT_NAME: 'VID', FULL_NAME: 'Ngân hàng VID Public Bank ' },
    { SHORT_NAME: 'VIETCAPITALBANK', FULL_NAME: 'Ngân hàng Bản Việt Gia Định' },
    { SHORT_NAME: 'VIET_BANK', FULL_NAME: 'Ngân hàng TMCP Việt Nam Thương Tín(VietBank) ' },
    { SHORT_NAME: 'VNCB', FULL_NAME: 'Ngân hàng xây dựng Việt Nam' },
    { SHORT_NAME: 'VPB', FULL_NAME: 'Ngân hàng TMCP Việt Nam Thịnh Vượng ' },
    { SHORT_NAME: 'VRB', FULL_NAME: 'Ngân hàng Liên doanh Việt Nga ' },
    { SHORT_NAME: 'WOORI', FULL_NAME: 'Woori Bank' },
    { SHORT_NAME: 'ABB', FULL_NAME: 'Ngân hàng TMCP An Bình (ABB) ' },
    { SHORT_NAME: 'ACB', FULL_NAME: 'Ngân hàng TMCP Á Châu (ACB) ' },
    { SHORT_NAME: 'AGR', FULL_NAME: 'Ngân hàng Nông nghiệp và PTNT VN(Agribank) ' },
    { SHORT_NAME: 'ANZ', FULL_NAME: 'Ngân hàng ANZ Việt Nam (ANZ) ' },
    { SHORT_NAME: 'BANK_OF_INDIA', FULL_NAME: 'Bank Of India HCM Branch' },
    { SHORT_NAME: 'BIDC', FULL_NAME: 'NH Dau Tu va PT Campuchia' },
    { SHORT_NAME: 'BIDV', FULL_NAME: 'Ngân hàng Đầu tư và Phát triển VN(BIDV) ' },
    { SHORT_NAME: 'BKOK', FULL_NAME: 'Bangkok Bank' },
    { SHORT_NAME: 'BNP', FULL_NAME: 'PARIBAS' },
    { SHORT_NAME: 'BOC', FULL_NAME: 'Bank of China' },
    { SHORT_NAME: 'BOCOMM', FULL_NAME: 'Bank of Communication' },
    { SHORT_NAME: 'BPCE_IOM', FULL_NAME: 'BPCE International et Outre-Mer' },
    { SHORT_NAME: 'BTMU', FULL_NAME: 'Bank of Tokyo Mitsubishi' },
    { SHORT_NAME: 'BUSAN_BANK', FULL_NAME: 'Busan Bank Co Ltd' },
    { SHORT_NAME: 'BVB', FULL_NAME: 'Ngân hàng TMCP Bảo Việt ' },
    { SHORT_NAME: 'CATHAY', FULL_NAME: 'Cathay Bank' },
    { SHORT_NAME: 'CCB', FULL_NAME: 'China Construction Bank' },
    { SHORT_NAME: 'CHIFON', FULL_NAME: 'NH Chifon Dai Loan' },
    { SHORT_NAME: 'CIMB', FULL_NAME: 'NH TNHH MTV CIMB Viet Nam' },
    { SHORT_NAME: 'CITIBANK', FULL_NAME: 'Ngân hàng Citibank Việt Nam ' },
    { SHORT_NAME: 'CO-OP', FULL_NAME: 'Ngân hàng Hợp tác Việt Nam' },
    { SHORT_NAME: 'COMMONWEALTH', FULL_NAME: 'Commonwealth Bank' },
    { SHORT_NAME: 'CREDIT_AGRICOLE', FULL_NAME: 'Credit Agricole CIB' },
    { SHORT_NAME: 'CTBC', FULL_NAME: 'Chinatrust Commercial Bank' },
    { SHORT_NAME: 'CTG', FULL_NAME: 'Ngân hàng TMCP Công Thương VN (CTG-Viettinbank) ' },
    { SHORT_NAME: 'DAB', FULL_NAME: 'Ngân hàng TMCP Đông Á ' },
    { SHORT_NAME: 'DAI_A', FULL_NAME: 'Ngân hàng Đại Á ' },
    { SHORT_NAME: 'DBS', FULL_NAME: 'DBS Bank Ltd' },
    { SHORT_NAME: 'DEUTSCHE BANK', FULL_NAME: 'Deutsche Bank Viet Nam' },
    { SHORT_NAME: 'E.SUN', FULL_NAME: 'E.SUN Commercial Bank Ltd' },
    { SHORT_NAME: 'EIB', FULL_NAME: 'Ngân hàng Xuất nhập khẩu Việt Nam(Eximbank) ' },
    { SHORT_NAME: 'FCB', FULL_NAME: 'Ngân hàng TMCP Đệ Nhất ' },
    { SHORT_NAME: 'GPB', FULL_NAME: 'Ngân hàng TMCP Dầu khí Toàn Cầu ' },
    { SHORT_NAME: 'HBB', FULL_NAME: 'Ngân hàng TMCP Nhà Hà Nội(Habubank ) ' },
    { SHORT_NAME: 'HDB', FULL_NAME: 'Ngân hàng TMCP Phát triển thành phố Hồ Chí Minh ' },
    { SHORT_NAME: 'HLB', FULL_NAME: 'Hong Leong Bank' },
    { SHORT_NAME: 'HNCB', FULL_NAME: 'Hua Nan Commerecial Bank' },
    { SHORT_NAME: 'HSBC', FULL_NAME: 'Ngân hàng HSBC Việt Nam ' },
    { SHORT_NAME: 'IBK', FULL_NAME: 'Industrial Bank Of Korea' },
    { SHORT_NAME: 'ICBC', FULL_NAME: 'NH Cong Thuong Trung Quoc' },
    { SHORT_NAME: 'IVB', FULL_NAME: 'Ngân hàng Indovina ' },
    { SHORT_NAME: 'JP_MORGAN_CHASE', FULL_NAME: 'JP Morgan Chase Bank' },
    { SHORT_NAME: 'KBNN', FULL_NAME: 'Kho bac nha nuoc' },
    { SHORT_NAME: 'KEB_HANA', FULL_NAME: 'Korea Exchange Bank' },
    { SHORT_NAME: 'KEB_HANA_HCM', FULL_NAME: 'Korean Exchange Bank HCM' },
    { SHORT_NAME: 'KLB', FULL_NAME: 'Ngân hàng TMCP Kiên Long ' },
    { SHORT_NAME: 'KOOKMIN', FULL_NAME: 'Kookmin Bank' },
    { SHORT_NAME: 'LVB', FULL_NAME: 'Ngân hàng TMCP Bưu Điện Liên Việt ' },
    { SHORT_NAME: 'MALAYAN_BERHAD', FULL_NAME: 'Malayan Banking Berhad' },
    { SHORT_NAME: 'MAY_BANK', FULL_NAME: 'May Bank' },
    { SHORT_NAME: 'MBB', FULL_NAME: 'Ngân hàng TMCP Quân đội (MBB) ' },
    { SHORT_NAME: 'MDB', FULL_NAME: 'Ngân hàng TMCP Phát Triển Mê Kông ' },
    { SHORT_NAME: 'MHB', FULL_NAME: 'Ngân hàng phát triển nhà đồng bằng sông Cửu Long ' },
    { SHORT_NAME: 'MIZUHO', FULL_NAME: 'Ngân hàng Mizuho Việt Nam ' },
    { SHORT_NAME: 'MSB', FULL_NAME: 'Ngân hàng TMCP Hàng Hải Việt Nam(Maritime bank) ' },
    { SHORT_NAME: 'NAB', FULL_NAME: 'Ngân hàng TMCP Nam Á ' },
    { SHORT_NAME: 'NASB', FULL_NAME: 'Ngân hàng TMCP Bắc Á ' },
    { SHORT_NAME: 'NCB', FULL_NAME: 'Ngân hàng TMCP Quốc Dân ' },
    { SHORT_NAME: 'NHNN', FULL_NAME: 'Ngân hàng nhà nước' },
    { SHORT_NAME: 'NONG_HYUP', FULL_NAME: 'Nong Hyup Bank' },
    { SHORT_NAME: 'NVB', FULL_NAME: 'Ngân hàng TMCP Nam Việt ' },
    { SHORT_NAME: 'OCB', FULL_NAME: 'Ngân hàng TMCP Phương Đông ' },
    { SHORT_NAME: 'OCBC', FULL_NAME: 'Oversea Chinese Banking Corp' },

]



export const path = {
    HOME: '/',
    // LOGIN: '/login',
    // LOG_OUT: '/logout',
    // SYSTEM: '/system',
    // MONITOR: '/Monitor',
    // CONFIG: '/config',

    INFO_ACCOUNT_REGISTER: '/info-account-register',
    ACCOUNT_REGISTER: '/account-register',
    ACTIVE_EMAIL: '/active-email',
    INTRO_CRE_ACC: '/intro-cre-acc',
};

export const languages = {
    VI: 'vi',
    EN: 'en'
};

export const manageActions = {
    ADD: "ADD",
    EDIT: "EDIT",
    DELETE: "DELETE",
    APPROVE: "APPROVE",
    CREATE: "CREATE",
    UPDATE: "UPDATE"
};

export const dateFormat = {
    SEND_TO_SERVER: 'DD/MM/YYYY'
};

export const YesNoObj = {
    YES: 'Y',
    NO: 'N'
};

export const exchanges = {
    HOSE: { name: "HOSE", code: "001" },
    HNX: { name: "HNX", code: "002" }
};

export const rowsPerPageArr = [20, 50, 100, 200, 500];

export const custTypes = {
    ADMIN: 'A',
    MANAGER: 'M',
    STAFF: 'S'
};

export const emitEvents = {
    ON_CLOSE_FULLSCREEN_WIDGET: 'ON_CLOSE_FULLSCREEN_WIDGET',
    ON_OPENNING_A_MODAL: 'ON_OPENNING_A_MODAL',
    ON_CLOSING_A_MODAL: 'ON_CLOSING_A_MODAL',
};

export const convertBase64toImageString = "data:image/png;base64,";



export const Event = {
    ON_INIT_DAY: 'ON_INIT_DAY',
    MARKET_INFO_LOADED: 'MARKET_INFO_LOADED',
    UPDATE_LATEST_MARKET_INFO: 'UPDATE_LATEST_MARKET_INFO',
    UPDATE_LATEST_CEIL_FLOOR_COUNT: 'UPDATE_LATEST_CEIL_FLOOR_COUNT',
    UPDATE_STOCK_REALTIME: 'UPDATE_STOCK_REALTIME',
    RESIZE_HIGH_CHART_SYMBOL_DETAIL: "RESIZE_HIGH_CHART_SYMBOL_DETAIL",
    // TRADINGVIEW ORDER
    CHANGE_PRICE_ORDER_BY_TRADINGVIEW: "CHANGE_PRICE_ORDER_BY_TRADINGVIEW",
    ON_REMOVE_ORDER_BY_TRADINGVIEW: "ON_REMOVE_ORDER_BY_TRADINGVIEW",
    RELOAD_TRADINGVIEW_BY_NORMALORDER: "RELOAD_TRADINGVIEW_BY_NORMALORDER",
    RELOAD_TRADINGVIEW_BY_NORMALORDER_FDS: "RELOAD_TRADINGVIEW_BY_NORMALORDER_FDS",
    CLEAR_ORDER_BY_TRADINGVIEW: "CLEAR_ORDER_BY_TRADINGVIEW",
    ON_CHANGE_POSITIONS_BY_TRADINGVIEW: "ON_CHANGE_POSITIONS_BY_TRADINGVIEW",
    RELOAD_TRADINGVIEW_BY_OPENPOSITIONS: "RELOAD_TRADINGVIEW_BY_OPENPOSITIONS",
    RELOAD_TRADINGVIEW_BY_FDSOPENPOSITIONS: "RELOAD_TRADINGVIEW_BY_FDSOPENPOSITIONS",

    UPDATE_LAST_CLOSEPRICE: "UPDATE_LAST_CLOSEPRICE",
    CANCEL_OR_ERR_ACTION_EDIT_ORDER: "CANCEL_OR_ERR_ACTION_EDIT_ORDER",
    // CANCEL_OR_ERR_ACTION_EDIT_POSITION: "CANCEL_OR_ERR_ACTION_EDIT_POSITION",
    // CANCEL_OR_ERR_ACTION_EDIT_FDSOPENPOSITIONS: "CANCEL_OR_ERR_ACTION_EDIT_FDSOPENPOSITIONS",
    CREATE_NEW_FILTER_ALGO: 'CREATE_NEW_FILTER_ALGO',
    UPDATE_CURRENT_FILTER_ALGO: 'UPDATE_CURRENT_FILTER_ALGO',
    // Ekyc
    DO_SUBMIT_ADD_FILE_CAM: 'DO_SUBMIT_ADD_FILE_CAM',
    DO_SUBMIT_ADD_FILE_CAM_SUSSESS: 'DO_SUBMIT_ADD_FILE_CAM_SUSSESS',
    DO_SUBMIT_COMPARE_FILE_AND_FACE: 'DO_SUBMIT_COMPARE_FILE_AND_FACE',
    DO_SUBMIT_COMPARE_FILE_AND_FACE_SUSSESS: 'DO_SUBMIT_COMPARE_FILE_AND_FACE_SUSSESS',
    DO_SKIP_SUBMIT_COMPARE_FILE_AND_FACE: 'DO_SKIP_SUBMIT_COMPARE_FILE_AND_FACE',
    DO_SHOW_TAKE_PHOTO: 'DO_SHOW_TAKE_PHOTO',
    DO_SHOW_RETAKE_PHOTO: 'DO_SHOW_RETAKE_PHOTO',

    HIDE_MODAL_TIMEOUT: 'HIDE_MODAL_TIMEOUT',

    APPLY_COUPON: 'APPLY_COUPON',
};
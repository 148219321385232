import axios from '../axios';
import * as queryString from 'query-string';
import _ from 'lodash';
import { CommonUtils, manageActions } from '../utils';

const adminService = {

    /**
     * Đăng nhập hệ thống
     * {
     *  "username": "string",
     *  "password": "string"
     * }
     */
    login(loginBody) {
        return axios.post(`/admin/login`, loginBody)
    },

    /**
     * Lấy danh sách users hệ thống
     * tlid: mã admin đăng nhập hệ thống
        tlid,
        offset,
        limit,
        username,
        userid,
        fullname,
        mobile,
        email,
        status,
        usertype
     */
    getFoUsers(tlid, offset, limit, username, userid, fullname, mobile, email, status, usertype) {
        const queryParams = {
            tlid: tlid ? tlid : null,
            offset: offset ? offset : 1,
            limit: limit ? limit : 200,
            username: username ? username : "ALL",
            userid: userid ? userid : "ALL",
            fullname: fullname ? fullname : "ALL",
            mobile: mobile ? mobile : "ALL",
            email: email ? email : "ALL",
            status: status ? status : "ALL",
            usertype: usertype ? usertype : "ALL",
        };
        return axios.get(`/admin/foUsers?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy danh sách users online của hệ thống
     * tlid: mã admin đăng nhập hệ thống
     * offset: stt bản ghi bắt đầu
     * limit: stt bản ghi kết thúc
     * username: tên người dùng online
     * userid: mã người dùng
     * fullname: tên đầy đủ
     * mobile: Số điện thoại di động
     * email: email
     * status: trạng thái
     * idcode: số cmnd
     * custtype: loại KH (vãng lai, có TK giao dịch)
     */
    getOnlineUsers(tlid, offset, limit, custtype, username, userid, fullname, mobile, email, status, idcode) {
        const queryParams = {
            tlid: tlid ? tlid : null,
            offset: offset ? offset : 1,
            limit: limit ? limit : 200,
            custtype: custtype ? custtype : "ALL",
            username: username ? username : "ALL",
            userid: userid ? userid : "ALL",
            fullname: fullname ? fullname : "ALL",
            mobile: mobile ? mobile : "ALL",
            email: email ? email : "ALL",
            status: status ? status : "ALL",
            idcode: idcode ? idcode : "ALL"
        };
        return axios.get(`/admin/onlineUsers?` + queryString.stringify(queryParams));
    },

    //get danh sách user mua gói dịch vụ trên fs2pro
    getOnlineBoughtPackage(offset, limit, fromdate, todate, feetype, userid) {
        let queryParams = {
            offset, limit, fromdate, todate, feetype, userid
        }

        return axios.get(`/admin/onlineBoughtPackage?` + queryString.stringify(queryParams));

    },

    getAllOnlineUsers() {
        return axios.get(`/admin/onlineUsers`);
    },

    /// Tham khảo 
    /**
    * Thêm/Sửa user
    * {
    *  "tlid": "number",
    *  "userid": "string"
    *  "username": "string"
    *  "usertype": "string"
    *  "fullname": "string"
    *  "password": "string"
    *  "mobile": "string"
    *  "email": "string"
    *  "status": "string"
    *  "action": "string" ADD or EDIT
    * }
    */
    addOrEditUser(userBody) {
        return axios.post(`/admin/foUsers`, userBody)
    },
    /// Tham khảo 

    /**
     * Xóa user
     * {
     *  "tlid": "number",
     *  "userid": "string"
     *  "username": "string"
     *  "usertype": "string"
     *  "fullname": "string"
     *  "password": "string"
     *  "mobile": "string"
     *  "email": "string"
     *  "status": "string"
     *  "action": "string" DELETE
     * }
     */
    deleteUser(userBody) {
        return axios.post(`/admin/foUsers`, userBody)
    },

    /**
     * Reset user's password
     * {
     *  "username": "string"
     *  "phone": "string"
     * }
     */
    resetUserPassword(userBody) {
        return axios.post(`/admin/resetUserPassword`, userBody)
    },


    /**
    * Lấy danh sách sản phẩm 
    * tlid: mã admin đăng nhập hệ thống
    */
    getFoProducts(tlid) {
        const queryParams = {
            tlid: tlid ? tlid : null
        };
        return axios.get(`/admin/foprtypes?` + queryString.stringify(queryParams));
    },

    /**
     * Thêm/Sửa sản phẩm
     * {
     *  "tlid": "number",
     *  "prid": "string" *
     *  "prname": "string" *
     *  "prtype": "string" *
     *  "status": "string" *
     *  "description": "string"
     *  "fromDate": "string" *
     *  "toDate": "string" *
     *  "prcusttype": "string" //action=ADD
     *  "action": "string" ADD or EDIT
     * }
     */
    addOrEditProduct(productBody) {
        return axios.post(`/admin/foprtypes`, productBody)
    },

    /**
     * Xóa sản phẩm
     * {
     *  "tlid": "number",
     *  "prid": "string" *
     *  "prname": "string" *
     *  "prtype": "string" *
     *  "status": "string" *
     *  "description": "string"
     *  "fromDate": "string" *
     *  "toDate": "string" *
     *  "action": "string" DELETE
     * }
     */
    deleteProduct(productBody) {
        return axios.post(`/admin/foprtypes`, productBody)
    },

    /**
     * Lấy allCode hệ thống theo cdtype & cdname
     */
    getAllCode(cdtype, cdname) {
        const queryParams = {
            cdtype: cdtype,
            cdname: cdname
        };
        return axios.get(`/admin/allCode?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy thông tin các gói tạo mặc định cho khách hàng vãng lai, khách hàng google/facebook và broker
     */
    getCountProductByUser() {
        return axios.get(`/admin/countProductByTypeUser`);
    },

    /**
     * Lấy toàn bộ cây menu khi thêm mới gói
     * prcusttype: "string" loại tk customer/broker
     */
    getAllMenuList(prcusttype) {
        const queryParams = {
            prcusttype: prcusttype,
        };
        return axios.get(`/admin/allMenuList?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy cây menu theo gói (khi sửa gói)
     * tlid: "number" mã user
     * prid: "string" mã gói
     */
    getListMenuRightByPrid(tlid, prid) {
        const queryParams = {
            tlid: tlid,
            prid: prid
        };
        return axios.get(`/admin/listmenurigths?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy danh sách templates
     * tlid: "number" mã user
     * prid: "string" mã gói
     * tempid: "string" mã temp
     */
    getTemplates(tlid, prid, tempid) {
        const queryParams = {
            tlid: tlid,
            prid: prid,
            tempid: tempid
        };
        return axios.get(`/admin/templates?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy danh sách template images
     * tlid: "number" mã user
     * tempid: "string" mã temp
     */
    getTemplateImages(tlid, tempid) {
        const queryParams = {
            tlid: tlid,
            tempid: tempid
        };
        return axios.get(`/admin/templateImages?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy ảnh tiếp theo của ảnh imageid
     * tlid: "number" mã user
     * tempid: "string" mã temp
     * imageid: "number" mã ảnh
     */
    getImageDetailByID(tlid, tempid, imageid) {
        const queryParams = {
            tlid: tlid,
            tempid: tempid,
            imageid: imageid
        };
        return axios.get(`/admin/imageDetailByID?` + queryString.stringify(queryParams));
    },

    /**
     * Lưu cây menu phân quyền chức năng
     * {
     *  "tlid": "number", 
     *  "prid": "string"
     *  "right_list": "string" menu dạng: cmdid#cmdallow$
     * }
     */
    assignRights(menuBody) {
        return axios.post(`/admin/assignRigths`, menuBody)
    },

    /**
     * Lấy danh sách quản lý gán nhóm
     * tlid: "number" mã user
     * offset: stt bản ghi bắt đầu
     * limit: stt bản ghi kết thúc
     * prname: tên gói
     * status: trạng thái
     * custtype: nhóm khách hàng
     * username: Tài khoản
     * frdate: Ngày đăng ký
     * todate: ngày hết hiệu lực
     * expdatetype: Thời gian sử dụng gói
     */
    getListFouserGroups(tlid, offset, limit, prname, status, custtype, username, frdate, todate, expdatetype) {
        const queryParams = {
            tlid: tlid ? tlid : null,
            offset: offset ? offset : 1,
            limit: limit ? limit : 200,
            prname: prname ? prname : 'ALL',
            status: status ? status : 'ALL',
            custtype: custtype ? custtype : 'ALL',
            username: username ? username : 'ALL',
            frdate: frdate ? frdate : 'ALL',
            todate: todate ? todate : 'ALL',
            expdatetype: expdatetype ? expdatetype : 'ALL'
        };
        return axios.get(`/admin/listfousergroups?` + queryString.stringify(queryParams));
    },

    /**
     * Gán gói dịch vụ cho khách hàng
     * {
     *  "tlid": "number",
     *  "prid": "string" 
     *  "userid": "string" 
     *  "custtype": "string" 
     *  "frdate": "string" 
     *  "todate": "string" 
     * }
     */
    addUserToGroup(assignBody) {
        return axios.post(`/admin/adduser2group`, assignBody)
    },

    /**
     * Xóa gán nhiều gói dịch vụ cho khách hàng
     * {
     *  "listAssigns": "array",
     * }
     */
    unassignMultiUserToGroup(unassignBody) {
        return axios.post(`/admin/removeMultiUser2group`, unassignBody)
    },

    /**
     * Xóa gán gói dịch vụ cho khách hàng
     * {
     *  "tlid": "number",
     *  "id": "string"  
     * }
     */
    unassignUserToGroup(unassignBody) {
        return axios.post(`/admin/removeuser2group`, unassignBody)
    },

    /**
     * Lấy thông tin trạng thái hệ thống
     */
    getSystemStatus() {
        return axios.get(`/admin/monitor/sysStatus`);
    },

    /**
     * Lấy thông tin trạng thái bank & sms
     */
    getBankSmsStatus() {
        return axios.get(`/admin/monitor/bankSmsStatus`);
    },

    /**
     * Lấy thông tin bussinessStatus
     */
    getBussinessStatus() {
        return axios.get(`/admin/monitor/bussinessStatus`);
    },

    /**
     * Lấy thông tin trạng thái kết nối giữa các service
     */
    getServiceStatus() {
        return axios.get(`/admin/monitor/serviceStatus`);
    },

    /**
     * Lấy thông tin trạng thái lệnh theo sàn
     * tradeplace: "string" mã sàn 001:HOSE/002:HNX
     */
    getOrderStatus(tradeplace) {
        const queryParams = {
            tradeplace: tradeplace
        };
        return axios.get(`/admin/monitor/orderStatus?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy trạng thái warning (trên widget order status) theo sàn
     * tradeplace: "string" sàn HOSE/HNX
     */
    getWarningStatus(tradeplace) {
        const queryParams = {
            tradeplace: tradeplace
        };
        return axios.get(`/admin/monitor/warningStatus?` + queryString.stringify(queryParams));
    },

    /**
     * Lấy danh sách cấu hình
     */
    getConfigList() {
        return axios.get(`/admin/monitor/configList`);
    },

    /**
     * Update config
     * {
     *  "username": "string",
     *  "logType": "string",
     *  "logTypeName": "string",
     *  "timeProcess": "number",
     *  "maxNum2Noti": "number",
     *  "notiSMS": "string",
     *  "notiEmail": "string",
     *  "listMobile": "string",
     *  "listEmail": "string",     
     *  "status": "string",     
     * }
     */
    updateConfig(configBody) {
        return axios.post(`/admin/monitor/configList`, configBody)
    },

    /**
     * Tạo và duyệt yêu cầu temp
     * {
     *  "tlid": "number",
     *  "prid": "string"
     *  "tempid": "string"
     *  "action": "string" ADD/APPROVE
     * }
     */
    createOrApproveTemp(body) {
        return axios.post(`/admin/createApproveReqTemp`, body)
    },

    /**
     * Change password
     * {
     *  "username": "string",
     *  "oldPassword": "string"
     *  "newPassword": "string"
     * }
     */
    changePassword(body) {
        return axios.post(`/admin/changePassword`, body)
    },

    /**
    * Lấy toàn bộ các widget trong fs2pro khi thêm mới gói
    */
    getAllWidgetList() {
        return axios.get(`/admin/allWidgetList`);
    },

    /**
     * Lấy các widget trong fs2pro theo gói (khi sửa gói)
     * prid: "string" mã gói
     */
    getListWidgetRightByPrid(prid) {
        const queryParams = {
            prid: prid
        };
        return axios.get(`/admin/listWidgetRights?` + queryString.stringify(queryParams));
    },

    /**
     * Lưu các widget trong fs2pro để phân quyền
     * {
     *  "prid": "string"
     *  "right_list": "string" menu dạng: cmdid#cmdallow$
     * }
     */
    assignWidgetRights(menuBody) {
        return axios.post(`/admin/assignWidgetRights`, menuBody)
    },

    /**
     * Get tất cả mã giảm giá
     *  
     */
    getAllCoupons() {
        let queryParams = {
            dccode: 'ALL' // truyền vào 'ALL' -> lấy tất cả, truyền vào id -> lấy theo id
        }
        return axios.get(`/admin/get-coupon?` + queryString.stringify(queryParams));
    },


    /**
     * 
            prname: prname ? prname : 'ALL',
            status: status ? status : 'ALL',
            custtype: custtype ? custtype : 'ALL',
            username: username ? username : 'ALL',
            frdate: frdate ? frdate : 'ALL',
            todate: todate ? todate : 'ALL',
            expdatetype: expdatetype ? expdatetype : 'ALL'
     */

    upsertCoupons(data) {
        // let data = {
        //     dccode: dccode ? dccode : 'ALL',
        //     dcname: dcname ? dcname : 'ALL',
        //     notes: notes ? notes : 'ALL',
        //     ispresent: ispresent ? ispresent : 'ALL',
        //     dctype: dctype ? dctype : 'ALL',
        //     dcamt: 10,
        //     minamt: 20,
        //     maxamt: 30,
        //     ismulti: 'N',
        //     frdate: '20/03/2021', // -> theo format này dd/mm/yyyy
        //     todate: '20/03/2021', // -> theo format này dd / mm / yyyy
        //     status: 'A',
        //     action: 'DELETE', // truyền vào 'CREATE', 'UPDATE', 'DELETE'
        // }
        return axios.post(`/admin/upsert-coupon`, data)
    },


    /**
    * Get tất cả biểu phí đã tạo
    * {
    *  "feeid": "feeid" // 'ALL' -> lấy tất cả fees, 'feeid' -> lấy 1 fee
    * }
    */
    getAllFee(data) {
        return axios.get(`/admin/get-fee?` + queryString.stringify(data))
    },

    updateFee(data) {
        return axios.post(`/admin/upsert-fee`, data)
    },

    /**
   * Update fee khi CRUD gói dịch vụ
   */
    updateProductFee(data) {
        return axios.post(`/admin/upsert-pr-fee`, data)
    },

    getProductFee(prid) {
        return axios.get(`/admin/get-pr-fee?` + queryString.stringify({ prid }))
    },

    async deleteProductFee(prid) {
        return new Promise(async (resolve, reject) => {
            try {
                let fee = await axios.get(`/admin/get-pr-fee?` + queryString.stringify({ prid }))
                if (fee && fee.length > 0) {
                    await this.updateProductFee({
                        prid: prid,
                        feeid: fee[0].feeid,
                        action: manageActions.DELETE,
                    }).then(() => {
                        resolve()
                    })
                }
                else {
                    resolve()
                }

            } catch (e) {
                console.log(e);
            }
        })
    }
};

export default adminService;
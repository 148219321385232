import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import './Header.scss';
import iconUserWhite from "../../../assets/images/register/icon-user-white.png";
import iconUserDark from "../../../assets/images/register/icon-user-dark.png";
import iconAddFriendWhite from "../../../assets/images/register/icon-add-friend-white.png";
import logo from "../../../assets/images/logo_header.png";
import config from '../../../config';
const LINK_WEB_TRADING = config.app.LINK_WEB_TRADING
const LINK_WEB_ONLINE = config.app.LINK_WEB_ONLINE

class Header extends Component {
    render() {

        return (
            <header className="header header-desktop" id="header">
                <div className="header-upper">
                    <div className="header-logo">
                        <a href="#">
                            <img src={logo} alt="Logo" />
                            {/* <img width="191px" height="auto" src={logo} alt="Logo" /> */}
                        </a>
                    </div>
                    <div className="header-user">
                        <a className="user-btn" href="#">
                            <div className="header-user-icon">
                                <img src={iconUserWhite} alt="" className="white" />
                                <img src={iconUserDark} alt="" className="dark" />
                            </div>
                            <div className="header-user-text">
                                <a href={LINK_WEB_TRADING} className="user-title">Đăng nhập</a>
                                <span className="user-note">Bạn đã có tài khoản</span>
                            </div>
                        </a>
                        <a className="user-btn _secondary-color" href="#">
                            <div className="header-user-icon">
                                <img src={iconAddFriendWhite} alt="" className="white" />
                                <img src={iconUserDark} alt="" className="dark" />
                            </div>
                            <div className="header-user-text">
                                <a href={LINK_WEB_ONLINE} className="user-title">Mở tài khoản</a>
                            </div>
                        </a>
                    </div>
                </div>

            </header>
        );
    }
}
const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'reactstrap';

import DraggableWrapper from '../DraggableWrapper';
import JSXUtils from '../../utils/JsxUtils';
import { KeyCodeUtils } from "../../utils";

class DraggableModal extends Component {
    constructor(props) {
        super(props);
        this.draggableWrapper = React.createRef();
    };

    componentDidUpdate() {
        const { isOpen } = this.props;
        if (isOpen) this.draggableWrapper && this.draggableWrapper.current && this.draggableWrapper.current.resetPosition();
        if (isOpen === true) {
            document.addEventListener('keydown', this.handlerKeyDown);
        } else {
            document.removeEventListener('keydown', this.handlerKeyDown);
        }

    };

    handlerKeyDown = (event) => {
        const { toggle, onEnter } = this.props;
        const keyCode = event.which || event.keyCode;
        switch (keyCode) {
            case KeyCodeUtils.ESCAPE:
                event.preventDefault();
                toggle();
                break;
            case KeyCodeUtils.ENTER:
                event.preventDefault();
                if (onEnter) {
                    onEnter();
                }
                break;
            default:
                break;
        }
    };


    render() {
        const { isOpen, toggle, onClose, className, headerClass, customizeTitleReport } = this.props;
        const { titleId } = this.props;

        let headerContainerClass = headerClass ? JSXUtils.joinClass("dragHandler", headerClass) : JSXUtils.joinClass("dragHandler", "header");
        let containerClass = JSXUtils.joinClass(className, 'draggable-modal');
        return (
            <DraggableWrapper
                ref={this.draggableWrapper}
                dragClass=".dragHandler"
                wrapperClass="modal-content"
            >
                <Modal
                    backdrop={"static"}
                    keyboard={false}
                    isOpen={isOpen}
                    toggle={this.toggle}
                    className={containerClass}
                    centered={true}
                    autoFocus={false}
                >
                    <div className={headerContainerClass}>
                        <div className="row no-gutters">
                            <div className="col">
                                <div className="title">
                                    {
                                        customizeTitleReport ? 
                                        customizeTitleReport : <FormattedMessage id={titleId} />
                                    }
                                </div>
                            </div>
                            <div className="col-auto">
                                <button onClick={onClose}><i className="fal fa-times" /></button>
                            </div>
                        </div>
                    </div>
                    {this.props.children}
                </Modal>
            </DraggableWrapper>
        )
    };
};

export default DraggableModal;
import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import "./CreateAccountStep3.scss";
import "./CreateAccountStep31.scss";
import imgAvatar from "../../assets/images/register/dang-ki-thong-tin/img-avatar.jpg";
import ekycServer from "../../services/ekycServer";
import { timer } from "../../clients/timer";
import config from "../../config";
import { STEP1_AREA, TYPE_IMG } from "../../utils/constant";
import Webcam from "react-webcam";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./TestCamFace.scss";
import $ from "jquery";
import { Modal } from "reactstrap";
import {
    CommonUtils,
    LanguageUtils,
    ERROR_CODE,
    STEP3_SEX,
    Event,
} from "../../../src/utils";
import userService from "../../services/userService";
import { push, Link } from "connected-react-router";
import { connect } from "react-redux";
import _ from "lodash";
import { emitter } from "../../clients/emitter";
import TextareaAutosize from "react-textarea-autosize";

const Url_Upload_File = config.app.DIR_UPLOAD_FILE;
const IS_WIN_SERVER = config.app.IS_WIN_SERVER;
const PATH_FILE = IS_WIN_SERVER == true ? "\\" : "/";
const MAX_SIZE_FILE_UPLOAD = config.app.MAX_SIZE_FILE_UPLOAD;

const DEFAULT_MIN_OPNPOINT = config.app.DEFAULT_MIN_OPNPOINT; // Điểm OPNPOINT tối thiếu để pass qua giao diện mở tài khoản
const DEFAULT_MAX_FAKE_SCOPE = config.app.DEFAULT_MAX_FAKE_SCOPE;
const NUMBER_FAIL_EKYC = config.app.NUMBER_FAIL_EKYC;

const DISABLE_CHECK_FACE_EKYC = false;

const videoConstraints = {
    // width: "100vw",
    height: 298,
    facingMode: "user",
};

function urltoFile(url, filename, mimeType) {
    return fetch(url)
        .then(function (res) {
            return res.arrayBuffer();
        })
        .then(function (buf) {
            return new File([buf], filename, { type: mimeType });
        });
}

function checkCamera() {
    navigator.getMedia =
        navigator.getUserMedia || // use the proper vendor prefix
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia;
    if (navigator.getMedia) {
        navigator.getMedia(
            { video: true },
            function () {
                console.log("camera available");
            },
            function () {
                alert("Bạn cần cho phép chụp ảnh từ camera");
            }
        );
    } else {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia(
                { video: true },
                function () {
                    console.log("camera available");
                },
                function () {
                    alert("Bạn cần cho phép chụp ảnh từ camera");
                }
            );
        }
    }
}

const msg_error_default =
    "Ảnh gương mặt không khớp với ảnh mặt trước của CMND/CCCD hoặc chất lượng ảnh thấp. Quý khách vui lòng kiểm tra camera của thiết bị và chụp lại ảnh gương mặt!";

class CreateAccountStep3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStartCamera: undefined,
            imagePreviewUrl: [],
            // inputImgValue: {
            //     'F': undefined,
            //     'B': undefined,
            // },
            imgfiles: undefined,
            imgtypefiles: "png", // Mặc định
            isShowTake: true, // Hiển thị mode Camera
            isShowDeleteIcon: false,
            webcamRef: "webcamRef",
            msg_error: undefined,
            dirFile: {}, // đường dẫn lưu file ảnh upload
            openPoint: "",

            isShowModalErrorEKYC: false, //modal hiển thị lỗi EKYC

            isReachMaxFailEKYC: false,
            countFailEKYC: 0,
        };
        checkCamera();
    }

    checkInfoByEkyc = async (data) => {
        // Haki.: Get Info Img Back
        let objFile = {
            requestid: data.requestid,
            foldername: data.folder_name,
            filename: data.file_name[TYPE_IMG.FACE],
            filenamefront: data.file_name[TYPE_IMG.FRONT],
            file: data.file[TYPE_IMG.FACE],
        };
        return await ekycServer
            .checkInfoFaceByEkyc(objFile)
            .then(async (res) => {
                console.log("getInfoImgByEkyc=====================", res);
                return res;
            });
        // return 'checkInfoByEkyc'
    };
    // getInfoImgByEkyc = async (typeImg, data) => {
    //     // Haki.: Get Info Img Back
    //     let objFile = {
    //         requestid: data.requestid,
    //         foldername: data.folder_name,
    //         filename: data.file_name[typeImg],
    //         file: data.file[typeImg],
    //     }
    //     return await ekycServer.getInfoImgByEkyc(objFile).then(async (res) => {
    //         console.log('getInfoImgByEkyc=====================', res)
    //         return res
    //     })
    // }

    checkValueInfoByEkyc = async (dataFace) => {
        if (DISABLE_CHECK_FACE_EKYC) {
            return true;
        }

        let { msg_error, countFailEKYC } = this.state;
        this.setState({
            msg_error: "",
        });
        try {
            // Haki.: Get Info Img Back
            if (dataFace.verify_result == "0") {
                // Không cùng 1 người
                this.setState({
                    // msg_error: 'Ekyc Lỗi: Không cùng 1 người',
                    msg_error: msg_error_default,
                    isShowModalErrorEKYC: true,
                    countFailEKYC: countFailEKYC + 1,
                });
                return false;
            }
            if (dataFace.verify_result == "1") {
                // Có thể cùng 1 người
                this.setState({
                    // msg_error: 'Ekyc Lỗi: Có thể cùng 1 người',
                    msg_error: msg_error_default,
                    isShowModalErrorEKYC: true,
                    countFailEKYC: countFailEKYC + 1,
                });
                return false;
            }
            if (
                parseFloat(dataFace.sim) * 100 <
                +this.props.allData.dataStep1_1.openPointEKYC
            ) {
                // if (parseFloat(dataFace.sim) * 100 < DEFAULT_MIN_OPNPOINT) {
                // Điểm tương tự của 2 ảnh nhỏ hơn DEFAULT_MIN_OPNPOINT
                this.setState({
                    // msg_error: 'Ekyc Lỗi: Điểm tương tự của 2 ảnh nhỏ hơn ' + DEFAULT_MIN_OPNPOINT + ' điểm!',
                    msg_error: msg_error_default,
                    isShowModalErrorEKYC: true,
                    countFailEKYC: countFailEKYC + 1,
                });
                return false;
            }
            if (!dataFace.face_anti_spoof_status) {
                // Điểm tương tự của 2 ảnh nhỏ hơn DEFAULT_MIN_OPNPOINT
                this.setState({
                    // msg_error: 'Ekyc Lỗi: Ekyc dataFace.face_anti_spoof_status error!',
                    msg_error: msg_error_default,
                    isShowModalErrorEKYC: true,
                    countFailEKYC: countFailEKYC + 1,
                });
                return false;
            } else {
                if (dataFace.face_anti_spoof_status.fake_code != "REAL") {
                    // Ảnh nghi vấn giả mạo
                    if (
                        dataFace.face_anti_spoof_status.fake_score >=
                        DEFAULT_MAX_FAKE_SCOPE
                    ) {
                        // Nghi vấn ảnh giả mạo
                        this.setState({
                            // msg_error: 'Ekyc Lỗi: Ảnh nghi vấn giả mạo!',
                            msg_error: msg_error_default,
                            isShowModalErrorEKYC: true,
                            countFailEKYC: countFailEKYC + 1,
                        });
                        return false;
                    }
                }
            }
            return true;
        } catch (e) {
            alert("checkValueInfoByEkyc.:Error=" + e);
            return false;
        }
    };

    checkEmptyObjectByTypeImage = (type) => {
        let { allData } = this.props;
        let dataStep3 = allData.dataStep3;
        if (dataStep3 && !_.isEmpty(dataStep3)) {
            if (
                dataStep3[`isSetData${type}`] &&
                dataStep3[`isSetData${type}`] === true
            ) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    submitCheckInfoByEkyc = async () => {
        try {
            CommonUtils.showLoaderScreen();
            let { allData, setParentStateFromChild, setStep } = this.props;
            let dataStep1 = allData.dataStep1;
            let phoneNumber = dataStep1.phoneNumber;
            //
            let dataStep2 = allData.dataStep2;
            let { imgfiles, imgtypefiles } = dataStep2;
            //
            if (!this.state.imgfiles) {
                this.setState({
                    msg_error: "Chưa có ảnh chụp!",
                });
                return null;
            }
            let imgfilesFace = this.state.imgfiles;
            let imgtypefilesFace = this.state.imgtypefiles;
            console.log(
                "submitCheckInfoByEkyc.:imgfilesFace=",
                imgfilesFace,
                "imgtypefilesFace=",
                imgtypefilesFace
            );
            // console.log('submitCheckInfoByEkyc.:dataStep1=', dataStep1)
            // console.log('submitCheckInfoByEkyc.:dataStep2=', dataStep2, imgfiles, imgtypefiles)
            if (
                imgfiles &&
                imgfiles[TYPE_IMG.FRONT] &&
                imgfiles[TYPE_IMG.BACK]
            ) {
                // let unixtime = moment().unix();
                // let name = dataStep1.phoneNumber;
                // let requestid = name + '_' + unixtime
                let { sessionid } = allData.dataStep1_1;
                if (sessionid) {
                    let name = sessionid.phoneNumber;
                    let unixtime = sessionid.unixtime;
                    let requestid = sessionid.id; // =phoneNumber+'_'+unixtime
                    let folder_name =
                        timer.formatDateToNameFolder() + PATH_FILE + requestid; // folder Linux/Ubuntu
                    let file_name_front =
                        name +
                        "_" +
                        TYPE_IMG.FRONT +
                        "_" +
                        unixtime +
                        "." +
                        imgtypefiles[TYPE_IMG.FRONT];
                    let file_name_back =
                        name +
                        "_" +
                        TYPE_IMG.BACK +
                        "_" +
                        unixtime +
                        "." +
                        imgtypefiles[TYPE_IMG.BACK];
                    let file_name_face =
                        name +
                        "_" +
                        TYPE_IMG.FACE +
                        "_" +
                        unixtime +
                        "." +
                        imgtypefilesFace;
                    let objFile = {
                        requestid: requestid,
                        folder_name: folder_name,
                        file_name: {
                            // 'FRONT': name + '_' + TYPE_IMG.FRONT + '_' + unixtime + '.' + imgtypefiles[TYPE_IMG.FRONT],
                            FRONT: file_name_front
                                ? file_name_front
                                : undefined,
                            BACK: file_name_back ? file_name_back : undefined,
                            FACE: file_name_face ? file_name_face : undefined,
                        },
                        file: {
                            FRONT: imgfiles[TYPE_IMG.FRONT],
                            BACK: imgfiles[TYPE_IMG.BACK],
                            FACE: imgfilesFace,
                        },
                    };
                    // Haki.: Lưu đường dẫn file xuống Back
                    let dirFile = {
                        FRONT:
                            Url_Upload_File +
                            folder_name +
                            PATH_FILE +
                            objFile.file_name[TYPE_IMG.FRONT],
                        BACK:
                            Url_Upload_File +
                            folder_name +
                            PATH_FILE +
                            objFile.file_name[TYPE_IMG.BACK],
                        FACE:
                            Url_Upload_File +
                            folder_name +
                            PATH_FILE +
                            objFile.file_name[TYPE_IMG.FACE],
                    };
                    let dataFace = await this.checkInfoByEkyc(objFile);
                    console.log("submitCheckInfoByEkyc.:dataFace=", dataFace);
                    if (dataFace) {
                        let objFace = {
                            // iddate: dataFace.issue_date ? dataFace.issue_date : '', // Ngay cap
                            // idplace: dataFace.issue_by ? dataFace.issue_by : '', // Noi cap
                            imgfilesFace,
                            imgtypefilesFace,
                            openpoint_ekyc: parseFloat(dataFace.sim) * 100,
                            dirFile,
                        };
                        await setParentStateFromChild(3, objFace);
                        let check = await this.checkValueInfoByEkyc(dataFace);

                        if (check) {
                            setStep(3.1);
                            CommonUtils.hideLoaderScreen();
                        } else {
                            if (this.state.countFailEKYC === NUMBER_FAIL_EKYC) {
                                emitter.emit(Event.HIDE_MODAL_TIMEOUT);
                            }
                            // Lỗi xác thực EKyc
                            // alert('checkValueInfoByEkyc =>Lỗi xác thực EKyc.:dataFace')
                            CommonUtils.hideLoaderScreen();
                            return null;
                        }
                    } else {
                        // Lỗi xác thực EKyc
                        // alert('Lỗi xác thực EKyc.:dataFace')
                        CommonUtils.hideLoaderScreen();
                        return null;
                    }
                    // console.log('submitCheckInfoByEkyc.:dataFront=', dataFront)
                    // console.log('submitCheckInfoByEkyc.:dataBack=', dataBack)
                    console.log("submitCheckInfoByEkyc.:dataFace=", dataFace);
                    console.log("submitCheckInfoByEkyc.:dirFile=", dirFile);
                } else {
                    alert("Lỗi không tìm thấy sessionid!");
                    CommonUtils.hideLoaderScreen();
                    return null;
                }
            }
        } catch (e) {
            alert("submitCheckInfoByEkyc.:" + e);
            CommonUtils.hideLoaderScreen();
            return null;
        }
    };

    checkRightFaceByServerEkyc = async (obj) => {
        let data = {
            img: obj.hash,
            // 'client_session': 'TEST-FROM-FSS.:checkRightFaceByServerEkyc()'
            client_session: "",
        };
        return ekycServer.checkRightFaceByServerEkyc(data);
    };

    checkHideFaceByServerEkyc = async (obj) => {
        let data = {
            img: obj.hash,
            face_bbox: "",
            face_lmark: "",
            // 'client_session': 'TEST-FROM-FSS.:checkHideFaceByServerEkyc()'
            client_session: "",
        };
        return ekycServer.checkHideFaceByServerEkyc(data);
    };

    doCompareFileFrontByServerEkyc = async (obj) => {
        let data = {
            img_front: obj.img_front,
            img_face: obj.hash,
            // 'client_session': 'TEST-FROM-FSS.:doCompareFileFrontByServerEkyc()'
            client_session: "",
        };
        return ekycServer.compareFlieAndFaceByServerEkyc(data);
    };
    checkValidate = (dataCompare) => {
        console.log("checkValidate.C:dataFront=", dataCompare);
        return true;
    };
    ClearFile = () => {
        let self = this;
        self.setState({
            imagePreviewUrl: [],
            // inputImgValue,
            imgfiles: undefined,
            imgtypefiles: "png", // Mặc định
            isShowTake: true,
            isShowDeleteIcon: false,
            msg_error: undefined,
        });
        self.resetChangeFile();
        $("#upload-image-files-face").val("");
    };
    resetChangeFile = async () => {
        let self = this;
        let objReset = {
            img_face: undefined,
            file_face: undefined,
            opnpoint: "0",
            img_face_type: "",
        };
        // reset thông tin
        // await self.props.onChangeAllData(objReset)
    };
    compareFlieAndFaceByServerEkyc = async () => {};
    show_take_photo = (e) => {
        let { imgfiles, imagePreviewUrl, inputImgValue, isShowTake } =
            this.state;
        if (isShowTake != true) {
            isShowTake = true;
            imgfiles = undefined;
            imagePreviewUrl = [];
            // inputImgValue = undefined
            this.setState({
                isShowTake,
                imgfiles,
                imagePreviewUrl,
                isShowDeleteIcon: false,
                // inputImgValue,
            });
        }
    };

    get_take_photo = async (event) => {
        let self = this;
        if (!this.refs["webcam-Face-Id"]) {
            this.setState({
                isStartCamera: false,
            });
            return null;
        }
        const imageSrc = this.refs["webcam-Face-Id"].getScreenshot();
        let time = Date.now();
        let file_name = "Face_img_" + time;
        let {
            imgfiles,
            imgtypefiles,
            imagePreviewUrl,
            inputImgValue,
            isShowTake,
            isShowDeleteIcon,
        } = this.state;
        let _imgfiles = await urltoFile(imageSrc, file_name, "image/png");
        imgtypefiles = "png"; // Mặc định
        console.log("get_take_photo.:_imgfiles=", _imgfiles);
        // Haki.: Convert về dạng FileList giống với logic upload ảnh
        // let _FileList = new DataTransfer();
        // _FileList.items.add(_imgfiles)
        // imgfiles = _FileList.files
        // console.log('get_take_photo.:imgfiles=', imgfiles)
        imgfiles = _imgfiles;
        // console.log('get_take_photo.:imgfiles=', _FileList)
        let _imagePreviewUrl = [];
        // for (var file of imgfiles) {
        //     _imagePreviewUrl.push(URL.createObjectURL(file));
        // }
        _imagePreviewUrl.push(imageSrc);
        imagePreviewUrl = _imagePreviewUrl;

        isShowTake = false;
        isShowDeleteIcon = true;
        $("#upload-image-files-face").val("");
        // console.log('get_take_photo.:', imgfiles, imagePreviewUrl)
        this.setState({
            isShowTake,
            isShowDeleteIcon,
            imgfiles,
            imgtypefiles,
            imagePreviewUrl,
            // inputImgValue,
        });
    };
    handleImageChange = (e) => {
        let {
            imagePreviewUrl,
            inputImgValue,
            imgfiles,
            isShowTake,
            isShowDeleteIcon,
        } = this.state;
        e.preventDefault();
        if (e.target.files.length > 0) {
            let _imagePreviewUrl = [];
            if (e.target.files.length > 0) {
                // inputImgValue[typeImg] = typeImg;
                imgfiles = e.target.files;
                // var data = e.target.files[0].getAsBinary();
                for (var file of imgfiles) {
                    _imagePreviewUrl.push(URL.createObjectURL(file));
                }
                imagePreviewUrl = _imagePreviewUrl;
                // console.log(_imagePreviewUrl)
                isShowTake = false;
                isShowDeleteIcon = true;
                this.setState({
                    imagePreviewUrl,
                    // inputImgValue,
                    imgfiles,
                    isShowTake,
                });
                // alert(this.state.isShowTake)
            }
        }
    };
    renderUploadForm() {
        let self = this;
        let {
            imgfiles,
            imagePreviewUrl,
            inputImgValue,
            isShowTake,
            isShowDeleteIcon,
        } = this.state;
        let _imgfiles = imgfiles;
        let _imagePreviewUrl = imagePreviewUrl;
        // console.log('renderUploadForm.:', _imagePreviewUrl, _imgfiles)
        let _isShowTake = isShowTake;
        let _isShowDeleteIcon = isShowDeleteIcon;
        return (
            <>
                <div className="img upload-image-preview text-center">
                    <div className="img-border">
                        <div className={!_isShowTake && "display_none"}>
                            <Webcam
                                audio={false}
                                height={videoConstraints.height}
                                ref={"webcam-Face-Id"}
                                screenshotFormat="image/png"
                                minScreenshotHeight="300"
                                minScreenshotWidth="500"
                                // width={videoConstraints.width}
                                videoConstraints={videoConstraints}
                                forceScreenshotSourceSize={true}
                            />
                        </div>
                        <div className={_isShowTake && "display_none"}>
                            <span className="Carousel-check">
                                {/* {_imgfiles && _imgfiles.length > 0 && ( */}
                                <Carousel autoPlay={false} showThumbs={false}>
                                    {_imagePreviewUrl.map((imageItem, i) => {
                                        // console.log('imageItem.:', imageItem, _isShowTake)
                                        return (
                                            <div key={i}>
                                                <img
                                                    alt=""
                                                    src={imageItem}
                                                    height="298px"
                                                    width="100%"
                                                />
                                            </div>
                                        );
                                    })}
                                </Carousel>
                                {/* )} */}
                                {/* {_imgfiles && _imgfiles.length === 0 && (
                                    <div className="no-image-div">
                                    </div>
                                )} */}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="bg-camera"></div>
                <div className="img-btn-clear">
                    <button
                        // ref={this.uploadBtnRef}
                        id={"clear-photo-face"}
                        className="btn btn-take-photo"
                        style={{ width: "100%", display: "none" }}
                        onClick={(e) => this.ClearFile(e)}
                        // disabled={imgfiles && imgfiles.length === 0}
                    ></button>
                    {_isShowDeleteIcon ? (
                        <label
                            htmlFor={"clear-photo-face"}
                            className="label-btn-clear-photo"
                        >
                            {/* <i className="fas fa-upload"></i> */}
                            {/* Chụp ảnh */}
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </label>
                    ) : null}
                    {/* label hiển thị thay cho input file none */}
                </div>
            </>
        );
    }

    onStartCamera = () => {
        if (
            "mediaDevices" in navigator &&
            "getUserMedia" in navigator.mediaDevices
        ) {
            console.log("Let's get this party started");
            this.setState({
                isStartCamera: true,
            });
        } else {
            navigator.mediaDevices.getUserMedia({ video: true });
        }
    };

    takePhotoAgain = () => {
        this.setState(
            {
                msg_error: "",
                isShowModalErrorEKYC: false,
            },
            () => {
                this.show_take_photo();
            }
        );
    };

    confirmMaxFailEKYC = () => {
        let { executeScroll } = this.props;
        this.setState(
            {
                isShowModalErrorEKYC: false,
                msg_error: "",
                isReachMaxFailEKYC: true,
            },
            () => {
                executeScroll();
            }
        );
    };

    render() {
        let {
            isShowTake,
            isShowDeleteIcon,
            isStartCamera,
            msg_error,
            isShowModalErrorEKYC,
            countFailEKYC,
            isReachMaxFailEKYC,
        } = this.state;
        let _isShowTake = isShowTake;
        let _isShowDeleteIcon = isShowDeleteIcon;
        return (
            <div className="register-step3">
                {!isReachMaxFailEKYC ? (
                    <div className="row_container">
                        <div className="text-xac-thuc">
                            {/* Xác thực qua video */}
                            Xác thực qua ảnh chụp gương mặt
                            {/* <button onClick={event => this.submitCheckInfoByEkyc()}> TEST </button> */}
                        </div>
                        <div className="box-content">
                            <div className="box-img-video">
                                {!isStartCamera ? (
                                    <div>
                                        <img
                                            src={imgAvatar}
                                            alt=""
                                            className="img-avatar"
                                        />

                                        <div className="note text-center">
                                            {/* Cần quay mặt của Quý khách trong video
                                    và giữ CMND trong 30 giây */}
                                            Cần chụp ảnh gương mặt của Quý khách
                                            trong khung phía trên
                                        </div>
                                        <i
                                            className="fas fa-camera img-play-video"
                                            onClick={(event) =>
                                                this.onStartCamera()
                                            }
                                        ></i>
                                        {/* <img src={iconPlayVideo} alt="" className="img-play-video" /> */}
                                    </div>
                                ) : (
                                    <div className="img_content">
                                        <div className="img_center">
                                            {this.renderUploadForm()}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <button
                                // ref={this.uploadBtnRef}
                                id={"show-take-photo-"}
                                className="btn btn-take-photo"
                                style={{ width: "100%", display: "none" }}
                                onClick={(e) => this.show_take_photo(e)}
                                // disabled={imgfiles && imgfiles.length === 0}
                            ></button>
                            <button
                                // ref={this.uploadBtnRef}
                                id={"take-photo-"}
                                className="btn btn-take-photo"
                                style={{ width: "100%", display: "none" }}
                                onClick={(e) => this.get_take_photo(e)}
                                // disabled={imgfiles && imgfiles.length === 0}
                            ></button>

                            <div className="box-button">
                                <div className="">
                                    {!isStartCamera ? (
                                        <a
                                            onClick={(event) =>
                                                this.onStartCamera()
                                            }
                                            className="button-click"
                                        >
                                            {/* <img src={iconCamera} alt="" className="icon-camera" /> */}
                                            {/* Bắt đầu xác thực */}
                                            <i className="fas fa-camera icon-camera">
                                                {" "}
                                                Bắt đầu xác thực
                                            </i>
                                        </a>
                                    ) : _isShowTake ? (
                                        <label
                                            htmlFor={"take-photo-"}
                                            className="button-click label-btn-take-photo"
                                        >
                                            <i className="fas fa-camera">
                                                {" "}
                                                Chụp ảnh
                                            </i>
                                        </label>
                                    ) : (
                                        <label
                                            htmlFor={"show-take-photo-"}
                                            className="button-click label-btn-take-photo"
                                        >
                                            <span className="re-take">
                                                Thực hiện lại
                                            </span>
                                        </label>
                                    )}
                                </div>
                                <div className="txt-content lbl_error">
                                    {/* {msg_error && msg_error} */}
                                </div>
                                <div>
                                    Đừng lo lắng! Quý khách có thể chụp thử
                                    nhiều lần trước khi gửi
                                </div>
                            </div>
                        </div>
                        <div className="box-luu-y">
                            <p>*Lưu ý:</p>
                            {/* Quý khách vui lòng mặc trang phục lịch sự và quay đầy đủ gương mặt (không thiếu phần nào của gương mặt, không che tay...)<br />
                Sau khi video xác thực hoàn thành và gửi đi, màn hình sẽ tự động chuyển sang bước tiếp theo */}
                            Quý khách vui lòng mặc trang phục lịch sự và chụp
                            đầy đủ gương mặt, không che tay.
                            <br />
                            Sau khi ảnh chụp xác thực hoàn thành và gửi đi, màn
                            hình sẽ tự động chuyển sang bước tiếp theo
                        </div>
                        <div className="submit">
                            {!_isShowTake && (
                                <label
                                    htmlFor={"show-take-photo-"}
                                    className="btn button-click label-btn-take-photo"
                                >
                                    <span className="re-take">Hủy</span>
                                </label>
                            )}
                            {!_isShowTake && (
                                <label
                                    onClick={(e) =>
                                        this.submitCheckInfoByEkyc()
                                    }
                                    className="btn gui"
                                >
                                    Gửi
                                </label>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="row_container">
                        <div className="row_body">
                            <div className="note-create-fail">
                                <div className="o-wysiwyg text-center title">
                                    QUÝ KHÁCH VUI LÒNG
                                </div>
                                <ul>
                                    <li>
                                        Tới quầy giao dịch KS Securities để hoàn
                                        thiện thủ tục mở Tài khoản chứng khoán
                                    </li>
                                    <li>
                                        Gửi chuyển phát nhanh hợp đồng mở tài
                                        khoản đã ký và đính kèm chứng thực chữ
                                        ký tới KS Securities được nêu tại email
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                <ModalErrorEKYC
                    isShowModalErrorEKYC={isShowModalErrorEKYC}
                    msg_error={msg_error}
                    takePhotoAgain={this.takePhotoAgain}
                    countFailEKYC={countFailEKYC}
                    confirmMaxFailEKYC={this.confirmMaxFailEKYC}
                />
            </div>
        );
    }
}

class CreateAccountStep3_1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "", // Ho ten
            birthday: "", // Ngay sinh
            idcode: "", // So CMND
            address: "", // Dia chi thuong tru
            iddate: "", // Ngay cap
            idplace: "", // Noi cap
            sex: undefined, //
            ekycSex: "", //giá trị sex set bởi ekyc
            // isValueSexByEKYC: false, //giá trị giới tính đc init bởi EKYC
            isEditInforEKYC: "N", //Y,N: thay đổi thông tin EKYC
            isShowModalEditInforEKYC: false, //modal confirm edit thông tin EKYC
            isShowModalErrorIdcode: false, //modal show error cmnd bị trùng
            msg_error: {
                SEX: undefined,
                IDCODE: undefined,
                DOB: undefined,
                NAME: undefined,
            },
        };
    }

    componentDidMount() {
        let data = this.props.allData.dataStep3;
        console.log("CreateAccountStep3_1.:componentDidMount.:", data);
        this.setState(
            {
                name: data.name, // Ho ten
                birthday: data.birthday, // Ngay sinh
                idcode: data.idcode, // So CMND
                address: data.address, // Dia chi thuong tru
                iddate: data.iddate, // Ngay cap
                idplace: data.idplace, // Noi cap
                sex: this.getSexEKYC(), // giới tính
            },
            () => {
                let { sex } = this.state;
                if (sex) {
                    this.setState({
                        ekycSex: sex,
                        // isValueSexByEKYC: true
                    });
                }
            }
        );
    }

    getSexEKYC = () => {
        let data = this.props.allData.dataStep3;
        if (data.sex) {
            let sexObj = STEP3_SEX.find(
                (item) =>
                    item &&
                    item.name &&
                    item.name.toUpperCase() === data.sex.toUpperCase()
            );
            if (sexObj) {
                return sexObj.value;
            }
        }
        return undefined;
    };

    onChangeAttribute = (att, event) => {
        if (event && event.target) {
            let data = this.props.allData.dataStep3;
            this.state[att] = event.target.value;
            // if (this.state[att] !== data[att]) {
            //     this.state.isEditInforEKYC = 'Y';
            // } else {
            //     this.state.isEditInforEKYC = 'N';
            // }

            this.setState((state) => ({
                ...state,
            }));
        }
    };

    onChangeSex = (event) => {
        if (event && event.target && event.target.value) {
            // let { isValueSexByEKYC, ekycSex } = this.state;
            // if (isValueSexByEKYC === true && event.target.value !== ekycSex) {
            //     //trường hợp căn cước công dân
            //     this.state.isEditInforEKYC = 'Y';
            // }
            // else if (isValueSexByEKYC === true && event.target.value === ekycSex) {
            //     this.state.isEditInforEKYC = 'N';
            // }
            // else {
            //     //trường hợp CMND ko có giới tính, nên sẽ ko tính là thay đổi EKYC
            //     // chỉ set = N khi chưa thay đổi các thông tin còn lại
            //     //do nothing

            // }
            this.state.sex = event.target.value;
            this.setState((state) => ({
                ...state,
            }));
        }
    };

    checkEditInfo = async () => {
        // checkEditInfo.: Check các thông tin Thay đổi so với Ekyc

        // let { isEditInforEKYC } = this.state;
        let self = this;

        let { idcode, sex, name, birthday, msg_error } = this.state;
        console.log("sex", sex);
        msg_error.SEX = undefined;
        msg_error.IDCODE = undefined;
        msg_error.NAME = undefined;
        msg_error.DOB = undefined;

        this.setState({
            msg_error,
            isEditInforEKYC: "N",
        });
        if (idcode === "") {
            msg_error.IDCODE = "Quý khách vui lòng điền số CMTND/CCCD!";
            this.setState({
                msg_error,
            });
            return;
        }
        if (name === "") {
            msg_error.NAME = "Quý khách vui lòng điền họ và tên!";
            this.setState({
                msg_error,
            });
            return;
        }
        if (birthday === "" || birthday === "N/A") {
            msg_error.DOB = "Quý khách vui lòng điền ngày sinh";
            this.setState({
                msg_error,
            });
            return;
        }
        if (!sex) {
            msg_error.SEX = "Quý khách vui lòng lựa chọn giới tính!";
            this.setState({
                msg_error,
            });
            return;
        }
        let isEditInforEKYC = "N";
        let data = this.props.allData.dataStep3;
        let listCheck = [
            "name",
            "sex",
            "birthday",
            "idcode",
            "address",
            "iddate",
            "idplace",
        ];
        listCheck.forEach(function (value, index, array) {
            if (self.state[value] != data[value]) {
                if (
                    value === "sex" &&
                    ((data[value] === "N/A" &&
                        self.state[value] === undefined) ||
                        (data[value] === "Nam" &&
                            self.state[value] === "001") ||
                        (data[value] === "Nữ" && self.state[value] === "002"))
                ) {
                    //nothing happen
                } else {
                    isEditInforEKYC = "Y";
                }
            }
        });

        if (isEditInforEKYC === "Y") {
            this.setState({
                isShowModalEditInforEKYC: true,
                isShowModalErrorIdcode: false,
                isEditInforEKYC: "Y",
            });
        } else {
            this.confirmEditInforEKYC();
        }
    };

    refreshByEkyc = async () => {
        let data = this.props.allData.dataStep3;
        this.setState(
            {
                name: data.name, // Ho ten
                birthday: data.birthday, // Ngay sinh
                idcode: data.idcode, // So CMND
                address: data.address, // Dia chi thuong tru
                iddate: data.iddate, // Ngay cap
                idplace: data.idplace, // Noi cap
                sex: this.getSexEKYC(), //Giới tính
                isEditInforEKYC: "N",
                isShowModalEditInforEKYC: false,
                isShowModalErrorIdcode: false,
            },
            () => {
                let { sex } = this.state;
                if (sex) {
                    this.setState({
                        ekycSex: sex,
                        // isValueSexByEKYC: true
                    });
                }
            }
        );
    };

    isValidIdcode = async (idcode) => {
        return new Promise(async (resolve, reject) => {
            try {
                let data = await userService.validateIdcode({ idcode });
                if (data && data.p_err_code === ERROR_CODE.VALID) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            } catch (e) {
                alert("isValidIdcode.:Error=" + e);
                reject(e);
            }
        });
    };

    confirmEditInforEKYC = async () => {
        let { setStep, setParentStateFromChild } = this.props;
        let {
            name,
            birthday,
            idcode,
            address,
            iddate,
            idplace,
            isEditInforEKYC,
            sex,
        } = this.state;

        let isValidIdcode = await this.isValidIdcode(idcode);
        if (isValidIdcode) {
            await setParentStateFromChild("3_1", {
                name,
                birthday,
                idcode,
                address,
                iddate,
                idplace,
                isEditInforEKYC,
                sex,
            });
            setStep(4);
        } else {
            //show modal thông báo cmnd đã tồn tại
            this.setState({
                isShowModalErrorIdcode: true,
            });
        }
    };

    cancelEditInforEKYC = () => {
        this.setState({
            isShowModalEditInforEKYC: false,
            isShowModalErrorIdcode: false,
            isEditInforEKYC: "Y",
        });
    };

    confirmErrorIdcode = () => {
        let { clearAllData } = this.props;
        this.setState(
            {
                isShowModalEditInforEKYC: false,
                isShowModalErrorIdcode: false,
            },
            async () => {
                await clearAllData();
            }
        );
    };

    render() {
        let {
            name,
            birthday,
            idcode,
            address,
            iddate,
            idplace,
            sex,
            isShowModalEditInforEKYC,
            isShowModalErrorIdcode,
            msg_error,
        } = this.state;
        // let { phoneNumber, email, idInvitor, area } = this.props.allData.dataStep1
        let { phoneNumber, email, area } = this.props.allData.dataStep1;
        let nameArea = "";
        if (area) {
            nameArea = STEP1_AREA.find(
                (item) =>
                    item && item.value === this.props.allData.dataStep1.area
            ).name;
        }

        return (
            <div className="register-step32">
                <div className="row_container">
                    <div className="box-1">
                        <div className="tieu-de-box-1">THÔNG TIN EKYC</div>
                        Thông tin dưới đây được trích từ quá trình xác thực
                        eKYC. Quý khách có thể chỉnh sửa nếu có thông tin chưa
                        chính xác nhưng KSS sẽ phải kiểm tra lại thông tin xác
                        thực.
                        <div className="wrapper-content">
                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">Họ và tên</p>
                                    </div>
                                    <div className="col2">
                                        <input
                                            type="text"
                                            id="ho-va-ten"
                                            name="ho-va-ten"
                                            value={name}
                                            onChange={(event) =>
                                                this.onChangeAttribute(
                                                    "name",
                                                    event
                                                )
                                            }
                                            // onBlur={(event) => this.validateName(event)}
                                            className="input-1"
                                        />
                                    </div>
                                </div>
                                {msg_error.NAME && (
                                    <span className="lbl_error">
                                        {msg_error.NAME}
                                    </span>
                                )}
                            </div>
                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">Ngày sinh</p>
                                    </div>
                                    <div className="col2">
                                        <input
                                            type="text"
                                            id="ngay-sinh"
                                            name="ngay-sinh"
                                            value={birthday}
                                            onChange={(event) =>
                                                this.onChangeAttribute(
                                                    "birthday",
                                                    event
                                                )
                                            }
                                            // onBlur={(event) => this.validateBirthday(event)}
                                            className="input-1"
                                        />
                                    </div>
                                </div>
                                {msg_error.DOB && (
                                    <span className="lbl_error">
                                        {msg_error.DOB}
                                    </span>
                                )}
                            </div>

                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">Giới tính</p>
                                    </div>
                                    <div className="col2 row">
                                        {STEP3_SEX.map((item, index) => {
                                            return (
                                                <div
                                                    className={
                                                        index === 0
                                                            ? "col p-0"
                                                            : "col"
                                                    }
                                                    key={index}
                                                >
                                                    <input
                                                        type="radio"
                                                        name="sex"
                                                        value={item.value}
                                                        id={item.value}
                                                        checked={
                                                            item.value === sex
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(event) =>
                                                            this.onChangeSex(
                                                                event
                                                            )
                                                        }
                                                    />
                                                    <label
                                                        className="o-label sex"
                                                        htmlFor={item.value}
                                                    >
                                                        {item.name}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                {msg_error.SEX && (
                                    <span className="lbl_error">
                                        {msg_error.SEX}
                                    </span>
                                )}
                            </div>

                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">Số CMND/ CCCD</p>
                                    </div>
                                    <div className="col2">
                                        <input
                                            type="text"
                                            id="CMND"
                                            name="CMND"
                                            value={idcode}
                                            onChange={(event) =>
                                                this.onChangeAttribute(
                                                    "idcode",
                                                    event
                                                )
                                            }
                                            // onBlur={(event) => this.validateIdcode(event)}
                                            className="input-1"
                                        />
                                    </div>
                                </div>
                                {msg_error.IDCODE && (
                                    <span className="lbl_error">
                                        {msg_error.IDCODE}
                                    </span>
                                )}
                            </div>
                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">Ngày cấp</p>
                                    </div>
                                    <div className="col2">
                                        <input
                                            type="text"
                                            id="ngay-cap"
                                            name="ngay-cap"
                                            value={iddate}
                                            onChange={(event) =>
                                                this.onChangeAttribute(
                                                    "iddate",
                                                    event
                                                )
                                            }
                                            // onBlur={(event) => this.validateIddate(event)}
                                            className="input-1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">Nơi cấp</p>
                                    </div>
                                    <div className="col2">
                                        <TextareaAutosize
                                            onChange={(event) =>
                                                this.onChangeAttribute(
                                                    "idplace",
                                                    event
                                                )
                                            }
                                            className="input-1 h-100"
                                            title={idplace}
                                            minRows={2}
                                            value={idplace}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">
                                            Địa chỉ thường trú
                                        </p>
                                    </div>
                                    <div className="col2">
                                        <TextareaAutosize
                                            onChange={(event) =>
                                                this.onChangeAttribute(
                                                    "address",
                                                    event
                                                )
                                            }
                                            className="input-1 h-100"
                                            title={address}
                                            minRows={2}
                                            value={address}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-2">
                        <div className="tieu-de-box-1">Thông tin khác</div>
                        <div className="wrapper-content">
                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">Khu vực</p>
                                    </div>
                                    <div className="col2">
                                        <p className="input-2 txt-wrap">
                                            {nameArea}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">
                                            Số điện thoại di động
                                        </p>
                                    </div>
                                    <div className="col2">
                                        <p className="input-2 txt-wrap">
                                            {phoneNumber}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">Email</p>
                                    </div>
                                    <div className="col2">
                                        <p className="input-2 txt-wrap">
                                            {email}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="wrapper-row">
                                <div className="row">
                                    <div className="col1">
                                        <p className="o-label">ID người giới thiệu</p>
                                    </div>
                                    <div className="col2">
                                        <p className="input-2">{idInvitor}</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="btn-desktop">
                        <div className="button-xac-nhan ">
                            <div className="w-75">
                                <button
                                    type="button"
                                    onClick={() => this.refreshByEkyc()}
                                    className="btn-31 delete"
                                >
                                    Khôi phục thông tin EKYC
                                </button>
                                {/* Dùng để khôi phục toàn bộ thông tin bị chỉnh sửa */}
                            </div>
                            <div className="w-25">
                                <button
                                    type="button"
                                    onClick={() => this.checkEditInfo()}
                                    className="btn-31 confirm"
                                >
                                    Tiếp tục
                                </button>
                            </div>
                        </div>
                        <div className="button-xac-nhan noti">
                            <div className="w-75">
                                {/* <span className="txt-noti mgr-15">Dùng để khôi phục toàn bộ thông tin bị chỉnh sửa</span> */}
                            </div>
                            <div className="w-25"></div>
                        </div>
                    </div>
                    <div className="btn-mobile">
                        <div className="button-xac-nhan">
                            <button
                                type="button"
                                onClick={() => this.refreshByEkyc()}
                                className="btn-31 delete"
                            >
                                Khôi phục thông tin EKYC
                            </button>
                            {/* <span className="txt-noti">Dùng để khôi phục toàn bộ thông tin bị chỉnh sửa</span> */}
                        </div>
                        <div className="button-xac-nhan">
                            <button
                                type="button"
                                onClick={() => this.checkEditInfo()}
                                className="btn-31 confirm"
                            >
                                Tiếp tục
                            </button>
                        </div>
                    </div>
                    <div className="txt-noti-2 mgt-0">
                        <div className="box-luu-y">
                            <p>Lưu ý:</p>Quý khách vui lòng lựa chọn nút "Khôi
                            phục thông tin EKYC" nếu muốn khôi phục lại toàn bộ
                            thông tin eKYC như ban đầu.
                        </div>
                        {/* <span> Lưu ý: Quý khách vui lòng lựa chọn nút "Khôi phục thông tin EKYC" nếu muốn khôi phục lại toàn bộ thông tin eKYC như ban đầu.</span> */}
                    </div>
                </div>
                <ModalEditInforEKYC
                    isShowModalEditInforEKYC={isShowModalEditInforEKYC}
                    cancelEditInforEKYC={this.cancelEditInforEKYC}
                    confirmEditInforEKYC={this.confirmEditInforEKYC}
                />

                <ModalInvalidIdcode
                    isShowModalErrorIdcode={isShowModalErrorIdcode}
                    confirmErrorIdcode={this.confirmErrorIdcode}
                />
            </div>
        );
    }
}

class ModalErrorEKYC extends Component {
    render() {
        let {
            isShowModalErrorEKYC,
            msg_error,
            toggleModalErrorEKYC,
            takePhotoAgain,
            countFailEKYC,
            confirmMaxFailEKYC,
        } = this.props;
        return (
            <Modal
                isOpen={isShowModalErrorEKYC}
                toggle={toggleModalErrorEKYC}
                className="modal-error-ekyc"
                centered
            >
                <div className="thong-bao-popup popup-text-black">
                    <h3 className="o-h3 text-gradient">
                        XÁC THỰC EKYC KHÔNG THÀNH CÔNG ({countFailEKYC})
                    </h3>
                    <div className="o-wysiwyg row-upper">
                        {countFailEKYC < NUMBER_FAIL_EKYC ? (
                            <p>
                                Quý khách vui lòng thực hiện lại chụp ảnh xác
                                thực
                            </p>
                        ) : (
                            <p>
                                Quý khách đã thực hiện xác thực EKYC thất bại{" "}
                                {NUMBER_FAIL_EKYC} lần
                            </p>
                        )}
                    </div>
                    <div className="o-wysiwyg row-lower">
                        {msg_error && countFailEKYC < NUMBER_FAIL_EKYC ? (
                            <p>{msg_error}</p>
                        ) : (
                            // : <p>QUÝ KHÁCH VUI LÒNG KIỂM TRA EMAIL ĐỂ HOÀN THIỆN THỦ TỤC ĐĂNG KÝ MỞ TÀI KHOẢN</p>
                            <p>
                                QUÝ KHÁCH VUI LÒNG THỰC HIỆN LẠI HOẶC LIÊN HỆ
                                HOTLINE KSS ĐỂ ĐƯỢC HỖ TRỢ ĐĂNG KÝ MỞ TÀI KHOẢN
                            </p>
                        )}
                    </div>
                    {countFailEKYC < NUMBER_FAIL_EKYC ? (
                        <button
                            type="button"
                            className="btn btn-manhtq btn-orange px-2"
                            onClick={takePhotoAgain}
                        >
                            Thực hiện lại
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-manhtq btn-orange"
                            onClick={confirmMaxFailEKYC}
                        >
                            Xác nhận
                        </button>
                    )}
                </div>
            </Modal>
        );
    }
}

class ModalEditInforEKYC extends Component {
    render() {
        let {
            isShowModalEditInforEKYC,
            toggleModalErrorEKYC,
            cancelEditInforEKYC,
            confirmEditInforEKYC,
        } = this.props;
        return (
            <Modal
                isOpen={isShowModalEditInforEKYC}
                toggle={toggleModalErrorEKYC}
                className="modal-edit-ekyc"
                centered
            >
                <div className="thong-bao-popup popup-text-black">
                    <div className="o-wysiwyg row-upper">
                        <div>Quý khách chắc chắn muốn sửa thông tin này ?</div>
                    </div>
                    <div className="o-wysiwyg row-lower">
                        <p>
                            Lưu ý: Trong trường hợp Quý khách sửa thông tin,
                            chúng tôi sẽ cần thực hiện kiểm tra lại thông tin
                            xác thực.
                        </p>
                    </div>

                    <div className="d-flex">
                        <button
                            type="button"
                            className="btn btn-manhtq btn-grey smaller"
                            onClick={cancelEditInforEKYC}
                        >
                            Quay lại
                        </button>

                        <button
                            type="button"
                            className="btn btn-manhtq btn-orange smaller"
                            onClick={confirmEditInforEKYC}
                        >
                            Sửa thông tin
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

class ModalInvalidIdcode extends Component {
    render() {
        let {
            isShowModalErrorIdcode,
            toggleModalErrorEKYC,
            confirmErrorIdcode,
        } = this.props;
        return (
            <Modal
                isOpen={isShowModalErrorIdcode}
                toggle={toggleModalErrorEKYC}
                className="modal-edit-ekyc"
                centered
            >
                <div className="thong-bao-popup popup-text-black">
                    <div className="o-wysiwyg row-upper"></div>
                    <div className="o-wysiwyg row-lower">
                        <p>
                            Số CMND/CCCD đã tồn tại trong hệ thống, quý khách
                            vui lòng sử dụng CMND/CCCD khác để xác thực.
                        </p>
                    </div>

                    <div className="d-flex">
                        <button
                            type="button"
                            className="btn btn-manhtq btn-orange smaller"
                            onClick={confirmErrorIdcode}
                        >
                            Thực hiện lại
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

// const mapDispatchToProps = dispatch => {
//     return {
//         navigate: (path) => dispatch(push(path))
//     };
// };

// const mapStateToProps = state => {
//     return {
//     };
// };

// CreateAccountStep3_1 = connect(mapStateToProps, mapDispatchToProps)(CreateAccountStep3_1)

export { CreateAccountStep3, CreateAccountStep3_1 };

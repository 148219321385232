import React, { Component } from "react";

import SkipEkyc from "./SkipEkyc";
import './SkipEkycModal.scss';
import DraggableModal from './Modal/DraggableModal';

class SkipEkycModal extends Component {
    shouldComponentUpdate(nextProps) {
        const props = this.props;
        return props.isOpen !== nextProps.isOpen
            || props.toggle !== nextProps.toggle
    }
    submit = () => {
        this.props.submit()
    }
    get_take_photo = () => {
        this.props.get_take_photo()
    }
    render() {
        const { isOpen, toggle, onClose, dataSkipEkycModal } = this.props;
        let titleId = "not-right-modal.title";

        return (
            <DraggableModal
                isOpen={isOpen} toggle={toggle}
                onClose={onClose}
                className='not-right-modal'
                titleId={titleId}
            >
                <div className="body">
                    <SkipEkyc
                        submit={this.submit}
                        get_take_photo={this.get_take_photo}
                        dataSkipEkycModal={dataSkipEkycModal}
                    />
                </div>
            </DraggableModal>
        );
    }
}

export default SkipEkycModal;
import actionTypes from './actionTypes';

export const appStartUpComplete = () => ({
    type: actionTypes.APP_START_UP_COMPLETE
});

export const setContentOfConfirmModal = (contentOfConfirmModal) => ({
    type: actionTypes.SET_CONTENT_OF_CONFIRM_MODAL,
    contentOfConfirmModal: contentOfConfirmModal
});

export const setScreenExportData = (data = { filename: null, data: null }) => {
    return {
        type: actionTypes.SET_SCREEN_EXCEL_EXPORT_DATA,
        data: data
    }
};

export const changeLanguage = (language) => ({
    type: actionTypes.CHANGE_LANGUAGE,
    language: language
});
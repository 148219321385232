import React, { Component } from "react";
import "./CreateAccountStep6.scss";
import tailieuImg from "../../assets/images/register/tai-lieu.png";
import iconCheckbox from "../../assets/images/register/icon-checkbox.png";
import iconBtnXacNhan from "../../assets/images/register/button-xac-nhan.png";
import iconReload from "../../assets/images/register/icon-reload-orange.png";

import { Modal } from "reactstrap";
import { OTPTYPE, TYPE_IMG } from "../../utils/constant";
import moment from "moment";
import userService from "../../services/userService";
import {
    LanguageUtils,
    ERROR_CODE,
    CommonUtils,
    Event,
} from "../../../src/utils";
import config from "../../config";
import CreateAccountStep6Contract from "./CreateAccountStep6Contract";
// import urlKssContract1 from '../../assets/TC_KSS_draft_v5_final.pdf'
import urlKssContract1 from "../../assets/TC_KSS_draft_v5_04.06.2021.pdf";
import { emitter } from "../../clients/emitter";

const KSS_URL = config.app.KSS_URL;
const COUNTDOWN_OTP = config.app.COUNTDOWN_OTP;
class CreateAccountStep6 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowModalOTP: false, //modal nhập otp
            isShowModalOpenAccSuccess: false, //modal hoàn tất, tài khoản đã mở thành công, cần kích hoạt trong email
            isShowModalSignAcc: false, //modal hoàn tất, ký thành công do đã sử thông tin EKYC
            errMessage: "",

            userPassword: "",
            OTP: "",
            isDisableBtnConfirm: false, //disable button xác nhận
            isMaxEnterOTP: false, //max số lần nhập OTP

            count: COUNTDOWN_OTP, //thời gian đếm ngược

            isRegisterComplete: false, //hoàn tất tất cả thủ tục để mở tài khoản
            isCheckContract: true, //check trạng thái button 'Chấp nhận'
            isShowModalContract: false, //modal hợp đồng
            urlIframe: "", //url mở file hợp đồng ở iframe
            urlPDFContract: "",
            isLoading: false,
        };
    }

    async componentDidMount() {
        // console.log('allData at step6', this.props.allData)
        let { executeScroll } = this.props;
        executeScroll();
        let body = CommonUtils.convertBodyDataGetContractPdf(
            this.props.allData
        );
        await this.getPDFContract(body);
    }

    componentDidUpdate = (prevProps, prevState) => {
        let { count, isShowModalOTP } = this.state;
        if (
            isShowModalOTP !== prevState.isShowModalOTP &&
            isShowModalOTP === true
        ) {
            this.createTimer();
        }

        if (count !== prevState.count && count === 0) {
            clearInterval(this.countDown);
            this.setState({
                isDisableBtnConfirm: true,
            });
        }
    };

    getPDFContract = async (body) => {
        await userService
            .generateContractPDF(body)
            .then((data) => {
                if (data && data.forView) {
                    this.setState({
                        urlPDFContract: `${KSS_URL}/pfiles/${data.forView}`,
                    });
                } else {
                    this.setState({
                        urlPDFContract: "",
                    });
                }
            })
            .catch((e) => {
                console.log("generateContractPDF error: ", e);
            });
    };

    createTimer = () => {
        if (this.countDown) {
            clearInterval(this.countDown);
        }

        this.countDown = setInterval(async () => {
            let { count, isLoading } = this.state;
            if (count >= 1) {
                await this.setState({ count: count - 1 });
            }

            if (count <= 2) {
                await this.setState({ isLoading: false });
            } else {
                await this.setState({ isLoading: true });
            }
        }, 1000);
    };

    toggleModalOTP = () => {
        this.setState({
            isShowModalOTP: !this.state.isShowModalOTP,
        });
    };

    toggleModalOpenAccSuccess = () => {
        this.setState({
            isShowModalOpenAccSuccess: !this.state.isShowModalOpenAccSuccess,
        });
    };

    toggleModalSignAcc = () => {
        this.setState({
            isShowModalSignAcc: !this.state.isShowModalSignAcc,
        });
    };

    isValidInputs = (userPassword, OTP) => {
        if (!userPassword) {
            this.setState({
                errMessage: "Quý khách vui lòng nhập mật khẩu",
            });
            return false;
        }

        if (!OTP) {
            this.setState({
                errMessage: "Quý khách vui lòng nhập mã OTP",
            });
            return false;
        }

        //check password compare to previous state

        return true;
    };

    // formatDateOpenAccount(string) {
    //     // return string.replaceAll('-', '/') Lỗi trên iphone 6
    //     return string.replace(/\-/g, '/')
    // }

    onChangeAttribute = (att, event) => {
        if (event && event.target) {
            this.state[att] = event.target.value;
            this.setState((state) => ({
                ...state,
            }));
        }
    };
    validateOpenAccount = (object) => {
        let flag = true;
        for (const property in object) {
            if (object[property] === "" || object[property] === undefined) {
                if (object["sex"] === "" || object["sex"] === undefined) {
                    if (object["idcode"].length === 12) {
                        flag = false;
                        this.setState({
                            errMessage: `${property} not empty!`,
                        });
                        break;
                    } else {
                        //nothing happens
                    }
                } else {
                    flag = false;
                    this.setState({
                        errMessage: `${property} not empty!`,
                    });
                    break;
                }
            }
        }
        return flag;
    };
    //validate OTP
    onClickConfirmOTP = async () => {
        try {
            let { userPassword, OTP } = this.state;
            let { allData, language } = this.props;
            //validate inputs
            let isValidInputs = this.isValidInputs(userPassword, OTP);
            if (!isValidInputs) return;
            let { sessionid } = allData.dataStep1_1;
            if (sessionid && sessionid.id) {
                let otptype = OTPTYPE;
                let refid = sessionid.id;
                let password = userPassword;
                let otp = OTP;
                let opentime = sessionid.txdate + " " + sessionid.txtime;

                //
                let target = allData.dataStep4.target;
                let id_invest = "";
                for (let _tar in target) {
                    if (target[_tar] == true) {
                        id_invest += "Y";
                    } else {
                        id_invest += "N";
                    }
                }
                //
                let experience = allData.dataStep4.experience;
                let id_exp = "";
                for (let _exp in experience) {
                    if (experience[_exp] == true) {
                        id_exp += "Y";
                    } else {
                        id_exp += "N";
                    }
                }

                this.setState({
                    errMessage: "",
                });

                let obj = {
                    refid,
                    fullname: allData.dataStep3_1.name,
                    mobile: allData.dataStep1.phoneNumber,
                    email: allData.dataStep1.email,
                    idtype: allData.dataStep3.id_type,
                    idcode: allData.dataStep3_1.idcode,
                    iddate: allData.dataStep3_1.iddate,
                    idexpired: allData.dataStep3.idexpired,
                    idplace: allData.dataStep3_1.idplace,
                    sex:
                        allData.dataStep3_1.sex === undefined
                            ? "000"
                            : allData.dataStep3_1.sex,
                    birthday: allData.dataStep3_1.birthday,
                    address: allData.dataStep3_1.address,
                    province: allData.dataStep1.area,
                    isprofessional: allData.dataStep4.professional,
                    idcodeurl: allData.dataStep3.dirFile[TYPE_IMG.FRONT],
                    idcode1url: allData.dataStep3.dirFile[TYPE_IMG.BACK],
                    videourl: allData.dataStep3.dirFile[TYPE_IMG.FACE],
                    bankname: allData.dataStep4.nameBank.SHORT_NAME,
                    bankbranch: allData.dataStep4.branchBank,
                    bankacc: allData.dataStep4.numberBank,
                    bankaccname: allData.dataStep4.accountHolder,
                    fullname_ekyc: allData.dataStep3.name,
                    birthday_ekyc: allData.dataStep3.birthday,
                    idcode_ekyc: allData.dataStep3.idcode,
                    iddate_ekyc: allData.dataStep3.iddate,
                    idexpired_ekyc: allData.dataStep3.idexpired,
                    idplace_ekyc: allData.dataStep3.idplace,
                    address_ekyc: allData.dataStep3.address,
                    openpoint: allData.dataStep3.openpoint_ekyc,
                    check_status: allData.dataStep3_1.isEditInforEKYC,
                    id_invest: id_invest,
                    id_risk: allData.dataStep4.risk,
                    id_exp: id_exp,
                    contactaddress: allData.dataStep4.contactAddress,
                    opentime: opentime,
                };
                let check = this.validateOpenAccount(obj);
                if (check === false) return null;
                await userService
                    .validateOTP({
                        otptype,
                        refid,
                        password,
                        otp,
                    })
                    .then(async (data) => {
                        if (data.p_err_code === ERROR_CODE.VALID) {
                            //cần thêm điều kiện check xem khách hàng có thay đổi thông tin EKYC ko (theo step 3) để open đúng modal
                            //validate
                            //tạo tài khoản create account
                            await userService
                                .openAccount(obj)
                                .then((data) => {
                                    if (data.p_err_code === ERROR_CODE.VALID) {
                                        let check =
                                            allData.dataStep3_1.isEditInforEKYC;
                                        if (check === "Y") {
                                            this.setState({
                                                isShowModalOTP: false,
                                                isShowModalSignAcc: true,
                                            });
                                        } else {
                                            this.setState({
                                                isShowModalOTP: false,
                                                isShowModalOpenAccSuccess: true,
                                            });
                                        }

                                        emitter.emit(Event.HIDE_MODAL_TIMEOUT);
                                    } else {
                                        let errMsg =
                                            "[" +
                                            data.p_err_code +
                                            "] Đã có lỗi xảy ra. Vui lòng thử lại sau!";
                                        for (let property in ERROR_CODE) {
                                            // console.log(`${property}: ${object[property]}`);
                                            if (
                                                parseInt(data.p_err_code) ==
                                                parseInt(ERROR_CODE[property])
                                            ) {
                                                errMsg =
                                                    LanguageUtils.getMessageByKey(
                                                        `error-code.${data.p_err_code}`,
                                                        language
                                                    );
                                            }
                                        }
                                        this.setState({
                                            // errMessage: 'Đã có lỗi xảy ra khi mở tài khoản!'
                                            errMessage: errMsg,
                                        });
                                    }
                                })
                                .catch((e) => {
                                    if (
                                        e &&
                                        e.problems &&
                                        e.problems.length > 0
                                    ) {
                                        this.setState({
                                            errMessage:
                                                "[CATCH] Error: " +
                                                e.problems[0],
                                        });
                                    } else {
                                        this.setState({
                                            errMessage:
                                                "[CATCH] Đã có lỗi xảy ra khi mở tài khoản!",
                                        });
                                    }
                                });
                        } else if (
                            data.p_err_code === ERROR_CODE.INVALID_OTP ||
                            data.p_err_code === ERROR_CODE.INVALID_PASSWORD ||
                            data.p_err_code === ERROR_CODE.INVALID_TIMEOUT_OTP
                        ) {
                            this.setState({
                                errMessage: LanguageUtils.getMessageByKey(
                                    `error-code.${data.p_err_code}`,
                                    language
                                ),
                            });
                        } else if (
                            data.p_err_code === ERROR_CODE.INVALID_MAX_OTP
                        ) {
                            this.setState({
                                isMaxEnterOTP: true,
                                errMessage: LanguageUtils.getMessageByKey(
                                    `error-code.${data.p_err_code}`,
                                    language
                                ),
                            });
                        } else {
                            this.setState({
                                // errMessage: 'Đã có lỗi xảy ra khi xác thực OTP!'
                                errMessage:
                                    "[" +
                                    data.p_err_code +
                                    "] Đã có lỗi xảy ra. Vui lòng thử lại sau!",
                            });
                        }
                    })
                    .catch((e) => {
                        this.setState({
                            errMessage:
                                "[CATCH] Đã có lỗi xảy ra khi xác thực OTP!",
                        });

                        alert("onClickConfirmOTP.:validateOTP.:Error=" + e);
                    });
            }
        } catch (e) {
            alert("onClickConfirmOTP.:Error=" + e);
        }
    };

    //gen OTP
    openModalOTP = async () => {
        // let { isCheckContract } = this.state;
        // if (isCheckContract !== true) { return };

        try {
            let { allData, language, clearAllData } = this.props;
            let { sessionid } = allData.dataStep1_1;
            let { isMaxEnterOTP } = this.state;
            this.setState({
                count: COUNTDOWN_OTP,
            });

            if (isMaxEnterOTP) {
                await clearAllData();
                return;
            }

            if (sessionid && sessionid.id) {
                let otptype = OTPTYPE;
                let refid = sessionid.id;
                let mobile = allData.dataStep1.phoneNumber;

                await userService
                    .genOTP({ otptype, mobile, refid })
                    .then((data) => {
                        if (data.p_err_code === ERROR_CODE.VALID) {
                            this.setState({
                                isShowModalOTP: true,
                            });
                        }
                        //không gửi đc mã OTP
                        else if (
                            data.p_err_code === ERROR_CODE.INVALID_SEND_OTP
                        ) {
                            let message = LanguageUtils.getMessageByKey(
                                `error-code.${data.p_err_code}`,
                                language
                            );
                            alert(message);
                        } else {
                            // console.log("gen otp error", data);
                        }
                    })
                    .catch((e) => {
                        alert("openModalOTP.:genOTP.:Error=" + e);
                        this.setState({
                            isShowModalOTP: false,
                        });
                    });
            }
        } catch (e) {
            alert("openModalOTP.:Error=" + e);
        }
    };

    //gửi lại OTPP
    resendOTP = async () => {
        let { allData } = this.props;
        let { sessionid } = allData.dataStep1_1;
        if (sessionid && sessionid.id) {
            let otptype = OTPTYPE;
            let refid = sessionid.id;
            let mobile = allData.dataStep1.phoneNumber;
            await this.setState({
                errMessage: "",
            });
            if (this.state.isLoading === false) {
                await userService
                    .genOTP({ otptype, mobile, refid })
                    .then((data) => {
                        if (data.p_err_code === ERROR_CODE.VALID) {
                            this.setState(
                                {
                                    errMessage: "",
                                    count: COUNTDOWN_OTP,
                                    isDisableBtnConfirm: false,
                                },
                                () => {
                                    this.createTimer();
                                }
                            );
                        } else {
                            this.setState({
                                // errMessage: 'Đã có lỗi xảy ra khi gửi OTP',
                                errMessage:
                                    "[" +
                                    data.p_err_code +
                                    "] Đã có lỗi xảy ra. Vui lòng thử lại sau!",
                            });
                        }
                    })
                    .catch((e) => {
                        this.setState({
                            errMessage: "[CATCH] Đã có lỗi xảy ra khi gửi OTP",
                        });

                        alert("resendOTP.:Error=" + e);
                    });
            }
        }
    };

    closeAllModalWhenComplete = () => {
        this.setState({
            isRegisterComplete: true,
            isShowModalOTP: false,
            isShowModalOpenAccSuccess: false,
            isShowModalSignAcc: false,
        });

        let { executeScroll } = this.props;
        executeScroll();
    };

    onConfirmOpenAccSuccess = () => {
        //todo
        this.closeAllModalWhenComplete();
    };

    onConfirmSignAccount = () => {
        //todo

        this.closeAllModalWhenComplete();
    };

    onClickMaxEnterOTP = () => {
        let { clearAllData } = this.props;
        this.setState(
            {
                isShowModalOTP: false,
            },
            async () => {
                await clearAllData();
            }
        );
    };

    formatTimeToMinute(s) {
        return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
    }

    onClickCheckBox = () => {
        // this.setState({
        //     isCheckContract: !this.state.isCheckContract
        // })
    };

    toggleModalContract = () => {
        this.setState({
            isShowModalContract: !this.state.isShowModalContract,
        });
    };

    closeModalContract = () => {
        this.setState({
            isShowModalContract: false,
        });
    };

    openModalContract = (url) => {
        this.setState({
            urlIframe: url,
            isShowModalContract: true,
        });
    };

    render() {
        let {
            isShowModalOTP,
            isShowModalOpenAccSuccess,
            errMessage,
            userPassword,
            OTP,
            isDisableBtnConfirm,
            count,
            isShowModalSignAcc,
            isRegisterComplete,
            isMaxEnterOTP,
            isCheckContract,
            isShowModalContract,
            urlIframe,
            urlPDFContract,
        } = this.state;
        return (
            <>
                <div className="register-step6">
                    {!isRegisterComplete ? (
                        <div className="row_container">
                            <div className="chu-y">
                                Quý khách cần đọc và ký Hợp đồng Mở tài khoản
                                Giao dịch Chứng khoán, các điều khoản và điều
                                kiện dịch vụ chứng khoán.
                            </div>
                            <div className="box-content">
                                <div className="box-1">
                                    <div className="tieu-de-box-1">
                                        Hợp đồng Mở tài khoản Giao dịch chứng
                                        khoán
                                    </div>

                                    {/* trên mobile */}
                                    <a
                                        href={urlPDFContract}
                                        className="noi-dung-1 background-a contract-mobile"
                                    >
                                        <img src={tailieuImg} />
                                        <span>Hợp đồng mở tài khoản.</span>
                                    </a>
                                    {/* 
                                    <a href="#" onClick={() => this.openModalContract(urlPDFContract)} className="noi-dung-1 background-a contract-mobile">
                                        <img src={tailieuImg} />
                                        <span>Hợp đồng mở tài khoản.</span>
                                    </a> */}

                                    {/* trên laptop */}
                                    <a
                                        href="#"
                                        onClick={() =>
                                            this.openModalContract(
                                                urlPDFContract
                                            )
                                        }
                                        className="noi-dung-1 background-a contract-laptop"
                                    >
                                        <img src={tailieuImg} />
                                        <span>Hợp đồng mở tài khoản.</span>
                                    </a>

                                    {/* trên mobile */}
                                    <a
                                        href={urlKssContract1}
                                        className="noi-dung-11 contract-mobile"
                                    >
                                        <img src={tailieuImg} />
                                        <span>
                                            Các Điều khoản và điều kiện dịch vụ
                                            chứng khoán (Bộ T&C).
                                        </span>
                                    </a>

                                    {/* trên laptop */}
                                    <a
                                        href="#"
                                        onClick={() =>
                                            this.openModalContract(
                                                urlKssContract1
                                            )
                                        }
                                        className="noi-dung-11 contract-laptop"
                                    >
                                        <img src={tailieuImg} />
                                        <span>
                                            Các Điều khoản và điều kiện dịch vụ
                                            chứng khoán (Bộ T&C).
                                        </span>
                                    </a>

                                    <span className="noi-dung-2 no-underline">
                                        {/* <label className="container-checkmark">
                                            <input type="checkbox" checked />
                                            <span className="checkmark"></span>
                                        </label> */}
                                        <p>
                                            Bằng việc chọn “Xác nhận” dưới đây,
                                            tôi xác nhận đã đọc, hiểu & đồng ý
                                            ký Hợp đồng mở tài khoản giao dịch
                                            chứng khoán và chấp nhận Bộ T&C.{" "}
                                        </p>
                                    </span>
                                    <button
                                        className="xac-nhan"
                                        onClick={() => this.openModalOTP()}
                                        // disabled={!isCheckContract}
                                    >
                                        <img src={iconBtnXacNhan} />
                                        <span>XÁC NHẬN</span>
                                    </button>
                                </div>
                                <div className="box-2">
                                    <div className="tieu-de-box-2 Flipped">
                                        TÓM TẮT CÁC ĐIỀU KHOẢN VÀ ĐIỀU KIỆN CỦA
                                        DỊCH VỤ CHỨNG KHOÁN
                                    </div>
                                    <div className="noi-dung Flipped">
                                        <p>
                                            <em>
                                                Nội dung dưới đ&acirc;y (trừ
                                                đoạn in nghi&ecirc;ng
                                                n&agrave;y) chỉ t&oacute;m tắt{" "}
                                                <strong>
                                                    <u>một số điều khoản</u>
                                                </strong>{" "}
                                                của C&Aacute;C ĐIỀU KHOẢN
                                                V&Agrave; ĐIỀU KIỆN CỦA DỊCH VỤ
                                                CHỨNG KHO&Aacute;N &aacute;p
                                                dụng tại KSS. C&aacute;c điều
                                                khoản đ&oacute; được KSS
                                                c&ocirc;ng bố c&ocirc;ng khai
                                                v&agrave; nếu C&aacute;c điều
                                                khoản đ&oacute; thay đổi
                                                th&igrave; phần t&oacute;m tắt
                                                dưới đ&acirc;y sẽ tự động thay
                                                đổi theo.
                                            </em>
                                        </p>
                                        <CreateAccountStep6Contract />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row_container">
                            <div className="row_body">
                                <div className="note-create-success">
                                    <div className="o-wysiwyg">
                                        Chúc mừng quý khách đã đăng ký tài khoản
                                        thành công!
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <ModalOTP
                    isShowModalOTP={isShowModalOTP}
                    toggleModalOTP={this.toggleModalOTP}
                    onChangeAttribute={this.onChangeAttribute}
                    time={this.formatTimeToMinute(count)}
                    // time={count}
                    errMessage={errMessage}
                    userPassword={userPassword}
                    OTP={OTP}
                    onClickConfirmOTP={this.onClickConfirmOTP}
                    resendOTP={this.resendOTP}
                    isDisableBtnConfirm={isDisableBtnConfirm}
                    isMaxEnterOTP={isMaxEnterOTP}
                    onClickMaxEnterOTP={this.onClickMaxEnterOTP}
                />

                <ModalOpenAccountSuccess
                    isShowModalOpenAccSuccess={isShowModalOpenAccSuccess}
                    toggleModalOpenAccSuccess={this.toggleModalOpenAccSuccess}
                    onConfirmOpenAccSuccess={this.onConfirmOpenAccSuccess}
                />

                <ModalSignAccount
                    isShowModalSignAcc={isShowModalSignAcc}
                    toggleModalSignAcc={this.toggleModalSignAcc}
                    onConfirmSignAccount={this.onConfirmSignAccount}
                />

                <ModalContract
                    isShowModalContract={isShowModalContract}
                    toggleModalContract={this.toggleModalContract}
                    closeModalContract={this.closeModalContract}
                    urlIframe={urlIframe}
                />
            </>
        );
    }
}

export default CreateAccountStep6;

class ModalOTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowPassword: false,
        };
    }

    hideShowPassword = (value) => {
        this.setState({
            isShowPassword: value,
        });
    };

    onClickHideShowPassword = (value, type) => {
        let { isShowPassword } = this.state;
        let check = isShowPassword ? value : value * -1;
        isShowPassword = check != 1 ? true : false;
        this.setState({
            isShowPassword,
        });
    };

    render() {
        let {
            isShowModalOTP,
            toggleModalOTP,
            onChangeAttribute,
            time,
            onClickConfirmOTP,
            isDisableBtnConfirm,
            errMessage,
            userPassword,
            OTP,
            resendOTP,
            isMaxEnterOTP,
            onClickMaxEnterOTP,
        } = this.props;

        let { isShowPassword } = this.state;
        return (
            <Modal
                isOpen={isShowModalOTP}
                toggle={toggleModalOTP}
                className="modal-otp"
                centered
            >
                <div className="thong-bao-popup popup-text-black popup-otp">
                    <div className="o-wysiwyg row-upper">
                        <p>
                            Nhập mật khẩu đăng nhập và OTP đã được gửi đến SĐT
                            đăng ký của Quý khách
                        </p>
                    </div>
                    <div className="row-otp">
                        <div className="input-wrapper">
                            <label htmlFor="mat-khau" className="input-label">
                                Nhập mật khẩu
                            </label>
                            <input
                                type={isShowPassword ? "text" : "password"}
                                name="mat-khau"
                                value={userPassword}
                                onChange={(event) =>
                                    onChangeAttribute("userPassword", event)
                                }
                            />
                            <i
                                className={
                                    !isShowPassword
                                        ? "far fa-eye-slash"
                                        : "far fa-eye"
                                }
                                // onMouseOver={() => this.hideShowPassword(true)}
                                // onMouseOut={() => this.hideShowPassword(false)}
                                onClick={() =>
                                    this.onClickHideShowPassword(1, "onClick")
                                }
                            ></i>
                        </div>
                        <div className="input-wrapper input-otp">
                            <label
                                htmlFor="nhap-otp"
                                className="input-label input-label-time"
                            >
                                Nhập OTP
                            </label>
                            <input
                                type="text"
                                name="nhap-otp"
                                value={OTP}
                                onChange={(event) =>
                                    onChangeAttribute("OTP", event)
                                }
                            />

                            <div className="remaining-time">{time}</div>
                        </div>
                        <p className="input-info">{errMessage}</p>
                        {!isMaxEnterOTP && (
                            <div className="resend-otp" onClick={resendOTP}>
                                <img src={iconReload} alt="" />
                                <span>Gửi lại OTP</span>
                            </div>
                        )}
                    </div>
                    {!isMaxEnterOTP ? (
                        <button
                            type="button"
                            className="btn btn-manhtq btn-orange"
                            onClick={onClickConfirmOTP}
                            disabled={isDisableBtnConfirm}
                        >
                            Tiếp tục
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-manhtq btn-orange"
                            onClick={onClickMaxEnterOTP}
                        >
                            Tiếp tục
                        </button>
                    )}
                </div>
            </Modal>
        );
    }
}

class ModalOpenAccountSuccess extends Component {
    render() {
        let {
            isShowModalOpenAccSuccess,
            toggleModalOpenAccSuccess,
            onConfirmOpenAccSuccess,
        } = this.props;
        return (
            <Modal
                isOpen={isShowModalOpenAccSuccess}
                toggle={toggleModalOpenAccSuccess}
                className="modal-open-acc"
                centered
            >
                <div className="thong-bao-popup popup-text-black">
                    <h3 className="o-h3 text-gradient">
                        TÀI KHOẢN ĐÃ ĐƯỢC MỞ THÀNH CÔNG
                    </h3>
                    <div className="o-wysiwyg row-upper">
                        <p>
                            KS Securities đã gửi Hợp đồng Mở tài khoản đến email
                            của Quý khách.
                        </p>
                    </div>
                    <div className="o-wysiwyg row-lower">
                        <p>
                            {" "}
                            Để hoàn tất kích hoạt tài khoản, Quý khách cần click
                            vào đường link chứa trong email được KS Securities
                            gửi tới Quý khách
                        </p>
                    </div>
                    <button
                        type="button"
                        className="btn btn-manhtq btn-orange"
                        onClick={onConfirmOpenAccSuccess}
                    >
                        Xác nhận
                    </button>
                </div>
            </Modal>
        );
    }
}
class ModalSignAccount extends Component {
    render() {
        let { onConfirmSignAccount, isShowModalSignAcc, toggleModalSignAcc } =
            this.props;
        return (
            <Modal
                isOpen={isShowModalSignAcc}
                toggle={toggleModalSignAcc}
                className="modal-sign-acc"
                centered
            >
                <div className="thong-bao-popup popup-text-black row-narrow">
                    <h3 className="o-h3 text-gradient">
                        TÀI KHOẢN ĐÃ ĐƯỢC ký THÀNH CÔNG
                    </h3>
                    <div className="o-wysiwyg row-upper">
                        <p>
                            Do Quý khách đã sửa thông tin xác thực eKYC, KSS sẽ
                            kiểm tra và liên hệ tới Quý khách sớm nhất có thể.
                            Quý khách có thể kiểm tra trạng thái duyệt Hợp đồng
                            bằng cách đăng nhập vào website/app KSS.
                        </p>
                    </div>
                    <div className="o-wysiwyg row-lower">
                        <p>
                            {" "}
                            Để hoàn tất kích hoạt tài khoản, Quý khách cần click
                            vào đường link chứa trong email được KS Securities
                            gửi tới Quý khách
                        </p>
                    </div>
                    <button
                        type="button"
                        className="btn btn-manhtq btn-orange"
                        onClick={onConfirmSignAccount}
                    >
                        Xác nhận
                    </button>
                </div>
            </Modal>
        );
    }
}

class ModalContract extends Component {
    render() {
        let {
            isShowModalContract,
            toggleModalContract,
            closeModalContract,
            urlIframe,
        } = this.props;
        return (
            <Modal
                isOpen={isShowModalContract}
                toggle={toggleModalContract}
                className="modal-contract"
                centered
            >
                <span className="close-btn" onClick={closeModalContract}>
                    <i className="fas fa-times-circle"></i>
                </span>
                <div className="content-modal-contract">
                    <iframe src={urlIframe} width="100%" height="100%"></iframe>
                </div>
            </Modal>
        );
    }
}

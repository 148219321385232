import * as TimeService from '../services/time.service';
import config from '../config';
const IDCODE_EXPIRY_NUM = config.app.IDCODE_EXPIRY_NUM;
class Timer {
	constructor() {
		this.time = new Date().getTime();
	}

	setTime(time) {
		this.time = time;
	}

	getTime() {
		return this.time;
	}

	clock() {
		setInterval(() => {
			this.time += 5000;
		}, 5000);
	}
	toTimestamp(strDate) {
		var datum = Date.parse(strDate);
		return datum / 1000;
	}
	formatDate_YYYYMMDD() {
		var d = new Date(),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = '' + d.getFullYear();
		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;
		return [year, month, day].join('-');
	}
	formatDateToNameFolder() {
		var d = new Date(),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = '' + d.getFullYear();
		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;
		return [year, month, day].join('');
	}
	formatDate(date) {
		var d = date.split('-')
		let day = d[0]
		let month = d[1]
		let year = d[2]
		return [day, month, year].join('/');
	}

	dateToUnix(year, month, day, hour, minute, second) {
		return ((new Date(Date.UTC(year, month - 1, day, hour, minute, second))).getTime() / 1000.0);
	}
	getExpiry(txdate) {
		let _txdate = txdate.split('/')
		let year = parseInt(_txdate[2]) - IDCODE_EXPIRY_NUM
		let d = [_txdate[0], _txdate[1], year].join('/')
		return d
	}
	checkExpiry(date, txdate) {
		// date //  Định dạng dd-mm-yyyy hoặc d-m-yyyy do CMT nhiều dạng format 
		// txdate //  Định dạng dd/mm/yyyy
		let _date = date.split('-')
		if (_date[0].length < 2)
			_date[0] = '0' + _date[0];
		if (_date[1].length < 2)
			_date[1] = '0' + _date[1];
		let num = '' + _date[2] + _date[1] + _date[0]
		let _txdate = txdate.split('/')
		let txnum = '' + _txdate[2] + _txdate[1] + _txdate[0]
		if (parseInt(num) >= parseInt(txnum)) {
			return true
		}
		return false
	}
	formatDateEkyc(date) {
		if (date) {
			let reg = /^(0?[1-9]|[12][0-9]|3[01])[-](0?[1-9]|1[012])[-]\d{4}$/;
			let isValid = reg.test(date);
			if (!isValid) {
				return 'N/A'
			}
			let d = date.split('-')
			if (d[0].length < 2)
				d[0] = '0' + d[0];
			if (d[1].length < 2) {
				d[1] = '0' + d[1];
			}
			let day = d[0]
			let month = d[1]
			let year = d[2]
			return [day, month, year].join('/');
		}
		return 'N/A'
	}
}

let _timer = new Timer();

TimeService.init(currentTime => {
	_timer.setTime(currentTime);
	_timer.clock();
});

export const timer = _timer;

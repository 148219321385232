import * as queryString from 'query-string';
import * as Promise from 'bluebird';
import _ from 'lodash';
import moment from 'moment';
import axios from '../axios';
// import axios2 from 'axios';
import config from '../config';

const publicIp = require('public-ip');
// const MacAddress = require('get-mac-address');
// Haki.: tạm config tham số




let IP_PUBLIC_ADDRESS
let MAC_ADDRESS
//  = getMAC('eth0')
const ekycServer = {
    async getBase64FromFile(file) {
        return new Promise((resolve, reject) => {
            // const reader = new FileReader();
            // reader.onload = (event) => {
            //     console.log('getBase64FromFile.:', event)
            //     resolve(event.target.result)
            // };
            // reader.readAsArrayBuffe(file);


            var fr = new FileReader();
            fr.onload = function () {
                var data = fr.result;
                var array = new Int8Array(data);
                // output.value = JSON.stringify(array, null, '  ');
                console.log('getBase64FromFile.:', data)
                console.log('getBase64FromFile.:', JSON.stringify(array, null, '  '))
                resolve(data)
            };
            fr.readAsArrayBuffer(file);
        })
    },

    async getInfoClient() {
        IP_PUBLIC_ADDRESS = await publicIp.v4()
        console.log('getMacAddress.:IP_PUBLIC_ADDRESS==', IP_PUBLIC_ADDRESS)
        // console.log('getMacAddress.:MacAddress==', MacAddress)
    },
    // sendFileToServer(objFile) {
    //     let url = `/userdata/uploadfile`;
    //     // let url = `http://localhost:1341/uploadfile`;
    //     const header = {
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //         }
    //     }
    //     var form = new FormData();
    //     form.append('foldername', objFile.folder_name);
    //     form.append('filename', objFile.file_name);
    //     form.append('file', objFile.file);
    //     return axios.post(url, form, header);
    // },
    // Haki.: 1. get info img by Ekyc
    getInfoImgByEkyc(objFile) {
        let url = `/get-info-img`;
        var form = new FormData();
        form.append('requestid', objFile.requestid);
        form.append('foldername', objFile.foldername);
        form.append('filename', objFile.filename);
        form.append('file', objFile.file);
        return axios.post(url, form);
    },

    checkInfoFaceByEkyc(objFile) {
        let url = `/get-check-face`;
        var form = new FormData();
        form.append('requestid', objFile.requestid);
        form.append('foldername', objFile.foldername);
        form.append('filename', objFile.filename);
        form.append('filenamefront', objFile.filenamefront);
        form.append('file', objFile.file);
        return axios.post(url, form);
    }
};

export default ekycServer;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Footer.scss';
import logoFooter from "../../../assets/images/logo_footer.png";
import iconAdress from "../../../assets/images/register/footer/icon-adress.png";
import iconEmail from "../../../assets/images/register/footer/icon-email.png";
import iconPhone from "../../../assets/images/register/footer/icon-phone.png";
import iconFacebook from "../../../assets/images/register/footer/facebook.png";
import iconYoutube from "../../../assets/images/register/footer/youtube.png";
import iphone from "../../../assets/images/register/footer/phone.png";
import iEmail from "../../../assets/images/register/footer/email.png";


class Footer extends Component {
    render() {

        return (
            <div className="footer footer-desktop" id="footer">
                <div className="top-footer">
                    <div className="row-container">
                        <img src={logoFooter} alt="" className="logo" />
                        {/* <img src={logoFooter} width="213px" height="" alt="" className="logo" /> */}
                        <div className="title-footer">
                            <h4 className="title-row">Công ty cổ phần Chứng khoán KS</h4>
                        </div>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row-container">
                        <div className="box-footer1">
                            <div className="title">
                                Thông tin liên hệ
				            </div>
                            <div className="box-item">
                                <img src={iconAdress} alt="" className="icon" />
                                <div className="text">
                                Tầng 10, TNR Tower, 180-192 Nguyễn Công Trứ, Phường Nguyễn Thái Bình, Quận 1, Thành phố Hồ Chí Minh
					            </div>
                            </div>
                            <div className="box-item box-23" style={{ 'paddingRight': '20px' }}>
                                <img src={iconEmail} alt="" className="icon" />
                                <a className="text" target="_blank" href="mailto:cskh@kss.com.vn">
                                cskh@kss.com.vn
                                    
						        </a>
                            </div>
                            <div className="box-item box-23">
                                <img src={iconPhone} alt="" className="icon" />
                                <a className="text" target="_blank" href="tel:19001000" >
                                1900 1000
					</a>
                            </div>
                            <div className="box-connect">
                                <div className="text">
                                    Kết nối với chúng tôi:
					</div>
                                <div className="list-mxh">
                                    <a href="#">
                                        <img src={iconFacebook} alt="" />
                                    </a>
                                    <a href="#">
                                        <img src={iconYoutube} alt="" />
                                    </a>
                                    <a href="#">
                                        <img src={iphone} alt="" />
                                    </a>
                                    <a href="#">
                                        <img src={iEmail} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="box-footer2">
                            <div className="row">
                                <div className="box-menu-footer col-xl-3 col-lg-6 col-6">
                                    <div className="title">
                                        Về chúng tôi
						            </div>
                                    <ul>
                                        <li>
                                            <a href="#" className="text">
                                                Về KSS
                                             </a>
                                        </li>
                                        <li>
                                            <a href="#" className="text">
                                                Đối tác
                                             </a>
                                        </li>
                                        <li>
                                            <a href="#" className="text">
                                                Cơ hội nghề nghiệp
                                             </a>
                                        </li>
                                        <li>
                                            <a href="#" className="text">
                                                Công bố thông tin
                                             </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="box-menu-footer col-xl-3 col-lg-6 col-6">
                                    <div className="title">
                                        Dịch vụ
						            </div>
                                    <ul>
                                        <li>
                                            <a href="#" className="text">
                                                Khách hàng cá nhân
                                              </a>
                                        </li>
                                        <li>
                                            <a href="#" className="text">
                                                Khách hàng doanh nghiệp
                                              </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="box-menu-footer col-xl-3 col-lg-6 col-6">
                                    <div className="title">
                                        Sản phẩm
						            </div>
                                    <ul>
                                        <li>
                                            <a href="#" className="text">
                                                KS Bond
                                             </a>
                                        </li>
                                        <li>
                                            <a href="#" className="text">
                                                KS Stock
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="text">
                                                Đầu tư PropTech
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" className="text">
                                                Chứng khoán phái sinh
                                             </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="box-menu-footer col-xl-3 col-lg-6 col-6">
                                    <div className="title">
                                        Trung tâm hỗ trợ
						            </div>
                                    <ul>
                                        <li>
                                            <a href="#" className="text">
                                                Hướng dẫn mở tài khoản
                                             </a>
                                        </li>
                                        <li>
                                            <a href="#" className="text">
                                                Hướng dẫn giao dịch trái phiếu
                                              </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="row-container">
                        <div className="text-copy">
                        Copyright 2021 Công ty cổ phần Chứng khoán KS - All Rights Reserved
			</div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);

export default {
    api: {
        API_USERDATA_URL: "https://apiuat.kss.com.vn/uat/ekyc-userdata", // = userdata
        //API_USERDATA_URL: "/apikss", // = userdata

        //API_USERDATA_URL: "http://localhost:1341", // = userdata
        // API_USERDATA_URL: "http://192.168.1.152:1341/", // = userdata
        // ROUTER_BASE_NAME: null,
    },
    app: {
        /**
         * The base URL for all locations. If your app is served from a sub-directory on your server, you'll want to set
         * this to the sub-directory. A properly formatted basename should have a leading slash, but no trailing slash.
         */
        ROUTER_BASE_NAME: null,
        //ROUTER_BASE_NAME: 'webonline/',
        MONITOR_INTERVAL: 30, // 30 seconds

        //DIR_UPLOAD_FILE: 'D:\\1.Mywork\\11.KSS\\uploads\\', // Window Local
        DIR_UPLOAD_FILE: "createaccount/", // Linux/Ubuntu
        // IS_WIN_SERVER: true, // true là Window, false là Ubuntu
        IS_WIN_SERVER: false, // true là Window, false là Ubuntu
        MAX_SIZE_FILE_UPLOAD: 5 * 1024 * 1024, //bytes
        LINK_WEB_TRADING:
            "https://trade.kss.com.vn/Account/Login?returnUrl=https%3A%2F%2Ftrade.kss.com.vn%2F",
        LINK_WEB_ONLINE: "https://ekyc.kss.com.vn/account-register",
        DEFAULT_MIN_OPNPOINT: 70, // Điểm OPNPOINT tối thiếu để pass qua giao diện mở tài khoản, hien khong dung nua
        DEFAULT_MAX_FAKE_SCOPE: 0.63,
        NUMBER_FAIL_EKYC: 3, // số lần fail tối đa xác thực EKYC
        ENABLE_CLICK_TITLE_STEP: false, // enable click vào title chuyển step
        COUNTDOWN_OTP: 90,
        IDCODE_EXPIRY_NUM: 15,
        KSS_URL: "https://ekyc.kss.com.vn",
        TIME_OUT_LINK_CREATE_ACCOUNT: 30, // phút
        ENABLE_CHECK_PASSWORD_REG_ONLINE: true, // enable check password khi gọi api reg_online
    },
};

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import userService from '../../services/userService';
import { withRouter } from 'react-router-dom';
import './ActiveEmail.scss';
import { ERROR_CODE } from "../../utils/constant";

class ActiveEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            isError: false,
            isLoading: true,
        }


    }

    async componentDidMount() {
        let id = new URLSearchParams(window.location.search).get('id');

        if (id) {
            await this.checkActiveEmail(id).then(status => {
                this.setState({
                    isError: status,
                    isLoading: false,
                })
            });

        } else {
            this.setState({
                isError: true
            })
        }
    }

    checkActiveEmail = (id) => {
        return new Promise(async (resolve, reject) => {
            try {
                await userService.activeEmail({ id }).then((data) => {
                    if (data.p_err_code === ERROR_CODE.VALID) {
                        resolve(false)
                    } else {
                        resolve(true)
                    }
                }).catch(e => {
                    resolve(true)
                })
            } catch (e) {
                alert('checkActiveEmail.:Error=' + e)
                reject(e)
            }
        })
    }

    render() {
        let { isError, isLoading } = this.state;

        return (
            <>
                {isLoading ? <div className=" row col-10 mx-auto text-center" style={{ color: 'black' }}> Đang tải dữ liệu ... </div>
                    :
                    <>  {isError ?
                        <div className="active-error container">
                            <div className="row">
                                <div className="col-10 mx-auto">
                                    <div className="title">Xác thực Email đăng ký tài khoản trực tuyến</div>
                                    <div className="content">
                                        <div className="child info">Xác thực email thất bại</div>
                                        <div className="child second">Quý khách vui lòng Liên hệ tổng đài <a href="tel:19001000">1900 1000</a> để được hỗ trợ nhanh, hoặc
                                        gửi email tới địa chỉ
                                         <a className="text" target="_blank" href="mailto:cskh@kss.com.vn">
                                                <span> cskh@kss.com.vn</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="active-success container">
                            <div className="row">
                                <div className="col-10 mx-auto">
                                    <div className="title">Xác thực Email đăng ký tài khoản trực tuyến</div>
                                    <div className="content">
                                        <div className="child info pb-5">Xác thực email thành công</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    </>
                }
            </>

        )
    }
}


export default withRouter(ActiveEmail);
import React, { Component, Fragment } from "react";
import instance from "../../axios";
import axios from "axios";

import { FormattedMessage } from "react-intl";
import "./CreateAccountStep1.scss";
import "./CreateAccountStep1Confirm.scss";
import iconClose from "../../assets/images/register/dang-ki-thong-tin/icon-close.png";
import ClientCaptcha from "react-client-captcha";
import _ from "lodash";
import userService from "../../services/userService";
import { STEP1_AREA, LanguageUtils, ERROR_CODE } from "../../utils";
import moment from "moment";

import { timer } from "../../clients/timer";
import validator from "validator";

const REGE_EMAIL =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REGE_PHONE = /^0\d{9}$/;

const defaultOpenPointEKYC = 200; // Mặc định không cho pass Ekyc nếu API getOpenPointEKYC bị lỗi

class CreateAccountStep1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            area: "",
            fullName: "",
            phoneNumber: "",
            email: "",
            idInvitor: "",
            nameInvitor: "",
            note: "",
            captchaCode: "", // capcha được gen ra từ thư viện
            clientCode: "", //mã capcha người dùng nhập vào input

            errMessage: {},
            isInvalidInput: {
                isInvalidFullName: false,
                isInvalidPhoneNumber: false,
                isInvalidIdInvitor: false,
                isInvalidCaptchaCode: false,
                isInvalidEmail: false,
            },
            key: 0, //key component captcha
        };
    }

    componentDidMount() {
        let { allData } = this.props;
        if (allData && allData.dataStep1 && !_.isEmpty(allData.dataStep1)) {
            this.setState({
                area: allData.dataStep1.area,
                fullName: allData.dataStep1.fullName,
                phoneNumber: allData.dataStep1.phoneNumber,
                email: allData.dataStep1.email,
                idInvitor: allData.dataStep1.idInvitor,
                nameInvitor: allData.dataStep1.nameInvitor,
                note: allData.dataStep1.note,
            });
        } else {
            this.setState({
                area: STEP1_AREA[0].value,
            });
        }
    }

    onChangeAttribute = (att, event) => {
        if (event && event.target) {
            this.state[att] = event.target.value;
            this.setState((state) => ({
                ...state,
            }));
        }
    };

    setCode = (captchaCode) => {
        this.setState({ captchaCode });
    };

    validatePhoneNumber = (event, phoneNumber) => {
        let isCheck = true;
        if (
            (event && event.target) ||
            (phoneNumber != undefined && phoneNumber != "")
        ) {
            let _phoneNumber = phoneNumber ? phoneNumber : event.target.value;
            if (_phoneNumber != undefined && _phoneNumber != "") {
                let re = REGE_PHONE; //start with 0, max 10 or 11 letters
                let isValid = re.test(_phoneNumber);
                if (isValid) {
                    this.state.errMessage.errPhoneNumber = "";
                    this.state.isInvalidInput.isInvalidPhoneNumber = false;
                } else {
                    this.state.errMessage.errPhoneNumber =
                        "Số điện thoại di động không đúng định dạng!";
                    this.state.isInvalidInput.isInvalidPhoneNumber = true;
                    isCheck = false;
                }
            } else {
                this.state.errMessage.errPhoneNumber =
                    "Số điện thoại di động không được để trống!";
                this.state.isInvalidInput.isInvalidPhoneNumber = true;
                isCheck = false;
            }
        } else {
            this.state.errMessage.errPhoneNumber =
                "Số điện thoại di động không được để trống!";
            this.state.isInvalidInput.isInvalidPhoneNumber = true;
            isCheck = false;
        }
        this.setState((state) => ({
            ...state,
        }));
        return isCheck;
    };

    validateEmail = (event, email) => {
        let isCheck = true;
        if ((event && event.target) || (email != undefined && email != "")) {
            // let re = REGE_EMAIL;
            // let isValid = re.test(event.target.value);
            let _email = email ? email : event.target.value;
            if (_email != undefined && _email != "") {
                let re = REGE_EMAIL;
                let isValid = re.test(_email);
                if (isValid === true) {
                    this.state.errMessage.errEmail = "";
                    this.state.isInvalidInput.isInvalidEmail = false;
                } else {
                    this.state.errMessage.errEmail =
                        "Email không đúng định dạng!";
                    this.state.isInvalidInput.isInvalidEmail = true;
                    isCheck = false;
                }
            } else {
                this.state.errMessage.errEmail = "Email không được để trống!";
                this.state.isInvalidInput.isInvalidEmail = true;
                isCheck = false;
            }
        } else {
            this.state.errMessage.errEmail = "Email không được để trống!";
            this.state.isInvalidInput.isInvalidEmail = true;
            isCheck = false;
        }
        this.setState((state) => ({
            ...state,
        }));
        return isCheck;
    };

    isValidAllInputs = async (
        fullName,
        phoneNumber,
        idInvitor,
        clientCode,
        captchaCode,
        email
    ) => {
        let isCheck = true;
        this.state.errMessage = {};
        this.state.isInvalidInput = {};

        if (!fullName) {
            this.state.errMessage.errFullName =
                "Họ và tên không được để trống!";
            this.state.isInvalidInput.isInvalidFullName = true;
            isCheck = false;
        }
        let isCheckPhoneNumber = await this.validatePhoneNumber(
            "",
            phoneNumber
        );
        if (!isCheckPhoneNumber) {
            isCheck = false;
        }
        let isCheckEmail = await this.validateEmail("", email);
        if (!isCheckEmail) {
            isCheck = false;
        }

        if (clientCode !== captchaCode) {
            this.state.errMessage.errCaptchaCode =
                "Mã xác nhận không chính xác!";
            this.state.isInvalidInput.isInvalidCaptchaCode = true;
            if (this.recaptchaInstance) {
                this.state.key = this.state.key + 1; //rerender captcha
            }
            isCheck = false;
        }
        await this.setState((state) => ({
            ...state,
        }));
        return isCheck;
    };

    onBlurNotEmptyName = (event) => {
        if (event && event.target) {
            this.state.errMessage.errFullName = "";
            this.state.isInvalidInput.isInvalidFullName = false;
            this.setState((state) => ({
                ...state,
            }));
        }
    };

    onBlurNotEmptyIdInvitor = (event) => {
        if (event && event.target) {
            this.state.errMessage.errIdInvitor = "";
            this.state.isInvalidInput.isInvalidIdInvitor = false;
            this.setState((state) => ({
                ...state,
            }));
        }
    };

    nextToStep1Confirm = async () => {
        let {
            area,
            fullName,
            phoneNumber,
            email,
            idInvitor,
            nameInvitor,
            note,
            clientCode,
            captchaCode,
        } = this.state;

        // let { setStep, setParentStateFromChild, language, isSystemError } = this.props;

        let { setStep, setParentStateFromChild, language } = this.props;
        // if (isSystemError) {
        //     alert('Đã có lỗi xảy ra. Quý khách vui lòng thử lại sau!');
        //     return;
        // }

        // validate trên giao diện
        let isValidAllInputs = await this.isValidAllInputs(
            fullName,
            phoneNumber,
            idInvitor,
            clientCode,
            captchaCode,
            email
        );
        if (!isValidAllInputs) return;

        this.setState({
            errMessage: {},
            isInvalidInput: {},
        });
        //validatePhone
        let isCheckMobile = false;

        await userService
            .validatePhone({
                mobile: phoneNumber,
            })
            .then((data) => {
                if (data.p_err_code === ERROR_CODE.VALID) {
                    setParentStateFromChild(1, {
                        area,
                        fullName,
                        phoneNumber,
                        email,
                        idInvitor,
                        nameInvitor,
                        note,
                    });
                    // setStep(1.1);
                    isCheckMobile = true;
                } else if (data.p_err_code === ERROR_CODE.INVALID_PHONE) {
                    this.state.errMessage.errPhoneNumber =
                        LanguageUtils.getMessageByKey(
                            `error-code.${data.p_err_code}`,
                            language
                        );
                    this.state.isInvalidInput.isInvalidPhoneNumber = true;
                } else {
                    this.state.errMessage.errSubmitBtn =
                        "[" +
                        data.p_err_code +
                        "] Đã có lỗi xảy ra. Vui lòng thử lại sau!";
                }
            })
            .catch((e) => {
                this.state.errMessage.errSubmitBtn =
                    "[CATCH] Đã có lỗi xảy ra. Vui lòng thử lại sau!";
                this.setState((state) => ({
                    ...state,
                }));
                console.log("Error api validatePhone: ", e);
                alert("nextToStep1Confirm.:validatePhone.:Error=" + e);
            });

        //validateEmail
        let isCheckEmail = false;
        await userService
            .validateEmail({
                email: email,
            })
            .then((data) => {
                if (data.p_err_code === ERROR_CODE.VALID) {
                    setParentStateFromChild(1, {
                        area,
                        fullName,
                        phoneNumber,
                        email,
                        idInvitor,
                        nameInvitor,
                        note,
                    });
                    isCheckEmail = true;
                } else if (data.p_err_code === ERROR_CODE.INVALID_EMAIL) {
                    this.state.errMessage.errEmail =
                        LanguageUtils.getMessageByKey(
                            `error-code.${data.p_err_code}`,
                            language
                        );
                    this.state.isInvalidInput.isInvalidEmail = true;
                } else if (
                    data.p_err_code === ERROR_CODE.INVALID_FORMAT_EMAIL
                ) {
                    this.state.errMessage.errEmail =
                        LanguageUtils.getMessageByKey(
                            `error-code.${data.p_err_code}`,
                            language
                        );
                    this.state.isInvalidInput.isInvalidEmail = true;
                } else {
                    this.state.errMessage.errSubmitBtn =
                        "[" +
                        data.p_err_code +
                        "] Đã có lỗi xảy ra. Vui lòng thử lại sau!";
                }
            })
            .catch((e) => {
                this.state.errMessage.errSubmitBtn =
                    "[CATCH] Đã có lỗi xảy ra. Vui lòng thử lại sau!";
                this.setState((state) => ({
                    ...state,
                }));
                console.log("Error api validateEmail: ", e);
                alert("nextToStep1Confirm.:validateEmail.:Error=" + e);
            });
        this.setState((state) => ({
            ...state,
        }));
        if (isCheckMobile && isCheckEmail) {
            setStep(1.1);
        }
    };

    render() {
        let {
            area,
            fullName,
            phoneNumber,
            email,
            idInvitor,
            nameInvitor,
            note,
            clientCode,
            errMessage,
            isInvalidInput,
            key,
        } = this.state;

        return (
            <div className="register-step1">
                <div className="row_container">
                    <div className="box-tieude">
                        <h2 className="title-row">
                            Quý khách vui lòng nhập thông tin dưới đây{" "}
                        </h2>
                        <div className="box-thong-tin">
                            <p className="lien-he">
                                Liên hệ tổng đài{" "}
                                <a href="tel:19001000">1900 1000</a> để được hỗ
                                trợ nhanh
                            </p>
                        </div>
                    </div>

                    <div className="box-luu-y">
                        <p>Lưu ý:</p> <br />
                        Trước khi mở tài khoản, Quý khách vui lòng: <br />
                        1. Chuẩn bị sẵn CMND/CCCD; <br />
                        2. Sử dụng thiết bị có camera phía trước hoặc webcam để
                        xác thực (như điện thoại, laptop hay máy tính bảng);{" "}
                        <br />
                        3. Sử dụng trình duyệt khuyến nghị: Chrome; và
                        <br />
                        4. Với mỗi thông tin nhập trong quá trình mở tài khoản,
                        kiểm tra trước khi chuyển sang bước kế tiếp.
                    </div>

                    <div className="row_body">
                        <div className="row-wrapper">
                            <div className="row">
                                <div className="col-5">
                                    <p className="o-label">Khu vực </p>
                                </div>
                                <div className="col-7">
                                    <div className="row">
                                        {STEP1_AREA.map((item, index) => {
                                            if (item.value == "HN") {
                                                return (
                                                    <div
                                                        className="col"
                                                        style={{
                                                            minWidth: "80px",
                                                        }}
                                                        key={index}
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="area"
                                                            value={item.value}
                                                            id={item.value}
                                                            checked={
                                                                item.value ===
                                                                area
                                                                    ? true
                                                                    : false
                                                            }
                                                            // defaultChecked={(index === 0 && item !== area) ? true : false}
                                                            onChange={(event) =>
                                                                this.onChangeAttribute(
                                                                    "area",
                                                                    event
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="o-label-1"
                                                            htmlFor={item.value}
                                                        >
                                                            {item.name}
                                                        </label>
                                                    </div>
                                                );
                                            }
                                            if (item.value == "HC") {
                                                return (
                                                    <div
                                                        className="col"
                                                        style={{
                                                            minWidth: "150px",
                                                        }}
                                                        key={index}
                                                    >
                                                        <input
                                                            type="radio"
                                                            name="area"
                                                            value={item.value}
                                                            id={item.value}
                                                            checked={
                                                                item.value ===
                                                                area
                                                                    ? true
                                                                    : false
                                                            }
                                                            // defaultChecked={(index === 0 && item !== area) ? true : false}
                                                            onChange={(event) =>
                                                                this.onChangeAttribute(
                                                                    "area",
                                                                    event
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            className="o-label-1"
                                                            htmlFor={item.value}
                                                        >
                                                            {item.name}
                                                        </label>
                                                    </div>
                                                );
                                            }
                                        })}
                                        <div className="col dpn"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row-wrapper">
                            <div className="row">
                                <div className="col-5">
                                    <p className="o-label">Họ và tên</p>
                                </div>
                                <div className="col-7">
                                    <input
                                        type="text"
                                        id="ho-va-ten"
                                        name="ho-va-ten"
                                        value={fullName}
                                        className={
                                            isInvalidInput.isInvalidFullName
                                                ? "input-1 error"
                                                : "input-1 "
                                        }
                                        onChange={(event) =>
                                            this.onChangeAttribute(
                                                "fullName",
                                                event
                                            )
                                        }
                                        placeholder="Nhập có dấu, giống trên CMND/CCCD"
                                        // onBlur={(event) => this.onBlurNotEmptyName(event)}
                                    />
                                    {isInvalidInput.isInvalidFullName && (
                                        <span className="text-err">
                                            {errMessage.errFullName}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row-wrapper">
                            <div className="row">
                                <div className="col-5">
                                    <p className="o-label">
                                        Số điện thoại di động
                                    </p>
                                </div>
                                <div className="col-7">
                                    <input
                                        type="text"
                                        id="so-dien-thoai"
                                        name="so-dien-thoai"
                                        value={phoneNumber}
                                        className={
                                            isInvalidInput.isInvalidPhoneNumber
                                                ? "input-1 error"
                                                : "input-1"
                                        }
                                        onChange={(event) =>
                                            this.onChangeAttribute(
                                                "phoneNumber",
                                                event
                                            )
                                        }
                                        // onBlur={(event) => this.validatePhoneNumber(event)}
                                        placeholder="VD: 0912345678"
                                    />
                                    {isInvalidInput.isInvalidPhoneNumber && (
                                        <span className="text-err">
                                            {errMessage.errPhoneNumber}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row-wrapper">
                            <div className="row">
                                <div className="col-5">
                                    <p className="o-label">Email</p>
                                </div>
                                <div className="col-7">
                                    <input
                                        type="email"
                                        id="ho-va-ten"
                                        name="ho-va-ten"
                                        value={email}
                                        className={
                                            isInvalidInput.isInvalidEmail
                                                ? "input-1 error"
                                                : "input-1"
                                        }
                                        onChange={(event) =>
                                            this.onChangeAttribute(
                                                "email",
                                                event
                                            )
                                        }
                                        // onBlur={(event) => this.validateEmail(event)}
                                        placeholder="VD: khachhang@gmail.com"
                                    />
                                    {isInvalidInput.isInvalidEmail && (
                                        <span className="text-err">
                                            {errMessage.errEmail}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* Tạm ẩn vì chưa có luồng này */}
                        {/* <div className="row-wrapper">
                            <div className="row">
                                <div className="col-5">
                                    <p className="o-label">ID người giới thiệu</p>
                                </div>
                                <div className="col-7">
                                    <input type="text" id="nguoi-gioi-thieu" name="nguoi-gioi-thieu"
                                        value={idInvitor} className={isInvalidInput.isInvalidIdInvitor ? "input-1 error" : " input-1"}
                                        onChange={(event) => this.onChangeAttribute('idInvitor', event)}
                                        placeholder="Nhập 0 nếu không có người giới thiệu"
                                        onBlur={(event) => this.onBlurNotEmptyIdInvitor(event)}
                                    />
                                    {isInvalidInput.isInvalidIdInvitor &&
                                        <span className="text-err">{errMessage.errIdInvitor}</span>
                                    }
                                </div>
                            </div>
                        </div> */}
                        {/* <div className="row-wrapper">
                            <div className="row">
                                <div className="col-5">
                                    <p className="o-label">Tên người giới thiệu</p>
                                </div>
                                <div className="col-7">
                                    <input type="text" id="ten-nguoi-gioi-thieu" name="ten-nguoi-gioi-thieu"
                                        value={nameInvitor} className="input-1"
                                        onChange={(event) => this.onChangeAttribute('nameInvitor', event)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row-wrapper">
                            <div className="row">
                                <div className="col-5">
                                    <p className="o-label">Ghi chú</p>
                                </div>
                                <div className="col-7">
                                    <input type="text" id="ghi-chu" name="ghi-chu" value={note} className="input-1"
                                        onChange={(event) => this.onChangeAttribute('note', event)}
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div className="row-wrapper">
                            <div className="row">
                                <div className="col-5"></div>
                                <div className="col-7">
                                    <ClientCaptcha
                                        captchaCode={this.setCode}
                                        charsCount={5}
                                        width={120}
                                        height={45}
                                        key={key}
                                        ref={(e) =>
                                            (this.recaptchaInstance = e)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row-wrapper">
                            <div className="row">
                                <div className="col-5">
                                    <p className="o-label">Mã bảo mật</p>
                                </div>
                                <div className="col-7">
                                    <input
                                        type="text"
                                        id="ma-bao-mat"
                                        name="ma-bao-mat"
                                        value={clientCode}
                                        className={
                                            isInvalidInput.isInvalidCaptchaCode
                                                ? "input-1 error"
                                                : "input-1"
                                        }
                                        onChange={(event) =>
                                            this.onChangeAttribute(
                                                "clientCode",
                                                event
                                            )
                                        }
                                    />
                                    {isInvalidInput.isInvalidCaptchaCode && (
                                        <span className="text-err">
                                            {errMessage.errCaptchaCode}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="box-click row-wrapper">
                            <button
                                type="button"
                                onClick={() => this.nextToStep1Confirm()}
                                className="button-submit tiep-tuc"
                            >
                                Tiếp tục
                            </button>
                            {errMessage.errSubmitBtn && (
                                <span className="text-err d-block w-100 mt-3">
                                    {errMessage.errSubmitBtn}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class CreateAccountStep1Confirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            unixtime: "",
        };
    }

    componentDidMount() {
        let { executeScroll } = this.props;
        executeScroll();
    }

    backToStep1 = () => {
        let { setStep } = this.props;
        setStep(1);
    };

    get_date_by_client = () => {
        let unixtime = moment().unix();
        let sessionid = {
            txdata: "",
            txtime: "",
            unixtime: unixtime,
            day: ("0" + new Date().getDate()).slice(-2),
            month: ("0" + (new Date().getMonth() + 1)).slice(-2),
            year: new Date().getFullYear().toString().substring(2, 4),
        };

        return sessionid;
    };
    get_date_time_flex = async () => {
        try {
            let sessionid = {
                txdate: "",
                txtime: "",
                unixtime: "",
            };
            let dt = await userService.getDateTimeFlex();
            if (dt) {
                if (dt.p_err_code === 0) {
                    let d = dt.p_err_message.split(" ");
                    let txdate = d && d[0];
                    let txtime = d && d[1];
                    let _txdate = txdate.split("/");

                    let day = parseInt(_txdate[0]);
                    let month = parseInt(_txdate[1]);
                    let year = parseInt(_txdate[2]);
                    //
                    let _txtime = txtime.split(":");
                    let hour = parseInt(_txtime[0]);
                    let minute = parseInt(_txtime[1]);
                    let second = parseInt(_txtime[2]);
                    //
                    sessionid = {
                        txdate: d && d[0],
                        txtime: d && d[1],
                        unixtime: timer.dateToUnix(
                            year,
                            month - 1,
                            day,
                            hour,
                            minute,
                            second
                        ),
                        day: day > 9 ? day : "0" + day,
                        month: month > 9 ? month : "0" + month,
                        year: year.toString().substring(2, 4),
                    };
                    console.log(
                        "get_date_time_flex.:year, month - 1, day, hour, minute, second, sessionid.unixtime, txdate, txtime===",
                        year,
                        month - 1,
                        day,
                        hour,
                        minute,
                        second,
                        sessionid.unixtime,
                        txdate,
                        txtime
                    );
                }
            } else {
                sessionid = this.get_date_by_client();
            }
            return sessionid;
        } catch (e) {
            console.log("get_date_time_flex.:err=", e);
            alert("get_date_time_flex.:Error=" + e);
            let sessionid = this.get_date_by_client();
            return sessionid;
        }
    };

    get_bank_infor = async (requestid) => {
        try {
            let obj = {
                requestid: requestid,
            };
            let dt = await userService.getBankInfor(obj);
            if (dt) {
                if (dt.p_err_code === 0) {
                    if (dt.p_result && dt.p_result.length > 0) {
                        return dt.p_result;
                    }
                }
            }
            return [];
        } catch (e) {
            console.log("get_bank_infor.:err=", e);
            alert("get_bank_infor.:Error=" + e);
            return [];
        }
    };
    get_Open_Point_Ekyc = async (requestid) => {
        try {
            let obj = {
                requestid: requestid,
            };
            let dt = await userService.getOpenPointEKYC(obj);
            if (dt) {
                if (dt.p_err_code === 0) {
                    if (dt.p_result && dt.p_result.length > 0) {
                        return dt.p_result[0].EKYC_AI;
                    }
                }
            }
            return defaultOpenPointEKYC;
        } catch (e) {
            console.log("get_Open_Point_Ekyc.:err=", e);
            alert("get_bank_infor.:Error=" + e);
            return defaultOpenPointEKYC;
        }
    };
    nextToStep2 = async () => {
        let { setStep, setParentStateFromChild } = this.props;
        let { phoneNumber } = this.props.allData.dataStep1;
        // let unixtime = moment().unix();
        let _sessionid = await this.get_date_time_flex();
        let sessionid = {
            txdate: _sessionid.txdate,
            txtime: _sessionid.txtime,
            unixtime: _sessionid.unixtime,
            phoneNumber: phoneNumber,
            id: phoneNumber + "_" + _sessionid.unixtime,
            day: "" + _sessionid.day,
            month: "" + _sessionid.month,
            year: "" + _sessionid.year,
        };

        let bankInfor = await this.get_bank_infor(sessionid.id);
        let openPointEKYC = await this.get_Open_Point_Ekyc(sessionid.id);
        await setParentStateFromChild("1_1", {
            sessionid: sessionid,
            bankInfor: bankInfor,
            openPointEKYC: openPointEKYC,
        });
        setStep(2);
    };

    render() {
        let { allData } = this.props;
        let isRender =
            allData && allData.dataStep1 && !_.isEmpty(allData.dataStep1);
        let nameArea = "";
        if (isRender) {
            nameArea = STEP1_AREA.find(
                (item) => item && item.value === allData.dataStep1.area
            ).name;
        }

        return (
            <div className="register-step1-confirm">
                {isRender && (
                    <div className="row_container">
                        <div className="notification">
                            <img
                                src={iconClose}
                                alt=""
                                className="button-close"
                            />
                            <div className="title">
                                {/* Quý khách vui lòng xác nhận thông tin đăng ký */}
                                Quý khách vui lòng kiểm tra thông tin đăng ký
                                trước khi tiếp tục
                            </div>
                            <div className="box-info">
                                <div className="row-info">
                                    <div className="title-info">Khu vực</div>
                                    <div className="value-info">{nameArea}</div>
                                </div>
                                <div className="row-info">
                                    <div className="title-info">Họ và tên</div>
                                    <div className="value-info">
                                        {allData.dataStep1.fullName}
                                    </div>
                                </div>
                                <div className="row-info">
                                    <div className="title-info">
                                        Số điện thoại di động
                                    </div>
                                    <div className="value-info">
                                        {allData.dataStep1.phoneNumber}
                                    </div>
                                </div>
                                <div className="row-info">
                                    <div className="title-info">Email</div>
                                    <div className="value-info">
                                        {allData.dataStep1.email}
                                    </div>
                                </div>
                                {/* <div className="row-info">
                                    <div className="title-info">ID người giới thiệu</div>
                                    <div className="value-info">{allData.dataStep1.idInvitor}</div>
                                </div> */}
                            </div>
                            <div className="box-click row-wrapper">
                                <button
                                    className="button-submit quay-lai"
                                    onClick={() => this.backToStep1()}
                                >
                                    Quay lại
                                </button>
                                <button
                                    className="button-submit tiep-tuc"
                                    onClick={() => this.nextToStep2()}
                                >
                                    Tiếp tục
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export { CreateAccountStep1, CreateAccountStep1Confirm };
